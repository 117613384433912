import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Icon } from '@/src/ui/components/Icon'
import styled, { keyframes } from 'styled-components'
import { FlexCenter } from '@/src/ui/components/Flex'

export const Anchor = Popover.Anchor
export const Portal = Popover.Portal
export const Close = Popover.Close
export const Arrow = Popover.Arrow
export const Root = Popover.Root
export const Trigger = Popover.Trigger

type TriggerProps = ComponentPropsWithoutRef<typeof Popover.Trigger> & {
  name?: string
  onDestroy?: (name: string) => void
  open?: boolean
  fixed?: boolean
}

export const TriggerFilter = forwardRef<
  ElementRef<typeof Popover.Trigger>,
  TriggerProps
>(({ name, onDestroy, open, children, ...props }, ref) => {
  return (
    <StyledTrigger ref={ref} {...props} fixed={props.fixed}>
      <FlexCenter>
        {!props.fixed && (
          <CloseButton
            onClick={(e) => {
              onDestroy && name && onDestroy(name)
              e.stopPropagation()
            }}
          >
            <Icon
              type="close"
              size="12"
              padding="2px"
              color="black70"
              hoverColor="black90"
            />
          </CloseButton>
        )}
        {children}
        <ChevronWrapper open={open}>
          <Icon type="downChevron" size="12" padding="2px" color="black80" />
        </ChevronWrapper>
      </FlexCenter>
    </StyledTrigger>
  )
})
Trigger.displayName = Popover.Trigger.displayName

export const Content = forwardRef<
  ElementRef<typeof Popover.Content>,
  ComponentPropsWithoutRef<typeof Popover.Content>
>(({ children, ...props }, ref) => {
  return (
    <StyledContent ref={ref} {...props}>
      {children}
    </StyledContent>
  )
})
Content.displayName = Popover.Content.displayName

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(2px);
  }
  100% {
    opacity: 0;
    transform: translateY(-4px);
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-4px);
  }
  100% {
    opacity: 1;
    transform: translateY(2px);
  }
`

const StyledTrigger = styled(Popover.Trigger)<{ fixed?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.black30};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ fixed }) => (fixed ? '8px' : '100px')};
  padding: 6px 12px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.black20};
  }
`

const StyledContent = styled(Popover.Content)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 0.43px solid ${({ theme }) => theme.colors.black30};
  max-height: var(--radix-popover-content-available-height);
  display: flex;
  flex-direction: column;

  box-shadow: 0px 4px 12px 0px #00000029;
  transform: translateY(2px);

  &[data-state='closed'] {
    animation: ${fadeOut} 200ms;
  }
  &[data-state='open'] {
    animation: ${fadeIn} 200ms;
  }
`

const CloseButton = styled.div`
  margin-right: 6px;
`

const ChevronWrapper = styled.div<{ open?: boolean }>`
  margin-left: 8px;
  ${({ open }) => open && 'transform: rotate(180deg);'}
  transition: 0.3s;
`
