import { useEffect, useMemo } from 'react'
import { identify } from '@fullstory/browser'
import { init } from '@fullstory/browser'
import { ENVIRONMENT, FULL_STORY_ID } from '@/parafin.config'
import { useAuth } from '@/src/providers/auth'
import { useCore } from '@/src/providers/core'
import { useExperimentContext } from '@/src/providers/experiment'

export const InitializeServices = () => {
  const { organization, orgOrPartner } = useCore()
  const { user, claims } = useAuth()
  const experimentContext = useExperimentContext()

  useEffect(() => {
    if (
      FULL_STORY_ID &&
      ENVIRONMENT !== 'development' &&
      orgOrPartner.slug !== 'jason' &&
      claims.fullstoryUserId
    ) {
      identify(claims.fullstoryUserId, {
        displayName: `${claims.name} @ ${
          organization ? organization.name : orgOrPartner.name
        } (pt. dashboard)`,
        partner: organization ? organization.name : orgOrPartner.slug,
        app: 'partner_dashboard',
        environment: ENVIRONMENT,
      })
    }
  }, [claims, organization, orgOrPartner])

  useMemo(() => {
    if (
      FULL_STORY_ID &&
      ENVIRONMENT !== 'development' &&
      orgOrPartner.slug !== 'jason'
    ) {
      init({ orgId: FULL_STORY_ID })
    }
  }, [])

  useEffect(() => {
    experimentContext.setConfig({
      userId: user?.email,
      partnerSlug: orgOrPartner.slug,
      organizationSlugs: organization ? [organization.slug] : [],
    })
  }, [user, orgOrPartner, organization])

  return <></>
}
