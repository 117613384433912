import { PartnerInfo, useCore } from '@/src/providers/core'
import { useListNotifications } from '@/src/queries/gets'
import {
  useMarkAllNotificationsRead,
  useMarkNotificationRead,
} from '@/src/queries/mutations'
import { Root, Trigger, Portal, Content } from '@/src/ui/base/popover'
import { ToggleRoot, ToggleItem } from '@/src/ui/base/toggle'
import Button from '@/src/ui/components/Button'
import { FlexCenter, FlexColumn, FlexSpaced } from '@/src/ui/components/Flex'
import { Icon } from '@/src/ui/components/Icon'
import { Loading } from '@/src/ui/components/Loading'
import { OrganizationLogoWithName } from '@/src/ui/components/OrganizationLogoWithName/OrganizationLogoWithName'
import { PartnerLogoWithName } from '@/src/ui/components/PartnerLogoWithName'
import { Typography } from '@/src/ui/components/Typography'
import {
  getListPdPartnerEventListWebNotificationsQueryKey,
  ListWebNotificationsResponse,
  Organization,
  WebNotification,
  WebNotificationState,
} from '@parafin/medici-api'
import { formatDate, GetReturn } from '@parafin/utils'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Notifications = () => {
  const { sandbox } = useCore()
  const qc = useQueryClient()
  const [open, setOpen] = useState<boolean>(false)
  const [pageNum, setPageNum] = useState<number>(1)
  const [viewFilter, setViewFilter] = useState<WebNotificationState>('unread')
  const notifications = useListNotifications(
    {
      page_number: pageNum,
      state: viewFilter,
      live_mode: !sandbox,
    },
    { enabled: viewFilter !== undefined, refetchInterval: 60000 }
  )

  const markAllRead = useMarkAllNotificationsRead()

  return (
    <Root>
      <Trigger name="notificationBell">
        <BellButton>
          <Icon
            type={
              notifications.data?.has_unread === true
                ? 'notificationBellDot'
                : 'notificationBell'
            }
            size="22px"
            onClick={() => {
              setOpen(!open)
            }}
          />
        </BellButton>
      </Trigger>
      <Portal>
        <Content collisionPadding={20}>
          <NotificationSidebar>
            <Header padding="24px 16px 16px 16px">
              <Typography variant="title" weight="bold">
                Notifications
              </Typography>
              <FlexCenter gap="12px">
                {viewFilter === 'unread' && (
                  <Button
                    onClick={() => {
                      markAllRead.mutate(
                        { data: { live_mode: !sandbox } },
                        {
                          onSuccess: () => {
                            qc.invalidateQueries(
                              getListPdPartnerEventListWebNotificationsQueryKey()
                            )
                          },
                          onError: () => {
                            toast.error('Failed to mark all read')
                          },
                        }
                      )
                    }}
                    variant="tertiary"
                    id={'mark-all-read'}
                  >
                    Mark all read
                  </Button>
                )}
                <ToggleRoot
                  type="single"
                  value={viewFilter}
                  onValueChange={(value: WebNotificationState) => {
                    if (value) setViewFilter(value)
                    qc.invalidateQueries(
                      getListPdPartnerEventListWebNotificationsQueryKey()
                    )
                  }}
                >
                  <ToggleItem
                    label="Unread"
                    currentValue={viewFilter}
                    value={WebNotificationState.unread}
                  />
                  <ToggleItem
                    label="Read"
                    currentValue={viewFilter}
                    value={WebNotificationState.read}
                  />
                </ToggleRoot>
              </FlexCenter>
            </Header>
            <NotificationsBodyWithData
              data={notifications}
              pageNum={pageNum}
              setPageNum={setPageNum}
              viewFilter={viewFilter}
            />
          </NotificationSidebar>
        </Content>
      </Portal>
    </Root>
  )
}

type NotificationsBodyWithDataProps = {
  data: GetReturn<ListWebNotificationsResponse>
  pageNum: number
  setPageNum: React.Dispatch<React.SetStateAction<number>>
  viewFilter: WebNotificationState
}

const NotificationsBodyWithData = ({
  data,
  pageNum,
  setPageNum,
  viewFilter,
}: NotificationsBodyWithDataProps) => {
  if (data.isLoading) {
    return <Loading height="160px" />
  }

  if (data.isInvalid) {
    return (
      <EmptyNotifications>
        <Typography color="black70">Unable to fetch notifications</Typography>
      </EmptyNotifications>
    )
  }

  if (data.data.count === 0) {
    return (
      <EmptyNotifications>
        <Typography color="black70">
          You have no {viewFilter} notifications
        </Typography>
      </EmptyNotifications>
    )
  }

  const notificationsList = data.data.results || []

  return (
    <>
      <ScrollArea>
        {notificationsList.map((notif, index) => {
          return <NotificationSingle notif={notif} key={index} />
        })}
      </ScrollArea>
      <FooterBar>
        {notificationsList.length > 0 && (
          <Typography color="black70">
            Showing {(pageNum - 1) * 10 + 1} to{' '}
            {(pageNum - 1) * 10 + notificationsList.length} of {data.data.count}
          </Typography>
        )}
        <FlexCenter gap="6px">
          {pageNum > 1 && (
            <Icon
              type="leftChevron"
              onClick={() => {
                setPageNum((curPageNum) => Math.max(curPageNum - 1, 0))
              }}
              color="black40"
            />
          )}
          {data.data.has_next && (
            <Icon
              type="rightChevron"
              onClick={() => {
                setPageNum((curPageNum) => curPageNum + 1)
              }}
              color="black40"
            />
          )}
        </FlexCenter>
      </FooterBar>
    </>
  )
}

const NotificationSingle = ({ notif }: { notif: WebNotification }) => {
  const { partners, organization, sandbox } = useCore()
  const markRead = useMarkNotificationRead()

  const cta = getNotificationCTALink(notif)

  const titleNode = organization
    ? getNotificationPlatformTitle(notif, partners, organization)
    : undefined
  const description = getNotificationText(notif)
  const date = formatDate(notif.created_at, {
    hour: 'numeric',
    minute: 'numeric',
    local: true,
  })

  return (
    <NotificationSingleBlock
      reloadDocument
      to={cta}
      onClick={() =>
        markRead.mutateAsync({
          data: { id: notif.web_notification_id },
        })
      }
    >
      <FlexCenter gap="8px" padding="0px 0px 0px 16px">
        <ReadDot unread={notif.state === 'unread'} />
        {titleNode || <Typography>{description}</Typography>}
      </FlexCenter>
      {titleNode && (
        <Typography margin="0px 0px 0px 32px">{description}</Typography>
      )}
      <Typography margin="0px 0px 0px 32px" variant="caption" color="black80">
        {date}
      </Typography>
    </NotificationSingleBlock>
  )
}

const getNotificationSearchParams = (
  notif: WebNotification,
  sandbox: boolean,
  organization?: Organization
) => {
  const newSearch = new URLSearchParams()
  if (sandbox) {
    newSearch.append('sandbox_mode', 'true')
  }
  if (organization !== undefined && notif.partner_id) {
    newSearch.append('partner_id', notif.partner_id)
  }
  if (notif.event_type === 'api_errors') {
    newSearch.append('status_code', '4XX')
    newSearch.append('status_code', '5XX')
  }
  if (notif.event_type === 'crm_errors') {
    newSearch.append('delivery_states', 'failed')
  }
  if (notif.event_type === 'api_errors') {
    newSearch.append('delivery_states', 'failed')
  }
  return newSearch
}

const getNotificationCTALink = (notif: WebNotification) => {
  const baseRoute = (() => {
    switch (notif.event_type) {
      case 'api_errors':
        return '/developer/api-logs'
      case 'crm_errors':
        return '/developer/crm-events'
      case 'webhook_errors':
        return '/developer/webhook-events'
      case 'manual_datashare_upload':
        return `/data-share/manual-uploads/uploads/${notif.payload?.['event_id']}`
      case 's3_datashare_upload':
        return `/data-share/s3-pipeline/uploads/${notif.payload?.['event_id']}`
      case 'user_added':
        return '/settings/users'
      case 'missing_bank_account_datashare':
        return '/data-share'
      case 'missing_sales_datashare':
        return '/data-share'
    }
  })()
  return `${baseRoute}${notif.pd_query_params}`
}

const getNotificationPlatformTitle = (
  notif: WebNotification,
  partners: PartnerInfo[],
  organization: Organization
) => {
  const partner = partners.find((p) => p.id === notif.partner_id)
  return (
    <Typography weight="medium">
      {partner ? (
        <PartnerLogoWithName
          partner={partner}
          icon={<Icon type="briefcase" />}
          weight="medium"
        />
      ) : (
        <OrganizationLogoWithName
          organization={organization}
          orgEnabled
          weight="medium"
        />
      )}
    </Typography>
  )
}

const getNotificationText = (notif: WebNotification) => {
  switch (notif.event_type) {
    case 'api_errors': {
      const counter =
        parseInt(notif.payload?.['error_count'] || '0') > 1
          ? 'requests'
          : 'request'
      return `${notif.payload?.['error_count']} API ${counter} failed`
    }
    case 'crm_errors': {
      const counter =
        parseInt(notif.payload?.['error_count'] || '0') > 1 ? 'events' : 'event'
      return `${notif.payload?.['error_count']} CRM ${counter} failed`
    }
    case 'webhook_errors': {
      const counter =
        parseInt(notif.payload?.['error_count'] || '0') > 1
          ? 'webhooks'
          : 'webhook'
      return `${notif.payload?.['error_count']} ${counter} failed to deliver`
    }
    case 'manual_datashare_upload':
      return 'Manual datashare upload failed'
    case 's3_datashare_upload':
      return 'S3 datashare upload failed'
    case 'user_added':
      return `${notif.payload?.['username']} was added as ${notif.payload?.['role']}`
    case 'missing_bank_account_datashare':
      return 'Bank account datashare missing'
    case 'missing_sales_datashare':
      return 'Sales datashare missing'
  }
}

const BellButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  border-radius: 8px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.black20};
  }
`

const EmptyNotifications = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NotificationSidebar = styled(FlexColumn)`
  align-items: stretch;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  width: 450px;
  height: fit-content;
  overflow: hidden;
`

const Header = styled(FlexSpaced)`
  flex-shrink: 0;
  box-shadow: 0px 0px 32px -16px ${({ theme }) => theme.colors.black30};
`

const ScrollArea = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const NotificationSingleBlock = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black30};
`

const ReadDot = styled.div<{ unread: boolean }>`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({ unread, theme }) =>
    unread ? theme.colors.basil100 : theme.colors.white};
`

const FooterBar = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 20px 18px 28px 18px;
  box-shadow: 0px 0px 32px -16px ${({ theme }) => theme.colors.black30};
`
