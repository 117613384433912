import styled from 'styled-components'

export type TypographyVariant =
  | 'display'
  | 'heading'
  | 'title'
  | 'paragraph'
  | 'caption'

export type TypographyWeight = 'regular' | 'medium' | 'bold'

export interface TypographyProps {
  variant?: TypographyVariant
  weight?: TypographyWeight
  color?: string
  rawColor?: string
  align?: string
  padded?: boolean
  nowrap?: boolean
  inline?: boolean
  margin?: string
  overflowWrap?: boolean
  wordBreak?: boolean
}

export const Typography = styled.div<TypographyProps>`
  font-family: 'Inter', sans-serif;
  font-size: ${({ variant = 'paragraph' }) => {
    switch (variant) {
      case 'display':
        return '34px'
      case 'heading':
        return '24px'
      case 'title':
        return '16px'
      case 'paragraph':
        return '14px'
      case 'caption':
        return '12px'
    }
  }};

  line-height: ${({ variant = 'paragraph' }) => {
    switch (variant) {
      case 'display':
        return '40px'
      case 'heading':
        return '32px'
      case 'title':
        return '24px'
      case 'paragraph':
        return '22px'
      case 'caption':
        return '14px'
    }
  }};

  font-weight: ${({ weight = 'regular', variant = 'paragraph' }) => {
    switch (weight) {
      case 'regular':
        if (['paragraph', 'caption'].includes(variant)) {
          return 400
        }
        return 500
      case 'medium':
        return 600
      case 'bold':
        return 700
    }
  }};

  color: ${({ theme, color, rawColor }) =>
    rawColor ? rawColor : color ? theme.colors[color] : 'auto'};
  ${({ align }) => align && `text-align: ${align};`}
  ${({ padded }) => padded && `padding: 14px 0px;`}
  ${({ nowrap }) => nowrap && `white-space: nowrap;`}
  ${({ inline }) => inline && `display: inline;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ overflowWrap }) => overflowWrap && `overflow-wrap: anywhere;`} /* ${({
    wordBreak,
  }) => wordBreak && `word-break: break-word;`} */
`

// Reusable hardcoded Typography

export const Code = styled.code`
  padding: 0 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.mono200};
`

export const Medium = styled.span`
  font-weight: 500;
`
