import styled from 'styled-components'
import { Fragment } from 'react'
import { Icon } from '../../ui/components/Icon'
import { FlexCenter } from '@/src/ui/components/Flex'
import { Typography } from '@/src/ui/components/Typography'
import { Link } from '@/src/ui/components/Link'
import { toSnakeCase } from '@parafin/utils'

export type Breadcrumb = {
  name: string
  route?: string
}

type BreadcrumbsProps = {
  crumbs: Breadcrumb[]
}

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  return (
    <FlexCenter data-testid="breadcrumbs">
      {crumbs.map((c, i) => (
        <Fragment key={i}>
          {c.route ? (
            <Link id={`breadcrumb-${toSnakeCase(c.name)}`} to={c.route}>
              <RootHeading variant="heading" weight="medium" color="mono800">
                {c.name}
              </RootHeading>
            </Link>
          ) : (
            <Typography variant="heading" weight="medium">
              {c.name}
            </Typography>
          )}
          {i !== crumbs.length - 1 && (
            <Separator>
              <Icon type="rightChevron" padding="2px" />
            </Separator>
          )}
        </Fragment>
      ))}
    </FlexCenter>
  )
}

const Separator = styled.div`
  margin: 0 4px;

  & > svg {
    display: block;
  }
`

const RootHeading = styled(Typography)`
  &:hover {
    text-decoration: underline;
  }
`
