import { useQuery as c, useMutation as i } from "@tanstack/react-query";
import o from "axios";
const D_ = {
  cash_advance_offer_created: "cash_advance_offer_created",
  cash_advance_offer_accepted: "cash_advance_offer_accepted",
  cash_advance_top_up_eligible: "cash_advance_top_up_eligible",
  cash_advance_paid_off: "cash_advance_paid_off",
  cash_advance_closed: "cash_advance_closed",
  cash_advance_offer_closed: "cash_advance_offer_closed",
  flex_loan_offer_accepted: "flex_loan_offer_accepted",
  flex_loan_closed: "flex_loan_closed",
  flex_loan_offer_created: "flex_loan_offer_created",
  flex_loan_offer_closed: "flex_loan_offer_closed",
  capital_product_offercreated: "capital_product_offer.created",
  capital_product_offerclosed: "capital_product_offer.closed",
  capital_productcreated: "capital_product.created",
  capital_productclosed: "capital_product.closed",
  capital_product_applicationupdated: "capital_product_application.updated",
  opt_in: "opt_in",
  fis_opt_in_merchant_account: "fis_opt_in_merchant_account",
  fis_opt_in_bank_account: "fis_opt_in_bank_account",
  wallet_applicationapproved: "wallet_application.approved",
  wallet_applicationdenied: "wallet_application.denied",
  wallet_accountactive: "wallet_account.active",
  wallet_accountinactive: "wallet_account.inactive",
  wallet_transactionsucceeded: "wallet_transaction.succeeded",
  wallet_transactionpending: "wallet_transaction.pending",
  wallet_transactioncanceled: "wallet_transaction.canceled",
  wallet_transactionarchived: "wallet_transaction.archived",
  wallet_transactionfailed: "wallet_transaction.failed"
}, G_ = {
  normal: "normal",
  sync: "sync"
}, I_ = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, T_ = {
  enabled: "enabled",
  disabled: "disabled"
}, w_ = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, Q_ = {
  active: "active",
  inactive: "inactive"
}, L_ = {
  read: "read",
  unread: "unread"
}, W_ = {
  automatically_verified: "automatically_verified",
  pending_automatic_verification: "pending_automatic_verification",
  pending_manual_verification: "pending_manual_verification",
  manually_verified: "manually_verified",
  verification_expired: "verification_expired",
  verification_failed: "verification_failed",
  database_matched: "database_matched",
  database_insights_pass: "database_insights_pass",
  database_insights_pass_with_caution: "database_insights_pass_with_caution",
  database_insights_fail: "database_insights_fail"
}, $_ = {
  Error: "Error",
  Warning: "Warning"
}, z_ = {
  admin: "admin",
  user: "user",
  controller: "controller"
}, H_ = {
  offer: "offer",
  no_offer: "no_offer",
  manual_review: "manual_review"
}, V_ = {
  pending_kitchen_response: "pending_kitchen_response",
  pending_kitchen_underwriting: "pending_kitchen_underwriting",
  failed: "failed",
  success: "success"
}, Y_ = {
  failed_plaid_auth: "failed_plaid_auth",
  no_plaid_dispersement_observed: "no_plaid_dispersement_observed"
}, j_ = {
  terms_of_service: "terms_of_service",
  state_disclosure: "state_disclosure",
  net_top_up_itemization: "net_top_up_itemization"
}, X_ = {
  payout: "payout",
  balance_transaction: "balance_transaction"
}, J_ = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AS: "AS",
  GU: "GU",
  MP: "MP",
  PR: "PR",
  VI: "VI",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT"
}, Z_ = {
  mfa: "mfa",
  otp: "otp",
  account_recovery: "account_recovery"
}, tl = {
  very_high: "very_high",
  high: "high",
  medium: "medium",
  low: "low"
}, el = {
  capital_product_plaid_bank_linking: "capital_product_plaid_bank_linking",
  capital_product_acceptance: "capital_product_acceptance",
  merchant_bank_card_transaction: "merchant_bank_card_transaction",
  merchant_bank_transfer: "merchant_bank_transfer"
}, nl = {
  pending_response: "pending_response",
  completed: "completed"
}, rl = {
  before_marketing: "before_marketing",
  after_sign_up: "after_sign_up"
}, sl = {
  capital_product_offers_table: "capital_product_offers_table",
  capital_product_applications_table: "capital_product_applications_table",
  capital_products_table: "capital_products_table"
}, al = {
  completed: "completed",
  in_progress: "in_progress",
  not_started: "not_started",
  failed: "failed",
  expired: "expired"
}, ol = {
  shopify: "shopify",
  amazon: "amazon",
  square: "square",
  ebay: "ebay",
  walmart: "walmart",
  etsy: "etsy",
  woo_commerce: "woo_commerce",
  quickbooks: "quickbooks",
  xero: "xero",
  net_suite: "net_suite",
  magneto: "magneto",
  freshbooks: "freshbooks",
  zohobooks: "zohobooks",
  clover: "clover",
  paypal: "paypal"
}, ul = {
  payments: "payments",
  accounting: "accounting"
}, il = {
  pending: "pending",
  available: "available",
  disconnected: "disconnected",
  disabled: "disabled",
  link_error: "link_error",
  error: "error"
}, cl = {
  duplicate: "duplicate",
  incorrect_amount: "incorrect_amount",
  incorrect_receiving_account: "incorrect_receiving_account",
  date_earlier_than_intended: "date_earlier_than_intended",
  date_later_than_intended: "date_later_than_intended"
}, yl = {
  R01: "R01",
  R02: "R02",
  R03: "R03",
  R04: "R04",
  R05: "R05",
  R06: "R06",
  R07: "R07",
  R08: "R08",
  R09: "R09",
  R10: "R10",
  R11: "R11",
  R12: "R12",
  R13: "R13",
  R14: "R14",
  R15: "R15",
  R16: "R16",
  R17: "R17",
  R18: "R18",
  R19: "R19",
  R20: "R20",
  R21: "R21",
  R22: "R22",
  R23: "R23",
  R24: "R24",
  R25: "R25",
  R26: "R26",
  R27: "R27",
  R28: "R28",
  R29: "R29",
  R30: "R30",
  R31: "R31",
  R32: "R32",
  R33: "R33",
  R34: "R34",
  R35: "R35",
  R36: "R36",
  R37: "R37",
  R38: "R38",
  R39: "R39",
  R45: "R45",
  C01: "C01",
  C02: "C02",
  C03: "C03",
  C05: "C05",
  C06: "C06",
  C07: "C07",
  C08: "C08",
  C09: "C09",
  C13: "C13",
  C14: "C14",
  NUMBER_900: 900,
  NUMBER_901: 901,
  NUMBER_902: 902,
  NUMBER_903: 903,
  NUMBER_904: 904,
  NUMBER_905: 905,
  NUMBER_907: 907,
  NUMBER_908: 908,
  NUMBER_909: 909,
  NUMBER_910: 910,
  NUMBER_911: 911,
  NUMBER_912: 912,
  NUMBER_914: 914,
  NUMBER_915: 915,
  NUMBER_916: 916,
  NUMBER_917: 917,
  NUMBER_918: 918,
  NUMBER_919: 919,
  NUMBER_920: 920,
  NUMBER_921: 921,
  NUMBER_922: 922,
  NUMBER_990: 990
}, dl = {
  prevent_business_onboarding: "prevent_business_onboarding",
  suspend_card_account: "suspend_card_account"
}, pl = {
  manual: "manual",
  auto_expiration: "auto_expiration",
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  lift_repeated_failed_nsf_repayments: "lift_repeated_failed_nsf_repayments",
  unverified_bank_account: "unverified_bank_account",
  lift_unverified_bank_account: "lift_unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  lift_recent_non_nsf_repayment_returns: "lift_recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  lift_first_repayment_returned_nsf: "lift_first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  lift_first_auto_repayment_returned_nsf: "lift_first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  lift_no_recent_sales: "lift_no_recent_sales",
  plaid_transaction_review: "plaid_transaction_review",
  generic_application_denial: "generic_application_denial"
}, _l = {
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  unverified_bank_account: "unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  fraud: "fraud",
  bankruptcy: "bankruptcy",
  active_payment_plan_high_risk: "active_payment_plan_high_risk",
  payment_processor_transition: "payment_processor_transition",
  shared_email_across_platforms: "shared_email_across_platforms",
  bank_account_high_servicing_risk: "bank_account_high_servicing_risk",
  servicing_risk: "servicing_risk",
  generic_application_denial: "generic_application_denial"
}, ll = {
  unverified_bank_account: "unverified_bank_account",
  repayment_returns: "repayment_returns",
  other: "other"
}, ml = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, gl = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly"
}, ql = {
  cx: "cx",
  pending_funding: "pending_funding",
  too_many_missed_repayments: "too_many_missed_repayments",
  administrative_ach_return: "administrative_ach_return",
  awaiting_retry_non_nsf_return: "awaiting_retry_non_nsf_return",
  pending_reversal_completion: "pending_reversal_completion"
}, fl = {
  gross_sales: "gross_sales",
  net_payouts: "net_payouts"
}, hl = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, Ol = {
  pending: "pending",
  approved: "approved",
  denied: "denied",
  manual_review: "manual_review"
}, Kl = {
  other: "other",
  disbursements: "disbursements",
  serviceability: "serviceability"
}, bl = {
  mfa_phone_number: "mfa_phone_number",
  unverified_phone_number: "unverified_phone_number"
}, Pl = {
  NUMBER_93: "93",
  NUMBER_355: "355",
  NUMBER_213: "213",
  NUMBER_1684: "1684",
  NUMBER_376: "376",
  NUMBER_244: "244",
  NUMBER_1264: "1264",
  NUMBER_672: "672",
  NUMBER_1268: "1268",
  NUMBER_54: "54",
  NUMBER_374: "374",
  NUMBER_297: "297",
  NUMBER_61: "61",
  NUMBER_43: "43",
  NUMBER_994: "994",
  NUMBER_1242: "1242",
  NUMBER_973: "973",
  NUMBER_880: "880",
  NUMBER_1246: "1246",
  NUMBER_375: "375",
  NUMBER_32: "32",
  NUMBER_501: "501",
  NUMBER_229: "229",
  NUMBER_1441: "1441",
  NUMBER_975: "975",
  NUMBER_591: "591",
  NUMBER_387: "387",
  NUMBER_267: "267",
  NUMBER_55: "55",
  NUMBER_246: "246",
  NUMBER_1284: "1284",
  NUMBER_673: "673",
  NUMBER_359: "359",
  NUMBER_226: "226",
  NUMBER_257: "257",
  NUMBER_855: "855",
  NUMBER_237: "237",
  NUMBER_1: "1",
  NUMBER_238: "238",
  NUMBER_1345: "1345",
  NUMBER_236: "236",
  NUMBER_235: "235",
  NUMBER_56: "56",
  NUMBER_86: "86",
  NUMBER_57: "57",
  NUMBER_269: "269",
  NUMBER_682: "682",
  NUMBER_506: "506",
  NUMBER_385: "385",
  NUMBER_53: "53",
  NUMBER_599: "599",
  NUMBER_357: "357",
  NUMBER_420: "420",
  NUMBER_243: "243",
  NUMBER_45: "45",
  NUMBER_253: "253",
  "1-767": "1-767",
  NUMBER_1809: "1809",
  NUMBER_1829: "1829",
  NUMBER_1849: "1849",
  NUMBER_670: "670",
  NUMBER_593: "593",
  NUMBER_20: "20",
  NUMBER_503: "503",
  NUMBER_240: "240",
  NUMBER_291: "291",
  NUMBER_372: "372",
  NUMBER_251: "251",
  NUMBER_500: "500",
  NUMBER_298: "298",
  NUMBER_679: "679",
  NUMBER_358: "358",
  NUMBER_33: "33",
  NUMBER_689: "689",
  NUMBER_241: "241",
  NUMBER_220: "220",
  NUMBER_995: "995",
  NUMBER_49: "49",
  NUMBER_233: "233",
  NUMBER_350: "350",
  NUMBER_30: "30",
  NUMBER_299: "299",
  NUMBER_1473: "1473",
  NUMBER_1671: "1671",
  NUMBER_502: "502",
  NUMBER_441481: "441481",
  NUMBER_224: "224",
  NUMBER_245: "245",
  NUMBER_592: "592",
  NUMBER_509: "509",
  NUMBER_504: "504",
  NUMBER_852: "852",
  NUMBER_36: "36",
  NUMBER_354: "354",
  NUMBER_91: "91",
  NUMBER_62: "62",
  NUMBER_98: "98",
  NUMBER_964: "964",
  NUMBER_353: "353",
  NUMBER_441624: "441624",
  NUMBER_972: "972",
  NUMBER_39: "39",
  NUMBER_225: "225",
  NUMBER_1876: "1876",
  NUMBER_81: "81",
  NUMBER_441534: "441534",
  NUMBER_962: "962",
  NUMBER_7: "7",
  NUMBER_254: "254",
  NUMBER_686: "686",
  NUMBER_383: "383",
  NUMBER_965: "965",
  NUMBER_996: "996",
  NUMBER_856: "856",
  NUMBER_371: "371",
  NUMBER_961: "961",
  NUMBER_266: "266",
  NUMBER_231: "231",
  NUMBER_218: "218",
  NUMBER_423: "423",
  NUMBER_370: "370",
  NUMBER_352: "352",
  NUMBER_853: "853",
  NUMBER_389: "389",
  NUMBER_261: "261",
  NUMBER_265: "265",
  NUMBER_60: "60",
  NUMBER_960: "960",
  NUMBER_223: "223",
  NUMBER_356: "356",
  NUMBER_692: "692",
  NUMBER_222: "222",
  NUMBER_230: "230",
  NUMBER_262: "262",
  NUMBER_52: "52",
  NUMBER_691: "691",
  NUMBER_373: "373",
  NUMBER_377: "377",
  NUMBER_976: "976",
  NUMBER_382: "382",
  "1-664": "1-664",
  NUMBER_212: "212",
  NUMBER_258: "258",
  NUMBER_95: "95",
  NUMBER_264: "264",
  NUMBER_674: "674",
  NUMBER_977: "977",
  NUMBER_31: "31",
  NUMBER_687: "687",
  NUMBER_64: "64",
  NUMBER_505: "505",
  NUMBER_227: "227",
  NUMBER_234: "234",
  NUMBER_683: "683",
  NUMBER_850: "850",
  NUMBER_1670: "1670",
  NUMBER_47: "47",
  NUMBER_968: "968",
  NUMBER_92: "92",
  NUMBER_680: "680",
  NUMBER_970: "970",
  NUMBER_507: "507",
  NUMBER_675: "675",
  NUMBER_595: "595",
  NUMBER_51: "51",
  NUMBER_63: "63",
  NUMBER_48: "48",
  NUMBER_351: "351",
  NUMBER_1787: "1787",
  NUMBER_1939: "1939",
  NUMBER_974: "974",
  NUMBER_242: "242",
  NUMBER_40: "40",
  NUMBER_250: "250",
  NUMBER_590: "590",
  NUMBER_290: "290",
  NUMBER_1869: "1869",
  NUMBER_1758: "1758",
  NUMBER_508: "508",
  NUMBER_1784: "1784",
  NUMBER_685: "685",
  NUMBER_378: "378",
  NUMBER_239: "239",
  NUMBER_966: "966",
  NUMBER_221: "221",
  NUMBER_381: "381",
  NUMBER_248: "248",
  NUMBER_232: "232",
  NUMBER_65: "65",
  NUMBER_1721: "1721",
  NUMBER_421: "421",
  NUMBER_386: "386",
  NUMBER_677: "677",
  NUMBER_252: "252",
  NUMBER_27: "27",
  NUMBER_82: "82",
  NUMBER_211: "211",
  NUMBER_34: "34",
  NUMBER_94: "94",
  NUMBER_249: "249",
  NUMBER_597: "597",
  NUMBER_268: "268",
  NUMBER_46: "46",
  NUMBER_41: "41",
  NUMBER_963: "963",
  NUMBER_886: "886",
  NUMBER_992: "992",
  NUMBER_255: "255",
  NUMBER_66: "66",
  NUMBER_228: "228",
  NUMBER_690: "690",
  NUMBER_676: "676",
  NUMBER_1868: "1868",
  NUMBER_216: "216",
  NUMBER_90: "90",
  NUMBER_993: "993",
  NUMBER_1649: "1649",
  NUMBER_688: "688",
  NUMBER_1340: "1340",
  NUMBER_256: "256",
  NUMBER_380: "380",
  NUMBER_971: "971",
  NUMBER_44: "44",
  NUMBER_598: "598",
  NUMBER_998: "998",
  NUMBER_678: "678",
  NUMBER_379: "379",
  NUMBER_58: "58",
  NUMBER_84: "84",
  NUMBER_681: "681",
  NUMBER_967: "967",
  NUMBER_260: "260",
  NUMBER_263: "263"
}, kl = {
  BUSINESS: "BUSINESS",
  BUSINESSSUPPLEMENTALDOCUMENTS: "BUSINESSSUPPLEMENTALDOCUMENTS",
  GOVID: "GOVID",
  ADHOCBUSINESSNAMEDOCUMENT: "ADHOCBUSINESSNAMEDOCUMENT",
  ADHOCBUSINESSADDRESSDOCUMENT: "ADHOCBUSINESSADDRESSDOCUMENT",
  ADHOCBUSINESSSTATUSDOCUMENT: "ADHOCBUSINESSSTATUSDOCUMENT",
  ADHOCHOMEADDRESSDOCUMENT: "ADHOCHOMEADDRESSDOCUMENT",
  ADHOCEINDOCUMENT: "ADHOCEINDOCUMENT",
  ADHOCIDENTITYDOCUMENT: "ADHOCIDENTITYDOCUMENT"
}, Ml = {
  s3_datashare_upload: "s3_datashare_upload",
  manual_datashare_upload: "manual_datashare_upload",
  missing_sales_datashare: "missing_sales_datashare",
  missing_bank_account_datashare: "missing_bank_account_datashare",
  api_errors: "api_errors",
  crm_errors: "crm_errors",
  webhook_errors: "webhook_errors",
  user_added: "user_added",
  send_otp: "send_otp"
}, xl = {
  merchant_cash_advance: "merchant_cash_advance",
  card: "card",
  flex_loan: "flex_loan",
  merchant_bank: "merchant_bank"
}, Fl = {
  in_progress: "in_progress",
  completed: "completed",
  failed: "failed"
}, Rl = {
  void_cash_advance: "void_cash_advance"
}, Bl = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  kyc_document_upload: "kyc_document_upload",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  introductory_offer_underwriting: "introductory_offer_underwriting",
  offer_acceptance: "offer_acceptance",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  manual_review: "manual_review",
  offer_accepted: "offer_accepted",
  offer_preview: "offer_preview",
  capital_product: "capital_product",
  denied: "denied",
  abandoned: "abandoned",
  merchant_bank_pre_onboarding: "merchant_bank_pre_onboarding",
  merchant_bank_created: "merchant_bank_created",
  contact_verification: "contact_verification"
}, vl = {
  merchant_cash_advance: "merchant_cash_advance",
  loan: "loan",
  merchant_bank: "merchant_bank"
}, Cl = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  offer_acceptance: "offer_acceptance",
  kyc_document_upload: "kyc_document_upload",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  start_application: "start_application",
  offer_preview: "offer_preview"
}, El = {
  top_up_reminder: "top_up_reminder",
  fast_payouts_notification_card: "fast_payouts_notification_card"
}, Ul = {
  enter_gmv: "enter_gmv",
  upload_csv: "upload_csv",
  generate_offers: "generate_offers",
  awaiting_review: "awaiting_review",
  map_emails: "map_emails",
  email_campaign: "email_campaign",
  finished: "finished"
}, Al = {
  minimum: "minimum",
  sales_based: "sales_based"
}, Sl = {
  unverified: "unverified",
  posted: "posted",
  reversed: "reversed",
  verified: "verified",
  failed: "failed"
}, Nl = {
  cash: "cash",
  credit: "credit"
}, Dl = {
  ach: "ach",
  same_day_ach: "same_day_ach",
  rtp: "rtp",
  wire: "wire"
}, Gl = {
  inner_transfer: "inner_transfer",
  card_transaction: "card_transaction",
  recipient_transfer: "recipient_transfer",
  external_bank_transfer: "external_bank_transfer",
  received_transfer: "received_transfer",
  transfer_return: "transfer_return",
  transfer_reversal: "transfer_reversal",
  repayment: "repayment"
}, Il = {
  succeeded: "succeeded",
  failed: "failed",
  pending: "pending",
  cancelled: "cancelled",
  archived: "archived"
}, Tl = {
  money_in: "money_in",
  money_out: "money_out"
}, wl = {
  merchant_bank_account: "merchant_bank_account",
  recipient_account: "recipient_account",
  external_bank_account: "external_bank_account"
}, Ql = {
  manual: "manual",
  payout: "payout"
}, Ll = {
  electrical: "electrical",
  wire: "wire"
}, Wl = {
  normal: "normal",
  micro_deposit: "micro_deposit",
  partner_payout: "partner_payout"
}, $l = {
  credit_limit: "credit_limit"
}, zl = {
  checking: "checking",
  savings: "savings"
}, Hl = {
  active: "active",
  unlinked: "unlinked",
  archived_due_to_update: "archived_due_to_update",
  unverified_micro_deposit: "unverified_micro_deposit",
  failed_micro_deposit: "failed_micro_deposit"
}, Vl = {
  physical: "physical",
  virtual: "virtual"
}, Yl = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, jl = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, Xl = {
  did_not_purchase: "did_not_purchase",
  charged_for_cancelled_or_returned_purchase: "charged_for_cancelled_or_returned_purchase",
  incorrect_amount: "incorrect_amount",
  never_received: "never_received",
  expecting_credit: "expecting_credit",
  not_as_described_or_defective: "not_as_described_or_defective",
  other: "other"
}, Jl = {
  contact_verification: "contact_verification",
  kyc: "kyc",
  kyc_document_upload: "kyc_document_upload",
  kyc_submitted: "kyc_submitted",
  kyc_approved: "kyc_approved",
  manual_review: "manual_review",
  merchant_bank_created: "merchant_bank_created",
  denied: "denied"
}, Zl = {
  checking: "checking",
  savings: "savings"
}, tm = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, em = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  statement_upload: "statement_upload",
  plaid_investigation: "plaid_investigation",
  manual_review: "manual_review",
  liens_pending: "liens_pending"
}, nm = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, rm = {
  ucc: "ucc",
  federal: "federal",
  state: "state"
}, sm = {
  open: "open",
  closed: "closed",
  unknown: "unknown"
}, am = {
  pending_user: "pending_user",
  user_reviewed: "user_reviewed",
  do_not_need_user_review: "do_not_need_user_review"
}, om = {
  checking_to_savings: "checking_to_savings",
  savings_to_checking: "savings_to_checking"
}, um = {
  not_started: "not_started",
  pending: "pending",
  success: "success",
  failed: "failed"
}, im = {
  irrelevant: "irrelevant",
  incomplete: "incomplete",
  complete: "complete"
}, cm = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, ym = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, dm = {
  all_merchants: "all_merchants",
  post_opt_in: "post_opt_in",
  post_opt_in_sync_webhook: "post_opt_in_sync_webhook",
  never: "never"
}, pm = {
  upcoming_repayment: "upcoming_repayment",
  scheduled_repayment: "scheduled_repayment"
}, _m = {
  manual: "manual",
  rutter: "rutter"
}, lm = {
  string: "string",
  decimal: "decimal",
  int: "int",
  date: "date",
  object: "object",
  currency_code: "currency_code",
  boolean: "boolean"
}, mm = {
  business: "business",
  account: "account"
}, gm = {
  active: "active",
  inactive: "inactive"
}, qm = {
  BUSINESS_GROUP_ADMIN: "BUSINESS GROUP ADMIN",
  ADMIN: "ADMIN",
  "N/A": "N/A",
  MANAGER: "MANAGER",
  STORE_OPERATOR: "STORE OPERATOR"
}, fm = {
  kyc: "kyc",
  bank_verification: "bank_verification",
  financial_review: "financial_review",
  tax_record: "tax_record"
}, hm = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, Om = {
  partner_wide_program: "partner_wide_program",
  business_level_program: "business_level_program"
}, Km = {
  revenue_share: "revenue_share",
  parafin_funded: "parafin_funded"
}, bm = {
  apply: "apply",
  lift: "lift"
}, Pm = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  location: "location"
}, km = {
  file_feed: "file_feed",
  manual_upload: "manual_upload",
  standard_s3: "standard_s3",
  api: "api",
  custom_legacy: "custom_legacy",
  opt_in: "opt_in"
}, Mm = {
  pending: "pending",
  success: "success",
  failed: "failed"
}, xm = {
  business: "business",
  sale_record: "sale_record",
  bank_account_info: "bank_account_info",
  person: "person",
  person_business_relationship: "person_business_relationship"
}, Fm = {
  irrelevant: "irrelevant",
  optional: "optional",
  required: "required"
}, Rm = {
  UNSUPPORTEDCURRENCY: "UNSUPPORTEDCURRENCY",
  USD: "USD",
  CAD: "CAD",
  USDFOURTHDECIMAL: "USDFOURTHDECIMAL"
}, Bm = {
  hubspot: "hubspot",
  marketo: "marketo",
  salesforce: "salesforce"
}, vm = {
  offer_summary: "offer_summary"
}, Cm = {
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed"
}, Em = {
  US: "US",
  CA: "CA"
}, Um = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Am = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Sm = {
  fulfillmentdigitally_presented: "fulfillment.digitally_presented",
  fulfillmentissued: "fulfillment.issued",
  fulfillmentordered: "fulfillment.ordered",
  fulfillmentrejected: "fulfillment.rejected",
  fulfillmentreordered: "fulfillment.reordered",
  fulfillmentshipped: "fulfillment.shipped",
  stateactivated: "state.activated",
  statereinstated: "state.reinstated",
  statesuspended: "state.suspended",
  stateterminated: "state.terminated",
  pinchanged: "pin.changed",
  pinset: "pin.set",
  pinreveal: "pin.reveal"
}, Nm = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNACTIVATED: "UNACTIVATED"
}, Dm = {
  NUMBER_00: "00",
  NUMBER_01: "01",
  NUMBER_02: "02",
  NUMBER_03: "03",
  NUMBER_04: "04",
  NUMBER_05: "05",
  NUMBER_06: "06",
  NUMBER_07: "07",
  NUMBER_08: "08",
  NUMBER_09: "09",
  NUMBER_10: "10",
  NUMBER_11: "11",
  NUMBER_12: "12",
  NUMBER_13: "13",
  NUMBER_14: "14",
  NUMBER_15: "15",
  NUMBER_16: "16",
  NUMBER_17: "17",
  NUMBER_18: "18",
  NUMBER_19: "19",
  NUMBER_20: "20",
  NUMBER_21: "21",
  NUMBER_22: "22",
  NUMBER_23: "23",
  NUMBER_24: "24",
  NUMBER_25: "25",
  NUMBER_26: "26",
  NUMBER_27: "27",
  NUMBER_28: "28",
  NUMBER_29: "29",
  NUMBER_30: "30",
  NUMBER_31: "31"
}, Gm = {
  ADMIN: "ADMIN",
  API: "API",
  FRAUD: "FRAUD",
  IVR: "IVR",
  SYSTEM: "SYSTEM"
}, Im = {
  chargeback: "chargeback",
  normal: "normal"
}, Tm = {
  LOCAL_MAIL: "LOCAL_MAIL",
  GROUND: "GROUND",
  TWO_DAY: "TWO_DAY",
  OVERNIGHT: "OVERNIGHT",
  INTERNATIONAL: "INTERNATIONAL",
  FEDEX_EXPEDITED: "FEDEX_EXPEDITED",
  FEDEX_REGULAR: "FEDEX_REGULAR",
  UPS_EXPEDITED: "UPS_EXPEDITED",
  UPS_REGULAR: "UPS_REGULAR",
  USPS_EXPEDITED: "USPS_EXPEDITED",
  USPS_REGULAR: "USPS_REGULAR"
}, wm = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Qm = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Lm = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Wm = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, $m = {
  physical: "physical",
  virtual: "virtual"
}, zm = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, Hm = {
  physical: "physical",
  virtual: "virtual"
}, Vm = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, Ym = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, jm = {
  seen_accounting_integrations: "seen_accounting_integrations"
}, Xm = {
  outstanding: "outstanding",
  paid: "paid",
  abandoned: "abandoned",
  cancelled: "cancelled",
  waived: "waived",
  void: "void"
}, Jm = {
  "1month": "1month",
  "2month": "2month",
  "3month": "3month",
  "4month": "4month",
  "5month": "5month",
  "6month": "6month",
  "7month": "7month",
  "8month": "8month",
  "9month": "9month",
  "10month": "10month",
  "11month": "11month",
  "12month": "12month",
  "13month": "13month",
  "14month": "14month",
  "15month": "15month",
  "16month": "16month",
  "17month": "17month",
  "18month": "18month",
  "19month": "19month",
  "20month": "20month",
  "21month": "21month",
  "22month": "22month",
  "23month": "23month",
  "24month": "24month"
}, Zm = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, tg = {
  healthy: "healthy",
  unhealthy: "unhealthy"
}, eg = {
  merchant_cash_advance: "merchant_cash_advance",
  amazon_mca: "amazon_mca",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, ng = {
  application_created: "application_created",
  application_progress: "application_progress",
  manual_review: "manual_review",
  denied: "denied",
  multiple_manual_review: "multiple_manual_review",
  underwriting_manual_review: "underwriting_manual_review",
  underwriting_denial: "underwriting_denial",
  multiple_denied_reasons: "multiple_denied_reasons",
  kyc_manual_review: "kyc_manual_review",
  kyc_denial: "kyc_denial",
  bank_statement_upload_manual_review: "bank_statement_upload_manual_review",
  plaid_investigation_manual_review: "plaid_investigation_manual_review",
  funding_check_manual_review: "funding_check_manual_review",
  funding_check_denial: "funding_check_denial",
  auto_approval: "auto_approval",
  manual_review_approval: "manual_review_approval",
  manual_review_denial: "manual_review_denial",
  offer_refreshed: "offer_refreshed",
  offer_accepted: "offer_accepted",
  funding_completed: "funding_completed",
  expired: "expired",
  abandoned: "abandoned",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_pulled: "offer_pulled",
  consistency: "consistency",
  unsupported: "unsupported",
  metadata_update: "metadata_update"
}, rg = {
  created: "created",
  submitted: "submitted",
  approved: "approved",
  denied: "denied",
  abandoned: "abandoned",
  confirmed: "confirmed",
  funded: "funded"
}, sg = {
  in_progress: "in_progress",
  manual_review: "manual_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_accepted: "offer_accepted",
  funded: "funded"
}, ag = {
  principal: "principal",
  fee: "fee",
  payment: "payment",
  minimum_payment: "minimum_payment",
  automatic_payment: "automatic_payment",
  manual_payment: "manual_payment",
  generic: "generic",
  payment_adjustment: "payment_adjustment"
}, og = {
  card: "card",
  cash_advance: "cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl",
  merchant_bank: "merchant_bank"
}, ug = {
  full: "full",
  partial: "partial",
  none: "none",
  partner_does_not_have: "partner_does_not_have"
}, ig = {
  bankruptcy: "bankruptcy",
  excessive_credit_obligations: "excessive_credit_obligations",
  fraud: "fraud",
  insufficient_sales: "insufficient_sales",
  recent_sales_volatility: "recent_sales_volatility",
  liens: "liens",
  problematic_bank_account: "problematic_bank_account",
  problematic_payment_history: "problematic_payment_history",
  too_many_liens: "too_many_liens",
  unpaid_prior_account: "unpaid_prior_account",
  unserviceable_bank_account: "unserviceable_bank_account",
  kyc: "kyc",
  unlicensed_business: "unlicensed_business",
  program_terminated: "program_terminated",
  problematic_payment_history_with_others: "problematic_payment_history_with_others",
  collection_action_or_judgement: "collection_action_or_judgement",
  "": ""
}, cg = {
  HOME: "HOME",
  WORK: "WORK",
  LEGAL: "LEGAL",
  SHIPPING: "SHIPPING",
  PHYSICAL: "PHYSICAL"
}, yg = {
  celtic_bank: "celtic_bank",
  parafin: "parafin",
  parafin_spv_1: "parafin_spv_1",
  parafin_spv_2: "parafin_spv_2",
  parafin_spv_3: "parafin_spv_3"
}, d = (t, e) => o.get(
  "/business_cores",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), p = (t) => ["/business_cores", ...t ? [t] : []], _ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? p(t), queryFn: ({ signal: u }) => d(t, { signal: u, ...r }), ...n };
};
function dg(t, e) {
  const n = _(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const l = (t, e) => o.post(
  "/business_cores",
  t,
  e
), m = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return l(a, n);
  }, ...e };
}, pg = (t) => {
  const e = m(t);
  return i(e);
}, g = (t, e) => o.get(
  "/business_annotations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), q = (t) => ["/business_annotations", ...t ? [t] : []], f = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? q(t), queryFn: ({ signal: u }) => g(t, { signal: u, ...r }), ...n };
};
function _g(t, e) {
  const n = f(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const h = (t, e) => o.post(
  "/business_annotations",
  t,
  e
), O = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return h(a, n);
  }, ...e };
}, lg = (t) => {
  const e = O(t);
  return i(e);
}, K = (t, e) => o.get(
  "/cash_advance_charge_offs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), b = (t) => ["/cash_advance_charge_offs", ...t ? [t] : []], P = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? b(t), queryFn: ({ signal: u }) => K(t, { signal: u, ...r }), ...n };
};
function mg(t, e) {
  const n = P(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const k = (t, e) => o.post(
  "/cash_advance_charge_offs",
  t,
  e
), M = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return k(a, n);
  }, ...e };
}, gg = (t) => {
  const e = M(t);
  return i(e);
}, x = (t, e) => o.get(
  "/partners/doordash/admin_users",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), F = (t) => ["/partners/doordash/admin_users", ...t ? [t] : []], R = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? F(t), queryFn: ({ signal: u }) => x(t, { signal: u, ...r }), ...n };
};
function qg(t, e) {
  const n = R(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const B = (t, e) => o.get(
  "/cash_advance_pauses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), v = (t) => ["/cash_advance_pauses", ...t ? [t] : []], C = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? v(t), queryFn: ({ signal: u }) => B(t, { signal: u, ...r }), ...n };
};
function fg(t, e) {
  const n = C(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const E = (t, e) => o.post(
  "/cash_advance_pauses",
  t,
  e
), U = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return E(a, n);
  }, ...e };
}, hg = (t) => {
  const e = U(t);
  return i(e);
}, A = (t, e) => o.delete(
  `/cash_advance_pauses/${t}`,
  e
), S = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a } = s ?? {};
    return A(a, n);
  }, ...e };
}, Og = (t) => {
  const e = S(t);
  return i(e);
}, N = (t, e, n) => o.patch(
  `/cash_advance_pauses/${t}`,
  e,
  n
), D = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a, data: u } = s ?? {};
    return N(a, u, n);
  }, ...e };
}, Kg = (t) => {
  const e = D(t);
  return i(e);
}, G = (t, e) => o.get(
  "/repayment_plan_parameters",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), I = (t) => ["/repayment_plan_parameters", ...t ? [t] : []], T = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? I(t), queryFn: ({ signal: u }) => G(t, { signal: u, ...r }), ...n };
};
function bg(t, e) {
  const n = T(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const w = (t, e) => o.get(
  "/future_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Q = (t) => ["/future_activities", ...t ? [t] : []], L = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Q(t), queryFn: ({ signal: u }) => w(t, { signal: u, ...r }), ...n };
};
function Pg(t, e) {
  const n = L(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const W = (t, e) => o.get(
  "/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $ = (t) => ["/partners", ...t ? [t] : []], z = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $(t), queryFn: ({ signal: u }) => W(t, { signal: u, ...r }), ...n };
};
function kg(t, e) {
  const n = z(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const H = (t, e) => o.post(
  "/partners",
  t,
  e
), V = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return H(a, n);
  }, ...e };
}, Mg = (t) => {
  const e = V(t);
  return i(e);
}, Y = (t) => o.get(
  "/partners/underwriting",
  t
), j = () => ["/partners/underwriting"], X = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? j(), queryFn: ({ signal: a }) => Y({ signal: a, ...n }), ...e };
};
function xg(t) {
  const e = X(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const J = (t, e, n) => o.patch(
  `/partners/${t}`,
  e,
  n
), Z = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return J(a, u, n);
  }, ...e };
}, Fg = (t) => {
  const e = Z(t);
  return i(e);
}, tt = (t, e) => o.get(
  "/partner/api_keys",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), et = (t) => ["/partner/api_keys", ...t ? [t] : []], nt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? et(t), queryFn: ({ signal: u }) => tt(t, { signal: u, ...r }), ...n };
};
function Rg(t, e) {
  const n = nt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const rt = (t, e) => o.post(
  "/partner/api_keys",
  t,
  e
), st = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return rt(a, n);
  }, ...e };
}, Bg = (t) => {
  const e = st(t);
  return i(e);
}, at = (t) => o.get(
  "/partner/organizations",
  t
), ot = () => ["/partner/organizations"], ut = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ot(), queryFn: ({ signal: a }) => at({ signal: a, ...n }), ...e };
};
function vg(t) {
  const e = ut(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const it = (t, e) => o.post(
  "/partner/organizations",
  t,
  e
), ct = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return it(a, n);
  }, ...e };
}, Cg = (t) => {
  const e = ct(t);
  return i(e);
}, yt = (t, e) => o.post(
  "/partner/organizations/link_partner",
  t,
  e
), dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return yt(a, n);
  }, ...e };
}, Eg = (t) => {
  const e = dt(t);
  return i(e);
}, pt = (t, e) => o.get(
  "/partner/organizations/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _t = (t) => ["/partner/organizations/partners", ...t ? [t] : []], lt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _t(t), queryFn: ({ signal: u }) => pt(t, { signal: u, ...r }), ...n };
};
function Ug(t, e) {
  const n = lt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const mt = (t, e) => o.post(
  "/slack/authorize",
  t,
  e
), gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return mt(a, n);
  }, ...e };
}, Ag = (t) => {
  const e = gt(t);
  return i(e);
}, qt = (t) => o.get(
  "/slack/channels",
  t
), ft = () => ["/slack/channels"], ht = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ft(), queryFn: ({ signal: a }) => qt({ signal: a, ...n }), ...e };
};
function Sg(t) {
  const e = ht(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ot = (t, e) => o.delete(
  `/slack/channels/${t}`,
  e
), Kt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Ot(a, n);
  }, ...e };
}, Ng = (t) => {
  const e = Kt(t);
  return i(e);
}, bt = (t) => o.get(
  "/partner/users",
  t
), Pt = () => ["/partner/users"], kt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Pt(), queryFn: ({ signal: a }) => bt({ signal: a, ...n }), ...e };
};
function Dg(t) {
  const e = kt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Mt = (t, e) => o.post(
  "/partner/users",
  t,
  e
), xt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Mt(a, n);
  }, ...e };
}, Gg = (t) => {
  const e = xt(t);
  return i(e);
}, Ft = (t, e) => o.patch(
  "/partner/users",
  t,
  e
), Rt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ft(a, n);
  }, ...e };
}, Ig = (t) => {
  const e = Rt(t);
  return i(e);
}, Bt = (t, e) => o.delete(
  "/partner/delete_users",
  { data: t, ...e }
), vt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Bt(a, n);
  }, ...e };
}, Tg = (t) => {
  const e = vt(t);
  return i(e);
}, Ct = (t, e) => o.get(
  "/partner/public_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Et = (t) => ["/partner/public_configs", ...t ? [t] : []], Ut = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Et(t), queryFn: ({ signal: u }) => Ct(t, { signal: u, ...r }), ...n };
};
function wg(t, e) {
  const n = Ut(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const At = (t, e) => o.get(
  "/partner/configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), St = (t) => ["/partner/configs", ...t ? [t] : []], Nt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? St(t), queryFn: ({ signal: u }) => At(t, { signal: u, ...r }), ...n };
};
function Qg(t, e) {
  const n = Nt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Dt = (t, e, n) => o.patch(
  `/partner/configs/${t}`,
  e,
  n
), Gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Dt(a, u, n);
  }, ...e };
}, Lg = (t) => {
  const e = Gt(t);
  return i(e);
}, It = (t) => o.get(
  "/partner/sync_webhooks",
  t
), Tt = () => ["/partner/sync_webhooks"], wt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Tt(), queryFn: ({ signal: a }) => It({ signal: a, ...n }), ...e };
};
function Wg(t) {
  const e = wt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Qt = (t, e) => o.post(
  "/partner/sync_webhooks",
  t,
  e
), Lt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qt(a, n);
  }, ...e };
}, $g = (t) => {
  const e = Lt(t);
  return i(e);
}, Wt = (t, e) => o.delete(
  `/partner/sync_webhooks/${t}`,
  e
), $t = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Wt(a, n);
  }, ...e };
}, zg = (t) => {
  const e = $t(t);
  return i(e);
}, zt = (t, e, n) => o.patch(
  `/partner/sync_webhooks/${t}`,
  e,
  n
), Ht = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return zt(a, u, n);
  }, ...e };
}, Hg = (t) => {
  const e = Ht(t);
  return i(e);
}, Vt = (t, e) => o.post(
  "/auth/tokens",
  t,
  e
), Yt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Vt(a, n);
  }, ...e };
}, Vg = (t) => {
  const e = Yt(t);
  return i(e);
}, jt = (t, e) => o.post(
  "/hackathon/partners",
  t,
  e
), Xt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return jt(a, n);
  }, ...e };
}, Yg = (t) => {
  const e = Xt(t);
  return i(e);
}, Jt = (t) => o.post(
  "/hackathon/create_pilot",
  void 0,
  t
), Zt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Jt(n), ...e };
}, jg = (t) => {
  const e = Zt(t);
  return i(e);
}, te = (t) => o.get(
  "/hackathon/get_pilot",
  t
), ee = () => ["/hackathon/get_pilot"], ne = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ee(), queryFn: ({ signal: a }) => te({ signal: a, ...n }), ...e };
};
function Xg(t) {
  const e = ne(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const re = (t) => o.post(
  "/hackathon/generate_offers",
  void 0,
  t
), se = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => re(n), ...e };
}, Jg = (t) => {
  const e = se(t);
  return i(e);
}, ae = (t) => o.post(
  "/hackathon/await_parafin_representative",
  void 0,
  t
), oe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ae(n), ...e };
}, Zg = (t) => {
  const e = oe(t);
  return i(e);
}, ue = (t) => o.post(
  "/hackathon/send_offers",
  void 0,
  t
), ie = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ue(n), ...e };
}, tq = (t) => {
  const e = ie(t);
  return i(e);
}, ce = (t, e) => o.post(
  "/hackathon/gmv",
  t,
  e
), ye = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ce(a, n);
  }, ...e };
}, eq = (t) => {
  const e = ye(t);
  return i(e);
}, de = (t) => o.post(
  "/hackathon/reset",
  void 0,
  t
), pe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => de(n), ...e };
}, nq = (t) => {
  const e = pe(t);
  return i(e);
}, _e = (t) => o.post(
  "/hackathon/map_offers_to_emails",
  void 0,
  t
), le = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => _e(n), ...e };
}, rq = (t) => {
  const e = le(t);
  return i(e);
}, me = (t) => o.post(
  "/hackathon/upload_sales_csv",
  void 0,
  t
), ge = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => me(n), ...e };
}, sq = (t) => {
  const e = ge(t);
  return i(e);
}, qe = (t, e) => o.get(
  "/statements_v2",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fe = (t) => ["/statements_v2", ...t ? [t] : []], he = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fe(t), queryFn: ({ signal: u }) => qe(t, { signal: u, ...r }), ...n };
};
function aq(t, e) {
  const n = he(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Oe = (t, e) => o.post(
  "/marketing/review",
  t,
  e
), Ke = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Oe(a, n);
  }, ...e };
}, oq = (t) => {
  const e = Ke(t);
  return i(e);
}, be = (t) => o.get(
  "/marketing/integrations",
  t
), Pe = () => ["/marketing/integrations"], ke = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Pe(), queryFn: ({ signal: a }) => be({ signal: a, ...n }), ...e };
};
function uq(t) {
  const e = ke(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Me = (t, e) => o.post(
  "/marketing/integrations",
  t,
  e
), xe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Me(a, n);
  }, ...e };
}, iq = (t) => {
  const e = xe(t);
  return i(e);
}, Fe = (t, e) => o.delete(
  `/marketing/integrations/${t}`,
  e
), Re = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { vendor: a } = s ?? {};
    return Fe(a, n);
  }, ...e };
}, cq = (t) => {
  const e = Re(t);
  return i(e);
}, Be = (t) => o.get(
  "/marketing/gtm_toolkit",
  t
), ve = () => ["/marketing/gtm_toolkit"], Ce = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ve(), queryFn: ({ signal: a }) => Be({ signal: a, ...n }), ...e };
};
function yq(t) {
  const e = Ce(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ee = (t) => o.get(
  "/marketing/paragon_token",
  t
), Ue = () => ["/marketing/paragon_token"], Ae = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ue(), queryFn: ({ signal: a }) => Ee({ signal: a, ...n }), ...e };
};
function dq(t) {
  const e = Ae(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Se = (t, e) => o.get(
  "/beneficial_owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ne = (t) => ["/beneficial_owners", ...t ? [t] : []], De = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ne(t), queryFn: ({ signal: u }) => Se(t, { signal: u, ...r }), ...n };
};
function pq(t, e) {
  const n = De(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ge = (t, e) => o.post(
  "/beneficial_owners",
  t,
  e
), Ie = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ge(a, n);
  }, ...e };
}, _q = (t) => {
  const e = Ie(t);
  return i(e);
}, Te = (t, e) => o.delete(
  `/beneficial_owners/${t}`,
  e
), we = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Te(a, n);
  }, ...e };
}, lq = (t) => {
  const e = we(t);
  return i(e);
}, Qe = (t, e, n) => o.patch(
  `/beneficial_owners/${t}`,
  e,
  n
), Le = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Qe(a, u, n);
  }, ...e };
}, mq = (t) => {
  const e = Le(t);
  return i(e);
}, We = (t, e) => o.get(
  "/owner_is_beneficial_owner",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $e = (t) => ["/owner_is_beneficial_owner", ...t ? [t] : []], ze = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $e(t), queryFn: ({ signal: u }) => We(t, { signal: u, ...r }), ...n };
};
function gq(t, e) {
  const n = ze(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const He = (t, e) => o.patch(
  "/owner_is_beneficial_owner",
  t,
  e
), Ve = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return He(a, n);
  }, ...e };
}, qq = (t) => {
  const e = Ve(t);
  return i(e);
}, Ye = (t, e) => o.get(
  "/banks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), je = (t) => ["/banks", ...t ? [t] : []], Xe = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? je(t), queryFn: ({ signal: u }) => Ye(t, { signal: u, ...r }), ...n };
};
function fq(t, e) {
  const n = Xe(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Je = (t, e) => o.get(
  "/business_table_rows/product_agnostic",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ze = (t) => ["/business_table_rows/product_agnostic", ...t ? [t] : []], tn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ze(t), queryFn: ({ signal: u }) => Je(t, { signal: u, ...r }), ...n };
};
function hq(t, e) {
  const n = tn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const en = (t, e) => o.get(
  "/business_table_rows/mca",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), nn = (t) => ["/business_table_rows/mca", ...t ? [t] : []], rn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? nn(t), queryFn: ({ signal: u }) => en(t, { signal: u, ...r }), ...n };
};
function Oq(t, e) {
  const n = rn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const sn = (t, e) => o.get(
  "/business_table_rows/loan",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), an = (t) => ["/business_table_rows/loan", ...t ? [t] : []], on = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? an(t), queryFn: ({ signal: u }) => sn(t, { signal: u, ...r }), ...n };
};
function Kq(t, e) {
  const n = on(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const un = (t, e) => o.get(
  "/business_table_rows/card",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), cn = (t) => ["/business_table_rows/card", ...t ? [t] : []], yn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cn(t), queryFn: ({ signal: u }) => un(t, { signal: u, ...r }), ...n };
};
function bq(t, e) {
  const n = yn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const dn = (t, e) => o.get(
  "/business/officers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pn = (t) => ["/business/officers", ...t ? [t] : []], _n = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pn(t), queryFn: ({ signal: u }) => dn(t, { signal: u, ...r }), ...n };
};
function Pq(t, e) {
  const n = _n(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ln = (t, e) => o.post(
  "/business/officers",
  t,
  e
), mn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ln(a, n);
  }, ...e };
}, kq = (t) => {
  const e = mn(t);
  return i(e);
}, gn = (t, e) => o.delete(
  `/business/officers/${t}`,
  e
), qn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return gn(a, n);
  }, ...e };
}, Mq = (t) => {
  const e = qn(t);
  return i(e);
}, fn = (t, e, n) => o.patch(
  `/business/officers/${t}`,
  e,
  n
), hn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return fn(a, u, n);
  }, ...e };
}, xq = (t) => {
  const e = hn(t);
  return i(e);
}, On = (t) => o.post(
  "/dataingestion/oneschema/generate_jwt",
  void 0,
  t
), Kn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => On(n), ...e };
}, Fq = (t) => {
  const e = Kn(t);
  return i(e);
}, bn = (t, e) => o.get(
  "/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pn = (t) => ["/dataset_uploads", ...t ? [t] : []], kn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pn(t), queryFn: ({ signal: u }) => bn(t, { signal: u, ...r }), ...n };
};
function Rq(t, e) {
  const n = kn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Mn = (t, e) => o.get(
  "/dataingestion/manual_uploads/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xn = (t) => ["/dataingestion/manual_uploads/dataset_upload_validation_results", ...t ? [t] : []], Fn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xn(t), queryFn: ({ signal: u }) => Mn(t, { signal: u, ...r }), ...n };
};
function Bq(t, e) {
  const n = Fn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Rn = (t, e) => o.get(
  "/dataingestion/s3/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Bn = (t) => ["/dataingestion/s3/dataset_uploads", ...t ? [t] : []], vn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Bn(t), queryFn: ({ signal: u }) => Rn(t, { signal: u, ...r }), ...n };
};
function vq(t, e) {
  const n = vn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Cn = (t, e) => o.get(
  "/dataingestion/s3/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), En = (t) => ["/dataingestion/s3/dataset_upload_validation_results", ...t ? [t] : []], Un = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? En(t), queryFn: ({ signal: u }) => Cn(t, { signal: u, ...r }), ...n };
};
function Cq(t, e) {
  const n = Un(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const An = (t, e) => o.get(
  "/dataingestion/s3/dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sn = (t) => ["/dataingestion/s3/dataset_integrations", ...t ? [t] : []], Nn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sn(t), queryFn: ({ signal: u }) => An(t, { signal: u, ...r }), ...n };
};
function Eq(t, e) {
  const n = Nn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Dn = (t, e) => o.post(
  "/dataingestion/s3/dataset_integrations",
  t,
  e
), Gn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Dn(a, n);
  }, ...e };
}, Uq = (t) => {
  const e = Gn(t);
  return i(e);
}, In = (t, e) => o.get(
  "/dataingestion/s3/org_dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Tn = (t) => ["/dataingestion/s3/org_dataset_integrations", ...t ? [t] : []], wn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Tn(t), queryFn: ({ signal: u }) => In(t, { signal: u, ...r }), ...n };
};
function Aq(t, e) {
  const n = wn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qn = (t, e) => o.post(
  "/dataingestion/s3/org_dataset_integrations",
  t,
  e
), Ln = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qn(a, n);
  }, ...e };
}, Sq = (t) => {
  const e = Ln(t);
  return i(e);
}, Wn = (t, e) => o.get(
  "/dataingestion/s3/dataset_ingestion_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $n = (t) => ["/dataingestion/s3/dataset_ingestion_configs", ...t ? [t] : []], zn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $n(t), queryFn: ({ signal: u }) => Wn(t, { signal: u, ...r }), ...n };
};
function Nq(t, e) {
  const n = zn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hn = (t, e) => o.put(
  "/dataingestion/s3/dataset_ingestion_configs",
  t,
  e
), Vn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hn(a, n);
  }, ...e };
}, Dq = (t) => {
  const e = Vn(t);
  return i(e);
}, Yn = (t, e) => o.post(
  "/dataingestion/s3/run_dataset_ingestion",
  t,
  e
), jn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yn(a, n);
  }, ...e };
}, Gq = (t) => {
  const e = jn(t);
  return i(e);
}, Xn = (t, e) => o.get(
  "/dataingestion/schema",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Jn = (t) => ["/dataingestion/schema", ...t ? [t] : []], Zn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jn(t), queryFn: ({ signal: u }) => Xn(t, { signal: u, ...r }), ...n };
};
function Iq(t, e) {
  const n = Zn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const tr = (t, e) => o.get(
  "/dataingestion/stripe_ingestions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), er = (t) => ["/dataingestion/stripe_ingestions", ...t ? [t] : []], nr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? er(t), queryFn: ({ signal: u }) => tr(t, { signal: u, ...r }), ...n };
};
function Tq(t, e) {
  const n = nr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const rr = (t, e) => o.post(
  "/dataingestion/stripe_ingestions",
  t,
  e
), sr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return rr(a, n);
  }, ...e };
}, wq = (t) => {
  const e = sr(t);
  return i(e);
}, ar = (t, e) => o.patch(
  "/dataingestion/stripe_ingestions",
  t,
  e
), or = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ar(a, n);
  }, ...e };
}, Qq = (t) => {
  const e = or(t);
  return i(e);
}, ur = (t, e) => o.get(
  "/onboarding/business_requirements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ir = (t) => ["/onboarding/business_requirements", ...t ? [t] : []], cr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ir(t), queryFn: ({ signal: u }) => ur(t, { signal: u, ...r }), ...n };
};
function Lq(t, e) {
  const n = cr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yr = (t, e) => o.get(
  "/capital_product_prospective_terms_of_service",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dr = (t) => ["/capital_product_prospective_terms_of_service", ...t ? [t] : []], pr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dr(t), queryFn: ({ signal: u }) => yr(t, { signal: u, ...r }), ...n };
};
function Wq(t, e) {
  const n = pr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const _r = (t, e) => o.get(
  "/capital_products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), lr = (t) => ["/capital_products", ...t ? [t] : []], mr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lr(t), queryFn: ({ signal: u }) => _r(t, { signal: u, ...r }), ...n };
};
function $q(t, e) {
  const n = mr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const gr = (t, e) => o.post(
  "/capital_products",
  t,
  e
), qr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return gr(a, n);
  }, ...e };
}, zq = (t) => {
  const e = qr(t);
  return i(e);
}, fr = (t, e, n) => o.patch(
  `/capital_products/${t}`,
  e,
  n
), hr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return fr(a, u, n);
  }, ...e };
}, Hq = (t) => {
  const e = hr(t);
  return i(e);
}, Or = (t, e) => o.get(
  "/capital_product_offer_collections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Kr = (t) => ["/capital_product_offer_collections", ...t ? [t] : []], br = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kr(t), queryFn: ({ signal: u }) => Or(t, { signal: u, ...r }), ...n };
};
function Vq(t, e) {
  const n = br(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pr = (t, e) => o.post(
  "/capital_product_offer_collections",
  t,
  e
), kr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Pr(a, n);
  }, ...e };
}, Yq = (t) => {
  const e = kr(t);
  return i(e);
}, Mr = (t, e) => o.get(
  "/capital_product_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xr = (t) => ["/capital_product_activities", ...t ? [t] : []], Fr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xr(t), queryFn: ({ signal: u }) => Mr(t, { signal: u, ...r }), ...n };
};
function jq(t, e) {
  const n = Fr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Rr = (t, e) => o.get(
  "/capital_product_applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Br = (t) => ["/capital_product_applications", ...t ? [t] : []], vr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Br(t), queryFn: ({ signal: u }) => Rr(t, { signal: u, ...r }), ...n };
};
function Xq(t, e) {
  const n = vr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Cr = (t, e) => o.post(
  "/capital_product_applications",
  t,
  e
), Er = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cr(a, n);
  }, ...e };
}, Jq = (t) => {
  const e = Er(t);
  return i(e);
}, Ur = (t, e) => o.get(
  "/capital_product_application_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ar = (t) => ["/capital_product_application_snapshots", ...t ? [t] : []], Sr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ar(t), queryFn: ({ signal: u }) => Ur(t, { signal: u, ...r }), ...n };
};
function Zq(t, e) {
  const n = Sr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Nr = (t, e) => o.post(
  "/net_top_up",
  t,
  e
), Dr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Nr(a, n);
  }, ...e };
}, tf = (t) => {
  const e = Dr(t);
  return i(e);
}, Gr = (t, e) => o.get(
  "/capital_product_net_top_up_itemization",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ir = (t) => ["/capital_product_net_top_up_itemization", ...t ? [t] : []], Tr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ir(t), queryFn: ({ signal: u }) => Gr(t, { signal: u, ...r }), ...n };
};
function ef(t, e) {
  const n = Tr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const wr = (t, e) => o.get(
  "/capital_product_state_disclosure",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qr = (t) => ["/capital_product_state_disclosure", ...t ? [t] : []], Lr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qr(t), queryFn: ({ signal: u }) => wr(t, { signal: u, ...r }), ...n };
};
function nf(t, e) {
  const n = Lr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wr = (t) => o.get(
  "/benchmark_capital_products_ownerships",
  t
), $r = () => ["/benchmark_capital_products_ownerships"], zr = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? $r(), queryFn: ({ signal: a }) => Wr({ signal: a, ...n }), ...e };
};
function rf(t) {
  const e = zr(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Hr = (t, e) => o.get(
  "/capital_product_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vr = (t) => ["/capital_product_repayment_schedule", ...t ? [t] : []], Yr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vr(t), queryFn: ({ signal: u }) => Hr(t, { signal: u, ...r }), ...n };
};
function sf(t, e) {
  const n = Yr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const jr = (t, e) => o.post(
  "/offered_capital_product_discount",
  t,
  e
), Xr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return jr(a, n);
  }, ...e };
}, af = (t) => {
  const e = Xr(t);
  return i(e);
}, Jr = (t, e) => o.get(
  "/capital_product_intents",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zr = (t) => ["/capital_product_intents", ...t ? [t] : []], ts = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zr(t), queryFn: ({ signal: u }) => Jr(t, { signal: u, ...r }), ...n };
};
function of(t, e) {
  const n = ts(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const es = (t, e) => o.post(
  "/capital_product_intents",
  t,
  e
), ns = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return es(a, n);
  }, ...e };
}, uf = (t) => {
  const e = ns(t);
  return i(e);
}, rs = (t, e, n) => o.patch(
  `/capital_product_intents/${t}`,
  e,
  n
), ss = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return rs(a, u, n);
  }, ...e };
}, cf = (t) => {
  const e = ss(t);
  return i(e);
}, as = (t, e) => o.get(
  "/counteroffer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), os = (t) => ["/counteroffer", ...t ? [t] : []], us = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? os(t), queryFn: ({ signal: u }) => as(t, { signal: u, ...r }), ...n };
};
function yf(t, e) {
  const n = us(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const is = (t, e) => o.post(
  "/capital_product_super_applications",
  t,
  e
), cs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return is(a, n);
  }, ...e };
}, df = (t) => {
  const e = cs(t);
  return i(e);
}, ys = (t, e) => o.post(
  "/capital_product_super_application_actions",
  t,
  e
), ds = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ys(a, n);
  }, ...e };
}, pf = (t) => {
  const e = ds(t);
  return i(e);
}, ps = (t, e) => o.get(
  "/flex_loan_minimum_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _s = (t) => ["/flex_loan_minimum_repayment_schedule", ...t ? [t] : []], ls = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _s(t), queryFn: ({ signal: u }) => ps(t, { signal: u, ...r }), ...n };
};
function _f(t, e) {
  const n = ls(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ms = (t, e) => o.post(
  "/trigger_run_celtics_checklist",
  t,
  e
), gs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ms(a, n);
  }, ...e };
}, lf = (t) => {
  const e = gs(t);
  return i(e);
}, qs = (t, e) => o.get(
  "/run_ofac_screening_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fs = (t) => ["/run_ofac_screening_results", ...t ? [t] : []], hs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fs(t), queryFn: ({ signal: u }) => qs(t, { signal: u, ...r }), ...n };
};
function mf(t, e) {
  const n = hs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Os = (t, e, n) => o.patch(
  `/clear_watchlist_hits/${t}`,
  e,
  n
), Ks = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Os(a, u, n);
  }, ...e };
}, gf = (t) => {
  const e = Ks(t);
  return i(e);
}, bs = (t, e) => o.get(
  "/term_loan_prospective_repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ps = (t) => ["/term_loan_prospective_repayments", ...t ? [t] : []], ks = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ps(t), queryFn: ({ signal: u }) => bs(t, { signal: u, ...r }), ...n };
};
function qf(t, e) {
  const n = ks(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ms = (t, e) => o.get(
  "/funding/bank_account_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xs = (t) => ["/funding/bank_account_info", ...t ? [t] : []], Fs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xs(t), queryFn: ({ signal: u }) => Ms(t, { signal: u, ...r }), ...n };
};
function ff(t, e) {
  const n = Fs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Rs = (t, e) => o.post(
  "/funding/bank_account_info",
  t,
  e
), Bs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Rs(a, n);
  }, ...e };
}, hf = (t) => {
  const e = Bs(t);
  return i(e);
}, vs = (t, e, n) => o.patch(
  `/funding/bank_account_info/${t}`,
  e,
  n
), Cs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return vs(a, u, n);
  }, ...e };
}, Of = (t) => {
  const e = Cs(t);
  return i(e);
}, Es = (t, e) => o.get(
  "/funding/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Us = (t) => ["/funding/accounts", ...t ? [t] : []], As = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Us(t), queryFn: ({ signal: u }) => Es(t, { signal: u, ...r }), ...n };
};
function Kf(t, e) {
  const n = As(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ss = (t, e) => o.post(
  "/funding/accounts",
  t,
  e
), Ns = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ss(a, n);
  }, ...e };
}, bf = (t) => {
  const e = Ns(t);
  return i(e);
}, Ds = (t, e) => o.get(
  "/funding/plaid_linkage_with_investigation",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Gs = (t) => ["/funding/plaid_linkage_with_investigation", ...t ? [t] : []], Is = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gs(t), queryFn: ({ signal: u }) => Ds(t, { signal: u, ...r }), ...n };
};
function Pf(t, e) {
  const n = Is(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ts = (t, e) => o.post(
  "/funding/plaid_linkage_with_investigation",
  t,
  e
), ws = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ts(a, n);
  }, ...e };
}, kf = (t) => {
  const e = ws(t);
  return i(e);
}, Qs = (t, e, n) => o.patch(
  `/persona_fallback_inquiries/${t}`,
  e,
  n
), Ls = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Qs(a, u, n);
  }, ...e };
}, Mf = (t) => {
  const e = Ls(t);
  return i(e);
}, Ws = (t, e) => o.get(
  "/onboarding/states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $s = (t) => ["/onboarding/states", ...t ? [t] : []], zs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $s(t), queryFn: ({ signal: u }) => Ws(t, { signal: u, ...r }), ...n };
};
function xf(t, e) {
  const n = zs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hs = (t, e) => o.get(
  "/onboarding/info_needed",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vs = (t) => ["/onboarding/info_needed", ...t ? [t] : []], Ys = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vs(t), queryFn: ({ signal: u }) => Hs(t, { signal: u, ...r }), ...n };
};
function Ff(t, e) {
  const n = Ys(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const js = (t, e) => o.get(
  "/business_identities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xs = (t) => ["/business_identities", ...t ? [t] : []], Js = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xs(t), queryFn: ({ signal: u }) => js(t, { signal: u, ...r }), ...n };
};
function Rf(t, e) {
  const n = Js(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Zs = (t, e, n) => o.patch(
  `/business_identities/${t}`,
  e,
  n
), ta = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Zs(a, u, n);
  }, ...e };
}, Bf = (t) => {
  const e = ta(t);
  return i(e);
}, ea = (t, e) => o.get(
  "/owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), na = (t) => ["/owners", ...t ? [t] : []], ra = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? na(t), queryFn: ({ signal: u }) => ea(t, { signal: u, ...r }), ...n };
};
function vf(t, e) {
  const n = ra(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const sa = (t, e, n) => o.patch(
  `/owners/${t}`,
  e,
  n
), aa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return sa(a, u, n);
  }, ...e };
}, Cf = (t) => {
  const e = aa(t);
  return i(e);
}, oa = (t, e) => o.post(
  "/business",
  t,
  e
), ua = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return oa(a, n);
  }, ...e };
}, Ef = (t) => {
  const e = ua(t);
  return i(e);
}, ia = (t, e) => o.post(
  "/merchant_bank/business",
  t,
  e
), ca = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ia(a, n);
  }, ...e };
}, Uf = (t) => {
  const e = ca(t);
  return i(e);
}, ya = (t) => o.post(
  "/amazon_redirect_url",
  void 0,
  t
), da = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ya(n), ...e };
}, Af = (t) => {
  const e = da(t);
  return i(e);
}, pa = (t, e) => o.post(
  "/spd",
  t,
  e
), _a = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return pa(a, n);
  }, ...e };
}, Sf = (t) => {
  const e = _a(t);
  return i(e);
}, la = (t, e) => o.post(
  "/capital_product_business",
  t,
  e
), ma = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return la(a, n);
  }, ...e };
}, Nf = (t) => {
  const e = ma(t);
  return i(e);
}, ga = (t, e) => o.patch(
  "/flex_loan_age",
  t,
  e
), qa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ga(a, n);
  }, ...e };
}, Df = (t) => {
  const e = qa(t);
  return i(e);
}, fa = (t, e) => o.patch(
  "/flex_loan_ownership",
  t,
  e
), ha = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return fa(a, n);
  }, ...e };
}, Gf = (t) => {
  const e = ha(t);
  return i(e);
}, Oa = (t, e) => o.patch(
  "/flex_loan_fund",
  t,
  e
), Ka = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Oa(a, n);
  }, ...e };
}, If = (t) => {
  const e = Ka(t);
  return i(e);
}, ba = (t, e) => o.post(
  "/setup_amazon_top_up",
  void 0,
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { params: a } = s ?? {};
    return ba(a, n);
  }, ...e };
}, Tf = (t) => {
  const e = Pa(t);
  return i(e);
}, ka = (t, e) => o.get(
  "/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ma = (t) => ["/cards", ...t ? [t] : []], xa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ma(t), queryFn: ({ signal: u }) => ka(t, { signal: u, ...r }), ...n };
};
function wf(t, e) {
  const n = xa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Fa = (t, e) => o.post(
  "/cards",
  t,
  e
), Ra = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Fa(a, n);
  }, ...e };
}, Qf = (t) => {
  const e = Ra(t);
  return i(e);
}, Ba = (t, e) => o.get(
  "/businesses/products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), va = (t) => ["/businesses/products", ...t ? [t] : []], Ca = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? va(t), queryFn: ({ signal: u }) => Ba(t, { signal: u, ...r }), ...n };
};
function Lf(t, e) {
  const n = Ca(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ea = (t, e) => o.post(
  "/business/auth_link",
  t,
  e
), Ua = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ea(a, n);
  }, ...e };
}, Wf = (t) => {
  const e = Ua(t);
  return i(e);
}, Aa = (t, e) => o.get(
  "/document_upload/group",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sa = (t) => ["/document_upload/group", ...t ? [t] : []], Na = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sa(t), queryFn: ({ signal: u }) => Aa(t, { signal: u, ...r }), ...n };
};
function $f(t, e) {
  const n = Na(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Da = (t, e) => o.post(
  "/document_upload/group",
  t,
  e
), Ga = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Da(a, n);
  }, ...e };
}, zf = (t) => {
  const e = Ga(t);
  return i(e);
}, Ia = (t, e) => o.get(
  "/document_upload/list",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ta = (t) => ["/document_upload/list", ...t ? [t] : []], wa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ta(t), queryFn: ({ signal: u }) => Ia(t, { signal: u, ...r }), ...n };
};
function Hf(t, e) {
  const n = wa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qa = (t, e) => o.get(
  "/restriction",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), La = (t) => ["/restriction", ...t ? [t] : []], Wa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? La(t), queryFn: ({ signal: u }) => Qa(t, { signal: u, ...r }), ...n };
};
function Vf(t, e) {
  const n = Wa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $a = (t, e) => o.post(
  "/restriction",
  t,
  e
), za = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $a(a, n);
  }, ...e };
}, Yf = (t) => {
  const e = za(t);
  return i(e);
}, Ha = (t, e) => o.get(
  "/restrictions/details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Va = (t) => ["/restrictions/details", ...t ? [t] : []], Ya = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Va(t), queryFn: ({ signal: u }) => Ha(t, { signal: u, ...r }), ...n };
};
function jf(t, e) {
  const n = Ya(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ja = (t, e) => o.get(
  "/liens",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xa = (t) => ["/liens", ...t ? [t] : []], Ja = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xa(t), queryFn: ({ signal: u }) => ja(t, { signal: u, ...r }), ...n };
};
function Xf(t, e) {
  const n = Ja(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Za = (t, e, n) => o.patch(
  `/liens/${t}`,
  e,
  n
), to = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Za(a, u, n);
  }, ...e };
}, Jf = (t) => {
  const e = to(t);
  return i(e);
}, eo = (t, e) => o.post(
  "/opt_ins/trigger",
  t,
  e
), no = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return eo(a, n);
  }, ...e };
}, Zf = (t) => {
  const e = no(t);
  return i(e);
}, ro = (t, e) => o.get(
  "/businesses/daily_sales_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), so = (t) => ["/businesses/daily_sales_records", ...t ? [t] : []], ao = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? so(t), queryFn: ({ signal: u }) => ro(t, { signal: u, ...r }), ...n };
};
function th(t, e) {
  const n = ao(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const oo = (t, e) => o.get(
  "/parafinder/businesses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), uo = (t) => ["/parafinder/businesses", ...t ? [t] : []], io = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? uo(t), queryFn: ({ signal: u }) => oo(t, { signal: u, ...r }), ...n };
};
function eh(t, e) {
  const n = io(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const co = (t) => o.get(
  "/parafinder/business_types",
  t
), yo = () => ["/parafinder/business_types"], po = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? yo(), queryFn: ({ signal: a }) => co({ signal: a, ...n }), ...e };
};
function nh(t) {
  const e = po(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const _o = (t, e) => o.get(
  "/parafinder/summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), lo = (t) => ["/parafinder/summary", ...t ? [t] : []], mo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lo(t), queryFn: ({ signal: u }) => _o(t, { signal: u, ...r }), ...n };
};
function rh(t, e) {
  const n = mo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const go = (t, e) => o.get(
  "/parafinderV2/capital_info_summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qo = (t) => ["/parafinderV2/capital_info_summary", ...t ? [t] : []], fo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qo(t), queryFn: ({ signal: u }) => go(t, { signal: u, ...r }), ...n };
};
function sh(t, e) {
  const n = fo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ho = (t, e) => o.get(
  `/merchant_bank/accounts/${t}`,
  e
), Oo = (t) => [`/merchant_bank/accounts/${t}`], Ko = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Oo(t), queryFn: ({ signal: u }) => ho(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ah(t, e) {
  const n = Ko(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const bo = (t, e) => o.get(
  `/merchant_bank/accounts/${t}/balances`,
  e
), Po = (t) => [`/merchant_bank/accounts/${t}/balances`], ko = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Po(t), queryFn: ({ signal: u }) => bo(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function oh(t, e) {
  const n = ko(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Mo = (t, e) => o.get(
  "/merchant_bank/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xo = (t) => ["/merchant_bank/accounts", ...t ? [t] : []], Fo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xo(t), queryFn: ({ signal: u }) => Mo(t, { signal: u, ...r }), ...n };
};
function uh(t, e) {
  const n = Fo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ro = (t, e) => o.post(
  "/merchant_bank/accounts",
  t,
  e
), Bo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ro(a, n);
  }, ...e };
}, ih = (t) => {
  const e = Bo(t);
  return i(e);
}, vo = (t, e) => o.post(
  "/merchant_bank/inner_transfers",
  t,
  e
), Co = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return vo(a, n);
  }, ...e };
}, ch = (t) => {
  const e = Co(t);
  return i(e);
}, Eo = (t, e, n) => o.get(
  `/merchant_bank/transactions/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Uo = (t, e) => [`/merchant_bank/transactions/${t}`, ...e ? [e] : []], Ao = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Uo(t, e), queryFn: ({ signal: y }) => Eo(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function yh(t, e, n) {
  const r = Ao(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const So = (t, e) => o.get(
  "/merchant_bank/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), No = (t) => ["/merchant_bank/transactions", ...t ? [t] : []], Do = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? No(t), queryFn: ({ signal: u }) => So(t, { signal: u, ...r }), ...n };
};
function dh(t, e) {
  const n = Do(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Go = (t) => o.get(
  "/merchant_bank/transfer_fees",
  t
), Io = () => ["/merchant_bank/transfer_fees"], To = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Io(), queryFn: ({ signal: a }) => Go({ signal: a, ...n }), ...e };
};
function ph(t) {
  const e = To(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const wo = (t, e) => o.get(
  "/merchant_bank/transfer_limits",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qo = (t) => ["/merchant_bank/transfer_limits", ...t ? [t] : []], Lo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qo(t), queryFn: ({ signal: u }) => wo(t, { signal: u, ...r }), ...n };
};
function _h(t, e) {
  const n = Lo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wo = (t) => o.get(
  "/merchant_bank/interest_details",
  t
), $o = () => ["/merchant_bank/interest_details"], zo = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? $o(), queryFn: ({ signal: a }) => Wo({ signal: a, ...n }), ...e };
};
function lh(t) {
  const e = zo(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ho = (t, e) => o.get(
  "/merchant_bank/recipients",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vo = (t) => ["/merchant_bank/recipients", ...t ? [t] : []], Yo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vo(t), queryFn: ({ signal: u }) => Ho(t, { signal: u, ...r }), ...n };
};
function mh(t, e) {
  const n = Yo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const jo = (t, e) => o.post(
  "/merchant_bank/recipients",
  t,
  e
), Xo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return jo(a, n);
  }, ...e };
}, gh = (t) => {
  const e = Xo(t);
  return i(e);
}, Jo = (t, e) => o.get(
  `/merchant_bank/recipients/${t}`,
  e
), Zo = (t) => [`/merchant_bank/recipients/${t}`], tu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zo(t), queryFn: ({ signal: u }) => Jo(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function qh(t, e) {
  const n = tu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const eu = (t, e) => o.delete(
  `/merchant_bank/recipients/${t}`,
  e
), nu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return eu(a, n);
  }, ...e };
}, fh = (t) => {
  const e = nu(t);
  return i(e);
}, ru = (t, e, n) => o.patch(
  `/merchant_bank/recipients/${t}`,
  e,
  n
), su = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return ru(a, u, n);
  }, ...e };
}, hh = (t) => {
  const e = su(t);
  return i(e);
}, au = (t, e, n) => o.post(
  `/merchant_bank/recipients/${t}/payment_rails`,
  e,
  n
), ou = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return au(a, u, n);
  }, ...e };
}, Oh = (t) => {
  const e = ou(t);
  return i(e);
}, uu = (t, e) => o.post(
  "/merchant_bank/recipient_transfers",
  t,
  e
), iu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return uu(a, n);
  }, ...e };
}, Kh = (t) => {
  const e = iu(t);
  return i(e);
}, cu = (t, e, n) => o.patch(
  `/merchant_bank/recipient_transfers/${t}`,
  e,
  n
), yu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return cu(a, u, n);
  }, ...e };
}, bh = (t) => {
  const e = yu(t);
  return i(e);
}, du = (t, e) => o.get(
  "/merchant_bank/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pu = (t) => ["/merchant_bank/cards", ...t ? [t] : []], _u = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pu(t), queryFn: ({ signal: u }) => du(t, { signal: u, ...r }), ...n };
};
function Ph(t, e) {
  const n = _u(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const lu = (t, e) => o.post(
  "/merchant_bank/cards",
  t,
  e
), mu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return lu(a, n);
  }, ...e };
}, kh = (t) => {
  const e = mu(t);
  return i(e);
}, gu = (t, e, n) => o.patch(
  `/merchant_bank/card/cardholders/${t}`,
  e,
  n
), qu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return gu(a, u, n);
  }, ...e };
}, Mh = (t) => {
  const e = qu(t);
  return i(e);
}, fu = (t, e) => o.get(
  "/merchant_bank/card/cardholders",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hu = (t) => ["/merchant_bank/card/cardholders", ...t ? [t] : []], Ou = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hu(t), queryFn: ({ signal: u }) => fu(t, { signal: u, ...r }), ...n };
};
function xh(t, e) {
  const n = Ou(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ku = (t, e) => o.post(
  "/merchant_bank/card/activate",
  t,
  e
), bu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ku(a, n);
  }, ...e };
}, Fh = (t) => {
  const e = bu(t);
  return i(e);
}, Pu = (t, e) => o.get(
  "/merchant_bank/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ku = (t) => ["/merchant_bank/card/access_token", ...t ? [t] : []], Mu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ku(t), queryFn: ({ signal: u }) => Pu(t, { signal: u, ...r }), ...n };
};
function Rh(t, e) {
  const n = Mu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const xu = (t, e) => o.post(
  "/merchant_bank/card/apple_pay",
  t,
  e
), Fu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xu(a, n);
  }, ...e };
}, Bh = (t) => {
  const e = Fu(t);
  return i(e);
}, Ru = (t, e) => o.get(
  "/merchant_bank/card/cardholder_access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Bu = (t) => ["/merchant_bank/card/cardholder_access_token", ...t ? [t] : []], vu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Bu(t), queryFn: ({ signal: u }) => Ru(t, { signal: u, ...r }), ...n };
};
function vh(t, e) {
  const n = vu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Cu = (t, e) => o.post(
  "/merchant_bank/card/state_transition",
  t,
  e
), Eu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cu(a, n);
  }, ...e };
}, Ch = (t) => {
  const e = Eu(t);
  return i(e);
}, Uu = (t, e) => o.post(
  "/merchant_bank/card/google_pay",
  t,
  e
), Au = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Uu(a, n);
  }, ...e };
}, Eh = (t) => {
  const e = Au(t);
  return i(e);
}, Su = (t, e) => o.get(
  "/merchant_bank/card/disputes",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Nu = (t) => ["/merchant_bank/card/disputes", ...t ? [t] : []], Du = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Nu(t), queryFn: ({ signal: u }) => Su(t, { signal: u, ...r }), ...n };
};
function Uh(t, e) {
  const n = Du(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gu = (t, e) => o.post(
  "/merchant_bank/card/disputes",
  t,
  e
), Iu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gu(a, n);
  }, ...e };
}, Ah = (t) => {
  const e = Iu(t);
  return i(e);
}, Tu = (t, e) => o.post(
  "/merchant_bank/card/dispute/state_transition",
  t,
  e
), wu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Tu(a, n);
  }, ...e };
}, Sh = (t) => {
  const e = wu(t);
  return i(e);
}, Qu = (t, e) => o.post(
  "/merchant_bank/card/dispute/chargeback_credit",
  t,
  e
), Lu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qu(a, n);
  }, ...e };
}, Nh = (t) => {
  const e = Lu(t);
  return i(e);
}, Wu = (t, e) => o.get(
  "/merchant_bank/external_accounts/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $u = (t) => ["/merchant_bank/external_accounts/plaid_link_token", ...t ? [t] : []], zu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $u(t), queryFn: ({ signal: u }) => Wu(t, { signal: u, ...r }), ...n };
};
function Dh(t, e) {
  const n = zu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hu = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}/plaid_link_token`,
  e
), Vu = (t) => [`/merchant_bank/external_accounts/${t}/plaid_link_token`], Yu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vu(t), queryFn: ({ signal: u }) => Hu(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Gh(t, e) {
  const n = Yu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ju = (t, e) => o.get(
  "/merchant_bank/external_accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xu = (t) => ["/merchant_bank/external_accounts", ...t ? [t] : []], Ju = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xu(t), queryFn: ({ signal: u }) => ju(t, { signal: u, ...r }), ...n };
};
function Ih(t, e) {
  const n = Ju(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Zu = (t, e) => o.post(
  "/merchant_bank/external_accounts",
  t,
  e
), ti = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Zu(a, n);
  }, ...e };
}, Th = (t) => {
  const e = ti(t);
  return i(e);
}, ei = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}`,
  e
), ni = (t) => [`/merchant_bank/external_accounts/${t}`], ri = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ni(t), queryFn: ({ signal: u }) => ei(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function wh(t, e) {
  const n = ri(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const si = (t, e) => o.delete(
  `/merchant_bank/external_accounts/${t}`,
  e
), ai = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return si(a, n);
  }, ...e };
}, Qh = (t) => {
  const e = ai(t);
  return i(e);
}, oi = (t, e, n) => o.patch(
  `/merchant_bank/external_accounts/${t}`,
  e,
  n
), ui = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return oi(a, u, n);
  }, ...e };
}, Lh = (t) => {
  const e = ui(t);
  return i(e);
}, ii = (t, e) => o.post(
  "/merchant_bank/external_bank_transfers",
  t,
  e
), ci = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ii(a, n);
  }, ...e };
}, Wh = (t) => {
  const e = ci(t);
  return i(e);
}, yi = (t, e, n) => o.patch(
  `/merchant_bank/external_bank_transfers/${t}`,
  e,
  n
), di = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return yi(a, u, n);
  }, ...e };
}, $h = (t) => {
  const e = di(t);
  return i(e);
}, pi = (t, e) => o.get(
  "/debts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _i = (t) => ["/debts", ...t ? [t] : []], li = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _i(t), queryFn: ({ signal: u }) => pi(t, { signal: u, ...r }), ...n };
};
function zh(t, e) {
  const n = li(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const mi = (t, e) => o.post(
  "/debts",
  t,
  e
), gi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return mi(a, n);
  }, ...e };
}, Hh = (t) => {
  const e = gi(t);
  return i(e);
}, qi = (t, e) => o.post(
  "/debts/check_complete",
  t,
  e
), fi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return qi(a, n);
  }, ...e };
}, Vh = (t) => {
  const e = fi(t);
  return i(e);
}, hi = (t, e) => o.get(
  "/debt_checks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Oi = (t) => ["/debt_checks", ...t ? [t] : []], Ki = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Oi(t), queryFn: ({ signal: u }) => hi(t, { signal: u, ...r }), ...n };
};
function Yh(t, e) {
  const n = Ki(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const bi = (t, e) => o.post(
  "/card/close_account",
  t,
  e
), Pi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return bi(a, n);
  }, ...e };
}, jh = (t) => {
  const e = Pi(t);
  return i(e);
}, ki = (t, e) => o.get(
  "/financial_reviews",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Mi = (t) => ["/financial_reviews", ...t ? [t] : []], xi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mi(t), queryFn: ({ signal: u }) => ki(t, { signal: u, ...r }), ...n };
};
function Xh(t, e) {
  const n = xi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Fi = (t, e) => o.post(
  "/financial_reviews",
  t,
  e
), Ri = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Fi(a, n);
  }, ...e };
}, Jh = (t) => {
  const e = Ri(t);
  return i(e);
}, Bi = (t, e) => o.get(
  "/accounting_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vi = (t) => ["/accounting_info", ...t ? [t] : []], Ci = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vi(t), queryFn: ({ signal: u }) => Bi(t, { signal: u, ...r }), ...n };
};
function Zh(t, e) {
  const n = Ci(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ei = (t, e) => o.post(
  "/accounting_info",
  t,
  e
), Ui = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ei(a, n);
  }, ...e };
}, tO = (t) => {
  const e = Ui(t);
  return i(e);
}, Ai = (t, e) => o.post(
  "/tax_records",
  t,
  e
), Si = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ai(a, n);
  }, ...e };
}, eO = (t) => {
  const e = Si(t);
  return i(e);
}, Ni = (t, e) => o.get(
  "/officer_subsidiary_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Di = (t) => ["/officer_subsidiary_records", ...t ? [t] : []], Gi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Di(t), queryFn: ({ signal: u }) => Ni(t, { signal: u, ...r }), ...n };
};
function nO(t, e) {
  const n = Gi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ii = (t, e) => o.post(
  "/officer_subsidiary_records",
  t,
  e
), Ti = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ii(a, n);
  }, ...e };
}, rO = (t) => {
  const e = Ti(t);
  return i(e);
}, wi = (t) => o.get(
  "/analytics/cube_token",
  t
), Qi = () => ["/analytics/cube_token"], Li = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Qi(), queryFn: ({ signal: a }) => wi({ signal: a, ...n }), ...e };
};
function sO(t) {
  const e = Li(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Wi = (t, e) => o.get(
  "/logs/api_request",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $i = (t) => ["/logs/api_request", ...t ? [t] : []], zi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $i(t), queryFn: ({ signal: u }) => Wi(t, { signal: u, ...r }), ...n };
};
function aO(t, e) {
  const n = zi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hi = (t) => o.get(
  "/marketing/offer_csvs",
  t
), Vi = () => ["/marketing/offer_csvs"], Yi = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Vi(), queryFn: ({ signal: a }) => Hi({ signal: a, ...n }), ...e };
};
function oO(t) {
  const e = Yi(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const ji = (t) => o.post(
  "/marketing/offer_csvs/generate",
  void 0,
  t
), Xi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ji(n), ...e };
}, uO = (t) => {
  const e = Xi(t);
  return i(e);
}, Ji = (t, e) => o.get(
  "/businesses/notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zi = (t) => ["/businesses/notifications", ...t ? [t] : []], tc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zi(t), queryFn: ({ signal: u }) => Ji(t, { signal: u, ...r }), ...n };
};
function iO(t, e) {
  const n = tc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ec = (t, e) => o.post(
  "/businesses/notifications",
  t,
  e
), nc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ec(a, n);
  }, ...e };
}, cO = (t) => {
  const e = nc(t);
  return i(e);
}, rc = (t, e) => o.post(
  "/terms_of_service_mark_shown",
  t,
  e
), sc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return rc(a, n);
  }, ...e };
}, yO = (t) => {
  const e = sc(t);
  return i(e);
}, ac = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/applications`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), oc = (t, e) => [`/partner_dashboard/business_details_page/${t}/applications`, ...e ? [e] : []], uc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? oc(t, e), queryFn: ({ signal: y }) => ac(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function dO(t, e, n) {
  const r = uc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const ic = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/bank_info`,
  e
), cc = (t) => [`/partner_dashboard/business_details_page/${t}/bank_info`], yc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cc(t), queryFn: ({ signal: u }) => ic(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function pO(t, e) {
  const n = yc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const dc = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/business_info`,
  e
), pc = (t) => [`/partner_dashboard/business_details_page/${t}/business_info`], _c = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pc(t), queryFn: ({ signal: u }) => dc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function _O(t, e) {
  const n = _c(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const lc = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_products`,
  e
), mc = (t) => [`/partner_dashboard/business_details_page/${t}/funded_products`], gc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mc(t), queryFn: ({ signal: u }) => lc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function lO(t, e) {
  const n = gc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qc = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_product_details`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), fc = (t, e) => [`/partner_dashboard/business_details_page/${t}/funded_product_details`, ...e ? [e] : []], hc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? fc(t, e), queryFn: ({ signal: y }) => qc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function mO(t, e, n) {
  const r = hc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Oc = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/payment_progress`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Kc = (t, e) => [`/partner_dashboard/business_details_page/${t}/payment_progress`, ...e ? [e] : []], bc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Kc(t, e), queryFn: ({ signal: y }) => Oc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function gO(t, e, n) {
  const r = bc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Pc = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/offers`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), kc = (t, e) => [`/partner_dashboard/business_details_page/${t}/offers`, ...e ? [e] : []], Mc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? kc(t, e), queryFn: ({ signal: y }) => Pc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function qO(t, e, n) {
  const r = Mc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const xc = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/owner_info`,
  e
), Fc = (t) => [`/partner_dashboard/business_details_page/${t}/owner_info`], Rc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fc(t), queryFn: ({ signal: u }) => xc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function fO(t, e) {
  const n = Rc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Bc = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vc = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows/count", ...t ? [t] : []], Cc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vc(t), queryFn: ({ signal: u }) => Bc(t, { signal: u, ...r }), ...n };
};
function hO(t, e) {
  const n = Cc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ec = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Uc = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows", ...t ? [t] : []], Ac = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uc(t), queryFn: ({ signal: u }) => Ec(t, { signal: u, ...r }), ...n };
};
function OO(t, e) {
  const n = Ac(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Sc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Nc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows/count", ...t ? [t] : []], Dc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Nc(t), queryFn: ({ signal: u }) => Sc(t, { signal: u, ...r }), ...n };
};
function KO(t, e) {
  const n = Dc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ic = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows", ...t ? [t] : []], Tc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ic(t), queryFn: ({ signal: u }) => Gc(t, { signal: u, ...r }), ...n };
};
function bO(t, e) {
  const n = Tc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const wc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qc = (t) => ["/partner_dashboard/capital_tab/applications_table_rows/count", ...t ? [t] : []], Lc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qc(t), queryFn: ({ signal: u }) => wc(t, { signal: u, ...r }), ...n };
};
function PO(t, e) {
  const n = Lc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $c = (t) => ["/partner_dashboard/capital_tab/applications_table_rows", ...t ? [t] : []], zc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $c(t), queryFn: ({ signal: u }) => Wc(t, { signal: u, ...r }), ...n };
};
function kO(t, e) {
  const n = zc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vc = (t) => ["/partner_dashboard/capital_tab/offers_table_rows/count", ...t ? [t] : []], Yc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vc(t), queryFn: ({ signal: u }) => Hc(t, { signal: u, ...r }), ...n };
};
function MO(t, e) {
  const n = Yc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const jc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xc = (t) => ["/partner_dashboard/capital_tab/offers_table_rows", ...t ? [t] : []], Jc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xc(t), queryFn: ({ signal: u }) => jc(t, { signal: u, ...r }), ...n };
};
function xO(t, e) {
  const n = Jc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Zc = (t) => o.get(
  "/partner_dashboard/platform_tab/partner_support_info",
  t
), ty = () => ["/partner_dashboard/platform_tab/partner_support_info"], ey = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ty(), queryFn: ({ signal: a }) => Zc({ signal: a, ...n }), ...e };
};
function FO(t) {
  const e = ey(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const ny = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/offer_url`,
  e
), ry = (t) => [`/partner_dashboard/business_details_page/${t}/offer_url`], sy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ry(t), queryFn: ({ signal: u }) => ny(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function RO(t, e) {
  const n = sy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ay = (t) => o.get(
  "/partner_dashboard/partner_event/subscriptions/list_user_subscriptions",
  t
), oy = () => ["/partner_dashboard/partner_event/subscriptions/list_user_subscriptions"], uy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? oy(), queryFn: ({ signal: a }) => ay({ signal: a, ...n }), ...e };
};
function BO(t) {
  const e = uy(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const iy = (t, e) => o.get(
  "/partner_dashboard/partner_event/web_notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), cy = (t) => ["/partner_dashboard/partner_event/web_notifications", ...t ? [t] : []], yy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cy(t), queryFn: ({ signal: u }) => iy(t, { signal: u, ...r }), ...n };
};
function vO(t, e) {
  const n = yy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const dy = (t, e) => o.post(
  "/partner_dashboard/partner_event/web_notifications/mark_all_read",
  t,
  e
), py = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return dy(a, n);
  }, ...e };
}, CO = (t) => {
  const e = py(t);
  return i(e);
}, _y = (t, e) => o.post(
  "/partner_dashboard/partner_event/web_notifications/mark_one_read",
  t,
  e
), ly = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return _y(a, n);
  }, ...e };
}, EO = (t) => {
  const e = ly(t);
  return i(e);
}, my = (t, e) => o.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions",
  t,
  e
), gy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return my(a, n);
  }, ...e };
}, UO = (t) => {
  const e = gy(t);
  return i(e);
}, qy = (t, e) => o.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions_for_event_type",
  t,
  e
), fy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return qy(a, n);
  }, ...e };
}, AO = (t) => {
  const e = fy(t);
  return i(e);
}, hy = (t) => o.get(
  "/partner_dashboard/crm/integration",
  t
), Oy = () => ["/partner_dashboard/crm/integration"], Ky = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Oy(), queryFn: ({ signal: a }) => hy({ signal: a, ...n }), ...e };
};
function SO(t) {
  const e = Ky(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const by = (t) => o.get(
  "/partner_dashboard/valid_object_id_prefixes",
  t
), Py = () => ["/partner_dashboard/valid_object_id_prefixes"], ky = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Py(), queryFn: ({ signal: a }) => by({ signal: a, ...n }), ...e };
};
function NO(t) {
  const e = ky(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const My = (t, e) => o.get(
  "/partner_dashboard/webhook_logs/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xy = (t) => ["/partner_dashboard/webhook_logs/events", ...t ? [t] : []], Fy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xy(t), queryFn: ({ signal: u }) => My(t, { signal: u, ...r }), ...n };
};
function DO(t, e) {
  const n = Fy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ry = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}`,
  e
), By = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}`], vy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? By(t), queryFn: ({ signal: u }) => Ry(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function GO(t, e) {
  const n = vy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Cy = (t, e, n) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ey = (t, e) => [`/partner_dashboard/webhook_logs/event_info/${t}/attempts`, ...e ? [e] : []], Uy = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ey(t, e), queryFn: ({ signal: y }) => Cy(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function IO(t, e, n) {
  const r = Uy(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Ay = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`,
  e
), Sy = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`], Ny = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sy(t), queryFn: ({ signal: u }) => Ay(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function TO(t, e) {
  const n = Ny(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Dy = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend",
  t,
  e
), Gy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Dy(a, n);
  }, ...e };
}, wO = (t) => {
  const e = Gy(t);
  return i(e);
}, Iy = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend_event",
  t,
  e
), Ty = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Iy(a, n);
  }, ...e };
}, QO = (t) => {
  const e = Ty(t);
  return i(e);
}, wy = (t, e) => o.get(
  "/partner_dashboard/crm/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qy = (t) => ["/partner_dashboard/crm/events", ...t ? [t] : []], Ly = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qy(t), queryFn: ({ signal: u }) => wy(t, { signal: u, ...r }), ...n };
};
function LO(t, e) {
  const n = Ly(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wy = (t, e, n) => o.get(
  `/partner_dashboard/crm/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), $y = (t, e) => [`/partner_dashboard/crm/event_info/${t}/attempts`, ...e ? [e] : []], zy = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? $y(t, e), queryFn: ({ signal: y }) => Wy(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function WO(t, e, n) {
  const r = zy(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Hy = (t, e) => o.post(
  "/partner_dashboard/crm/resend",
  t,
  e
), Vy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hy(a, n);
  }, ...e };
}, $O = (t) => {
  const e = Vy(t);
  return i(e);
}, Yy = (t, e) => o.get(
  `/partner_dashboard/crm/event_info/${t}`,
  e
), jy = (t) => [`/partner_dashboard/crm/event_info/${t}`], Xy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? jy(t), queryFn: ({ signal: u }) => Yy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function zO(t, e) {
  const n = Xy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Jy = (t) => o.post(
  "/partner_dashboard/crm/trigger_sync",
  void 0,
  t
), Zy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Jy(n), ...e };
}, HO = (t) => {
  const e = Zy(t);
  return i(e);
}, td = (t) => o.get(
  "/partner_dashboard/crm/sync",
  t
), ed = () => ["/partner_dashboard/crm/sync"], nd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ed(), queryFn: ({ signal: a }) => td({ signal: a, ...n }), ...e };
};
function VO(t) {
  const e = nd(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const rd = (t) => o.post(
  "/partner_dashboard/crm/sync",
  void 0,
  t
), sd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => rd(n), ...e };
}, YO = (t) => {
  const e = sd(t);
  return i(e);
}, ad = (t, e) => o.get(
  "/partner_dashboard/webhook_endpoints",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), od = (t) => ["/partner_dashboard/webhook_endpoints", ...t ? [t] : []], ud = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? od(t), queryFn: ({ signal: u }) => ad(t, { signal: u, ...r }), ...n };
};
function jO(t, e) {
  const n = ud(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const id = (t, e) => o.post(
  "/partner_dashboard/webhook_endpoints",
  t,
  e
), cd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return id(a, n);
  }, ...e };
}, XO = (t) => {
  const e = cd(t);
  return i(e);
}, yd = (t, e) => o.delete(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e
), dd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return yd(a, n);
  }, ...e };
}, JO = (t) => {
  const e = dd(t);
  return i(e);
}, pd = (t, e, n) => o.patch(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e,
  n
), _d = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return pd(a, u, n);
  }, ...e };
}, ZO = (t) => {
  const e = _d(t);
  return i(e);
}, ld = (t, e) => o.get(
  `/partner_dashboard/capital_tab/csv_export/${t}`,
  e
), md = (t) => [`/partner_dashboard/capital_tab/csv_export/${t}`], gd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? md(t), queryFn: ({ signal: u }) => ld(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function tK(t, e) {
  const n = gd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qd = (t, e) => o.get(
  "/partner_dashboard/capital_tab/csv_export",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fd = (t) => ["/partner_dashboard/capital_tab/csv_export", ...t ? [t] : []], hd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fd(t), queryFn: ({ signal: u }) => qd(t, { signal: u, ...r }), ...n };
};
function eK(t, e) {
  const n = hd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Od = (t, e) => o.post(
  "/partner_dashboard/capital_tab/csv_export",
  t,
  e
), Kd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Od(a, n);
  }, ...e };
}, nK = (t) => {
  const e = Kd(t);
  return i(e);
}, bd = (t, e) => o.get(
  "/rutter_connections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pd = (t) => ["/rutter_connections", ...t ? [t] : []], kd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pd(t), queryFn: ({ signal: u }) => bd(t, { signal: u, ...r }), ...n };
};
function rK(t, e) {
  const n = kd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Md = (t, e) => o.post(
  "/rutter_connections",
  t,
  e
), xd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Md(a, n);
  }, ...e };
}, sK = (t) => {
  const e = xd(t);
  return i(e);
}, Fd = (t, e) => o.post(
  "/mark_accounting_integration_seen",
  t,
  e
), Rd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Fd(a, n);
  }, ...e };
}, aK = (t) => {
  const e = Rd(t);
  return i(e);
}, Bd = (t) => o.get(
  "/sardine/session_id",
  t
), vd = () => ["/sardine/session_id"], Cd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? vd(), queryFn: ({ signal: a }) => Bd({ signal: a, ...n }), ...e };
};
function oK(t) {
  const e = Cd(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ed = (t, e) => o.post(
  "/sardine/session_id",
  t,
  e
), Ud = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ed(a, n);
  }, ...e };
}, uK = (t) => {
  const e = Ud(t);
  return i(e);
}, Ad = (t) => o.get(
  "/partner/widget_configs",
  t
), Sd = () => ["/partner/widget_configs"], Nd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Sd(), queryFn: ({ signal: a }) => Ad({ signal: a, ...n }), ...e };
};
function iK(t) {
  const e = Nd(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Dd = (t, e) => o.patch(
  "/partner/widget_configs",
  t,
  e
), Gd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Dd(a, n);
  }, ...e };
}, cK = (t) => {
  const e = Gd(t);
  return i(e);
}, Id = (t, e) => o.get(
  `/merchant_bank/applications/${t}`,
  e
), Td = (t) => [`/merchant_bank/applications/${t}`], wd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Td(t), queryFn: ({ signal: u }) => Id(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function yK(t, e) {
  const n = wd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qd = (t, e) => o.get(
  "/merchant_bank/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ld = (t) => ["/merchant_bank/applications", ...t ? [t] : []], Wd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ld(t), queryFn: ({ signal: u }) => Qd(t, { signal: u, ...r }), ...n };
};
function dK(t, e) {
  const n = Wd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const $d = (t, e) => o.post(
  "/merchant_bank/applications",
  t,
  e
), zd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $d(a, n);
  }, ...e };
}, pK = (t) => {
  const e = zd(t);
  return i(e);
}, Hd = (t, e) => o.post(
  "/webhooks/plaid/general",
  t,
  e
), Vd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hd(a, n);
  }, ...e };
}, _K = (t) => {
  const e = Vd(t);
  return i(e);
}, Yd = (t, e) => o.get(
  "/amazon/funding/account_lock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), jd = (t) => ["/amazon/funding/account_lock", ...t ? [t] : []], Xd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? jd(t), queryFn: ({ signal: u }) => Yd(t, { signal: u, ...r }), ...n };
};
function lK(t, e) {
  const n = Xd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Jd = (t) => o.get(
  "/naics",
  t
), Zd = () => ["/naics"], tp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Zd(), queryFn: ({ signal: a }) => Jd({ signal: a, ...n }), ...e };
};
function mK(t) {
  const e = tp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const ep = (t, e, n, r) => o.post(
  `/mfa/otp/send/${t}/${e}`,
  n,
  r
), np = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return ep(a, u, y, n);
  }, ...e };
}, gK = (t) => {
  const e = np(t);
  return i(e);
}, rp = (t, e, n, r) => o.post(
  `/mfa/otp/verify/${t}/${e}`,
  n,
  r
), sp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return rp(a, u, y, n);
  }, ...e };
}, qK = (t) => {
  const e = sp(t);
  return i(e);
}, ap = (t, e) => o.get(
  "/funding/pad_agreement",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), op = (t) => ["/funding/pad_agreement", ...t ? [t] : []], up = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? op(t), queryFn: ({ signal: u }) => ap(t, { signal: u, ...r }), ...n };
};
function fK(t, e) {
  const n = up(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ip = (t, e) => o.post(
  "/funding/mark_pad_agreement_signed",
  t,
  e
), cp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ip(a, n);
  }, ...e };
}, hK = (t) => {
  const e = cp(t);
  return i(e);
}, yp = (t, e) => o.get(
  "/templates",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dp = (t) => ["/templates", ...t ? [t] : []], pp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dp(t), queryFn: ({ signal: u }) => yp(t, { signal: u, ...r }), ...n };
};
function OK(t, e) {
  const n = pp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const _p = (t, e) => o.post(
  "/templates",
  t,
  e
), lp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return _p(a, n);
  }, ...e };
}, KK = (t) => {
  const e = lp(t);
  return i(e);
}, mp = (t, e) => o.patch(
  "/templates",
  t,
  e
), gp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return mp(a, n);
  }, ...e };
}, bK = (t) => {
  const e = gp(t);
  return i(e);
}, qp = (t, e) => o.get(
  "/template_mock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fp = (t) => ["/template_mock", ...t ? [t] : []], hp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fp(t), queryFn: ({ signal: u }) => qp(t, { signal: u, ...r }), ...n };
};
function PK(t, e) {
  const n = hp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Op = (t, e) => o.get(
  "/template_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Kp = (t) => ["/template_snapshots", ...t ? [t] : []], bp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kp(t), queryFn: ({ signal: u }) => Op(t, { signal: u, ...r }), ...n };
};
function kK(t, e) {
  const n = bp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pp = (t, e) => o.get(
  "/template_snapshot_urls",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), kp = (t) => ["/template_snapshot_urls", ...t ? [t] : []], Mp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? kp(t), queryFn: ({ signal: u }) => Pp(t, { signal: u, ...r }), ...n };
};
function MK(t, e) {
  const n = Mp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const xp = (t) => o.get(
  "/template_types",
  t
), Fp = () => ["/template_types"], Rp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Fp(), queryFn: ({ signal: a }) => xp({ signal: a, ...n }), ...e };
};
function xK(t) {
  const e = Rp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Bp = (t, e) => o.post(
  "/template_types",
  t,
  e
), vp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Bp(a, n);
  }, ...e };
}, FK = (t) => {
  const e = vp(t);
  return i(e);
}, Cp = (t, e) => o.post(
  "/manual_repayment",
  t,
  e
), Ep = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cp(a, n);
  }, ...e };
}, RK = (t) => {
  const e = Ep(t);
  return i(e);
}, Up = (t, e) => o.post(
  "/forgive_capital_fee",
  t,
  e
), Ap = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Up(a, n);
  }, ...e };
}, BK = (t) => {
  const e = Ap(t);
  return i(e);
}, Sp = (t, e) => o.post(
  "/mfa/verify_account_details",
  t,
  e
), Np = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Sp(a, n);
  }, ...e };
}, vK = (t) => {
  const e = Np(t);
  return i(e);
}, Dp = (t) => o.patch(
  "/mfa/extend_session",
  void 0,
  t
), Gp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Dp(n), ...e };
}, CK = (t) => {
  const e = Gp(t);
  return i(e);
}, Ip = (t, e) => o.get(
  "/merchant_bank/offers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Tp = (t) => ["/merchant_bank/offers", ...t ? [t] : []], wp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Tp(t), queryFn: ({ signal: u }) => Ip(t, { signal: u, ...r }), ...n };
};
function EK(t, e) {
  const n = wp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qp = (t, e) => o.post(
  "/merchant_bank/offers",
  t,
  e
), Lp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qp(a, n);
  }, ...e };
}, UK = (t) => {
  const e = Lp(t);
  return i(e);
}, Wp = (t, e) => o.get(
  "/internal_api/sardine/score_details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $p = (t) => ["/internal_api/sardine/score_details", ...t ? [t] : []], zp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $p(t), queryFn: ({ signal: u }) => Wp(t, { signal: u, ...r }), ...n };
};
function AK(t, e) {
  const n = zp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hp = (t, e) => o.patch(
  "/merchant_bank/merchant_config",
  t,
  e
), Vp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hp(a, n);
  }, ...e };
}, SK = (t) => {
  const e = Vp(t);
  return i(e);
}, Yp = (t, e) => o.post(
  "/auth/login/validate_otp",
  t,
  e
), jp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yp(a, n);
  }, ...e };
}, NK = (t) => {
  const e = jp(t);
  return i(e);
}, Xp = (t, e) => o.post(
  "/auth/login/start",
  t,
  e
), Jp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Xp(a, n);
  }, ...e };
}, DK = (t) => {
  const e = Jp(t);
  return i(e);
}, Zp = (t, e) => o.post(
  "/auth/token/exchange",
  t,
  e
), t_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Zp(a, n);
  }, ...e };
}, GK = (t) => {
  const e = t_(t);
  return i(e);
}, e_ = (t, e) => o.get(
  `/auth/management/${t}/saml`,
  e
), n_ = (t) => [`/auth/management/${t}/saml`], r_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? n_(t), queryFn: ({ signal: u }) => e_(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function IK(t, e) {
  const n = r_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const s_ = (t, e, n) => o.post(
  `/auth/management/${t}/saml`,
  e,
  n
), a_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: a, data: u } = s ?? {};
    return s_(a, u, n);
  }, ...e };
}, TK = (t) => {
  const e = a_(t);
  return i(e);
}, o_ = (t, e, n) => o.delete(
  `/auth/management/${t}/saml/${e}`,
  n
), u_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: a, samlConfigId: u } = s ?? {};
    return o_(a, u, n);
  }, ...e };
}, wK = (t) => {
  const e = u_(t);
  return i(e);
}, i_ = (t, e, n, r) => o.patch(
  `/auth/management/${t}/saml/${e}`,
  n,
  r
), c_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: a, samlConfigId: u, data: y } = s ?? {};
    return i_(a, u, y, n);
  }, ...e };
}, QK = (t) => {
  const e = c_(t);
  return i(e);
}, y_ = (t, e) => o.get(
  `/introductory_offer/underwrite/${t}`,
  e
), d_ = (t) => [`/introductory_offer/underwrite/${t}`], p_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? d_(t), queryFn: ({ signal: u }) => y_(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function LK(t, e) {
  const n = p_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const __ = (t, e) => o.post(
  "/introductory_offer/underwrite",
  t,
  e
), l_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return __(a, n);
  }, ...e };
}, WK = (t) => {
  const e = l_(t);
  return i(e);
}, m_ = (t, e) => o.get(
  "/card/balances",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), g_ = (t) => ["/card/balances", ...t ? [t] : []], q_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? g_(t), queryFn: ({ signal: u }) => m_(t, { signal: u, ...r }), ...n };
};
function $K(t, e) {
  const n = q_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const f_ = (t) => o.get(
  "/cardholders",
  t
), h_ = () => ["/cardholders"], O_ = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? h_(), queryFn: ({ signal: a }) => f_({ signal: a, ...n }), ...e };
};
function zK(t) {
  const e = O_(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const K_ = (t, e, n) => o.patch(
  `/cardholders/${t}`,
  e,
  n
), b_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { cardholderId: a, data: u } = s ?? {};
    return K_(a, u, n);
  }, ...e };
}, HK = (t) => {
  const e = b_(t);
  return i(e);
}, P_ = (t, e) => o.get(
  "/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), k_ = (t) => ["/card/access_token", ...t ? [t] : []], M_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? k_(t), queryFn: ({ signal: u }) => P_(t, { signal: u, ...r }), ...n };
};
function VK(t, e) {
  const n = M_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const x_ = (t, e) => o.get(
  "/card/lifecycle_states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), F_ = (t) => ["/card/lifecycle_states", ...t ? [t] : []], R_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? F_(t), queryFn: ({ signal: u }) => x_(t, { signal: u, ...r }), ...n };
};
function YK(t, e) {
  const n = R_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const B_ = (t, e) => o.get(
  "/card/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), v_ = (t) => ["/card/transactions", ...t ? [t] : []], C_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? v_(t), queryFn: ({ signal: u }) => B_(t, { signal: u, ...r }), ...n };
};
function jK(t, e) {
  const n = C_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const E_ = (t, e) => o.get(
  "/card/pending_transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), U_ = (t) => ["/card/pending_transactions", ...t ? [t] : []], A_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? U_(t), queryFn: ({ signal: u }) => E_(t, { signal: u, ...r }), ...n };
};
function XK(t, e) {
  const n = A_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const JK = (t, e) => c({
  queryKey: ["plaid-link-token", t],
  queryFn: () => o.get("/funding/plaid_link_token", { ...e == null ? void 0 : e.axios, params: t })
}), ZK = (t, e) => o.get("/statements", {
  ...e == null ? void 0 : e.axios,
  params: t
}), tb = (t, e) => c({
  queryKey: ["card-repayments"],
  queryFn: async () => {
    const n = await o.get("/repayments", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.filter((r) => r.state === "complete" || r.state === "pending").map((r) => ({
          ...r,
          timestamp: r.date,
          activity_type: "repayment",
          state: r.is_settled ? r.state : "pending"
        }))
      }
    };
  }
}), eb = (t, e) => c({
  queryKey: ["show-top-up-offer", t.business_id],
  queryFn: () => o.get("/businesses/show_top_up_offer", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), nb = (t, e) => c({
  queryKey: ["whitelisted-for-early-payment", t.cash_advance_id],
  queryFn: () => o.get("/early_manual_repayment_whitelist", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), rb = (t, e) => c({
  queryKey: ["opt-out-eligibility", t.business_external_id],
  queryFn: () => o.get("/opt_out_eligibility", { ...e == null ? void 0 : e.axios, params: t })
}), sb = (t, e) => c({
  queryKey: ["opt-ins", t.business_id],
  queryFn: () => o.get("/opt_ins", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), ab = (t) => i({
  mutationFn: (e) => o.post("/beneficial_owners", e, t == null ? void 0 : t.axios)
}), ob = (t) => i({
  mutationFn: ({
    id: e,
    ...n
  }) => o.patch(`/beneficial_owners/${e}`, n, t == null ? void 0 : t.axios)
}), ub = (t) => i({
  mutationFn: (e) => o.delete(`/beneficial_owners/${e}`, t == null ? void 0 : t.axios)
}), ib = (t) => i({
  mutationFn: (e) => o.post(`/business/${e.business_id}/kyc`, e, t == null ? void 0 : t.axios)
}), cb = (t) => i({
  mutationFn: (e) => o.post("/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), yb = (t) => i({
  mutationFn: (e) => o.post("/amazon/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), db = (t) => i({
  mutationFn: (e) => o.post("/funding/bank_statement_upload", e, t == null ? void 0 : t.axios)
}), pb = (t) => i({
  mutationFn: (e) => o.post("/underwriting_attempts", { context: e }, t == null ? void 0 : t.axios)
}), _b = (t) => i({
  mutationFn: (e) => o.post(
    "/amazonlending/create_or_update_amazon_resources_pre_login",
    e,
    t == null ? void 0 : t.axios
  )
}), lb = (t) => i({
  mutationFn: (e) => o.patch("/boost_complete", e, t == null ? void 0 : t.axios)
}), mb = (t) => i({
  mutationFn: (e) => o.post("/card/disputes", e, t == null ? void 0 : t.axios)
}), gb = (t) => i({
  mutationFn: (e) => o.post(
    "/funding/create_unverified_bank_accounts_from_plaid_linkage",
    e,
    t == null ? void 0 : t.axios
  )
}), qb = (t) => i({
  mutationFn: (e) => o.post("/amazon/funding/verify_bank_account", e, t == null ? void 0 : t.axios)
}), fb = (t) => i({
  mutationFn: (e) => o.post("/funding/plaid_linkages", e, t == null ? void 0 : t.axios)
}), hb = (t) => i({
  mutationFn: (e) => o.post("/tickets", e, t == null ? void 0 : t.axios)
}), Ob = (t) => i({
  mutationFn: (e) => o.post("/opt_out", e, t == null ? void 0 : t.axios)
}), Kb = (t) => i({
  mutationFn: (e) => o.post("/opt_ins", e, t == null ? void 0 : t.axios)
}), bb = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/capital_product_applications",
    method: "get",
    name: "List Capital Product Applications"
  },
  {
    path: "/v1/capital_product_applications/{id}",
    method: "get",
    name: "Get Capital Product Application"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/approve",
    method: "post",
    name: "Approve Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/deny",
    method: "post",
    name: "Deny Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/capital_product_offers/{id}",
    method: "get",
    name: "Get Capital Product Offer"
  },
  {
    path: "/v1/capital_product_offers",
    method: "get",
    name: "List Capital Product Offers"
  },
  {
    path: "/v1/capital_product_payments",
    method: "get",
    name: "List Capital Product Payments"
  },
  {
    path: "/v1/capital_products/{id}/loan_minimum_repayment",
    method: "get",
    name: "Get Loan Minimum Repayment Details For A Capital Product"
  },
  {
    path: "/v1/capital_products/{id}",
    method: "get",
    name: "Get Capital Product"
  },
  {
    path: "/v1/capital_products",
    method: "get",
    name: "List Capital Products"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/bulk_ingest",
    method: "post",
    name: "Bulk Ingest Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}",
    method: "get",
    name: "Get Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests",
    method: "get",
    name: "List Net Settlement Payment Requests"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}/fulfill",
    method: "patch",
    name: "Fulfill Net Settlement Payment Request"
  },
  {
    path: "/v1/sandbox/net_settlement_payment_requests",
    method: "post",
    name: "Create Net Settlement Payment Request"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/person_business_relationships",
    method: "get",
    name: "List Person Business Relationships"
  },
  {
    path: "/v1/person_business_relationships",
    method: "post",
    name: "Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/batch_create",
    method: "post",
    name: "Batch Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/{id}",
    method: "get",
    name: "Get Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/change_representative",
    method: "post",
    name: "Change Representative"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  },
  {
    path: "/v1/sandbox/fund_capital_product",
    method: "post",
    name: "Fund Capital Product"
  },
  {
    path: "/v1/sandbox/capital_product_offer",
    method: "post",
    name: "[deprecated] Generate Capital Product Offer"
  },
  {
    path: "/v1/sandbox/wallets/balance",
    method: "post",
    name: "Add Business Wallet Balance"
  },
  {
    path: "/v1/sandbox/capital_product_payments",
    method: "post",
    name: "Create Capital Product Payment (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers",
    method: "post",
    name: "Create Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers/{id}/close",
    method: "post",
    name: "Close Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/created",
    method: "post",
    name: "[Experimental] Capital Product Offer Created (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/closed",
    method: "post",
    name: "[Experimental] Capital Product Offer Closed (Sandbox)"
  },
  {
    path: "/v1/wallet_applications/{id}",
    method: "get",
    name: "Get Wallet Application"
  },
  {
    path: "/v1/wallet_applications",
    method: "get",
    name: "List Wallet Applications"
  },
  {
    path: "/v1/wallet_accounts/{id}",
    method: "get",
    name: "Get Wallet Account"
  },
  {
    path: "/v1/wallet_accounts",
    method: "get",
    name: "List Wallet Accounts"
  },
  {
    path: "/v1/wallet_balances/{id}",
    method: "get",
    name: "Get Wallet Balance"
  },
  {
    path: "/v1/wallet_balances",
    method: "get",
    name: "List Wallet Balances"
  },
  {
    path: "/v1/wallet_transactions",
    method: "get",
    name: "List Wallet Transactions"
  },
  {
    path: "/v1/sandbox/wallet_transactions",
    method: "post",
    name: "Create Wallet Transaction"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "get",
    name: "Get Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "patch",
    name: "Update Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "get",
    name: "List Webhook Endpoints"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "post",
    name: "Create Webhook Endpoint"
  }
];
export {
  yg as AccountOwner,
  cg as AddressType,
  ig as AdverseActionReason,
  ug as BankAccountInfoDataShare,
  og as BusinessProductType,
  ag as CapitalProductActivityType,
  sg as CapitalProductApplicationStatus,
  rg as CapitalProductApplicationStatus1,
  ng as CapitalProductApplicationTransitionReason,
  eg as CapitalProductApplicationType,
  tg as CapitalProductHealthStatus,
  Zm as CapitalProductInternalRepaymentPlanType,
  Jm as CapitalProductOfferLabel,
  Xm as CapitalProductState,
  jm as CapitalProductSuperApplicationActionType,
  Ym as CapitalProductType,
  Vm as CardBusinessTableRowStatus,
  Hm as CardDeserializerCardType,
  zm as CardLifecycleStateSerializerLifecycleState,
  Qm as CardSerializer1FulfillmentStatus,
  wm as CardSerializer1State,
  $m as CardSerializerCardType,
  Wm as CardSerializerFulfillmentStatus,
  Lm as CardSerializerState,
  Tm as CardShippingMethod,
  Im as CardTransactionType,
  Gm as CardTransitionChannel,
  Dm as CardTransitionReasonCode,
  Nm as CardTransitionState,
  Sm as CardTransitionType,
  Am as CashAdvanceChargeOffSerializerChargeOffType,
  Um as ChargeOffInputSerializerChargeOffType,
  Em as CountryCode,
  Cm as CrmDeliveryAttemptState,
  vm as CrmEventType,
  Bm as CrmVendor,
  Rm as Currency,
  Fm as DataRequirement,
  xm as DatasetType,
  Mm as DatasetUploadStatus,
  km as DatashareMethod,
  Pm as DeniedReason,
  bm as Direction,
  Km as DiscountProgramFundingType,
  Om as DiscountProgramType,
  hm as DisputeState,
  fm as DocumentUploadPurpose,
  qm as DoordashAdminUserMxRole,
  gm as EmailSubscriptionState,
  mm as EntityType,
  lm as FieldType,
  _m as FinancialRecordDocType,
  pm as FutureActivityType,
  dm as IdentityDataShare,
  ym as IncorporationJurisdiction,
  cm as IncorporationType,
  im as InfoNeededStatus,
  um as IngestionStatus,
  om as InnerTransferType,
  am as LienReviewState,
  sm as LienStatus,
  rm as LienType,
  nm as LoanBusinessTableRowStatus,
  em as ManualReviewReason,
  tm as McaBusinessTableRowStatus,
  Zl as MerchantBankAccountType,
  Jl as MerchantBankApplicationState,
  Xl as MerchantBankCardDisputeReason,
  jl as MerchantBankCardDisputeState,
  Nl as MerchantBankCardPaymentPreference,
  Yl as MerchantBankCardReplacementReason,
  Vl as MerchantBankCardType,
  Hl as MerchantBankExternalAccountState,
  zl as MerchantBankExternalBankAccountType,
  $l as MerchantBankOfferProductType,
  Wl as MerchantBankReceivedTransferType,
  Ll as MerchantBankRecipientPaymentType,
  Ql as MerchantBankRepaymentType,
  wl as MerchantBankTransactionDestinationAccountType,
  Tl as MerchantBankTransactionPaymentDirection,
  Il as MerchantBankTransactionState,
  Gl as MerchantBankTransactionType,
  Dl as MerchantBankTransferPaymentType,
  Sl as MicroDepositVerificationState,
  Al as MinimumRepaymentAmountDueReason,
  Ul as NoCodePilotStatus,
  El as NotificationType,
  Cl as OnboardingAction,
  vl as OnboardingProductType,
  Bl as OnboardingState,
  Rl as OneOffActionType,
  Fl as OptInState,
  xl as ParafinProduct,
  Ml as PartnerEventType,
  kl as PersonaInquiryType,
  Pl as PhoneNumberCountryCode,
  bl as PhoneNumberType,
  Kl as PlaidInvestigationDeniedReason,
  Ol as PlaidLinkageWithInvestigationSerializerState,
  hl as RepaymentDetailsRepaymentPlanType,
  fl as RepaymentDisbursementType,
  ql as RepaymentPauseSerializerPauseType,
  gl as RepaymentSchedule,
  ml as ReplacementDetailsReplacementReason,
  ll as RestrictionExternalReason,
  _l as RestrictionReason,
  pl as RestrictionSource,
  dl as RestrictionType,
  yl as ReturnCode,
  cl as ReversalReason,
  il as RutterConnectionState,
  ul as RutterIntegrationType,
  ol as RutterPlatform,
  al as S3PartnerDashboardCsvStatus,
  sl as S3PartnerDashboardCsvTableType,
  rl as SalesDataShare,
  nl as SardineCustomerScoreState,
  el as SardineFlowName,
  tl as SardineLevel,
  Z_ as SessionType,
  J_ as State,
  X_ as StripeSaleRecordIngestionType,
  j_ as TermsOfServiceLinkType,
  Y_ as UnderwriteIntroductoryOfferAttemptFailureReason,
  V_ as UnderwriteIntroductoryOfferAttemptStatus,
  H_ as UnderwritingDesiredOutputState,
  z_ as UserRole,
  $_ as ValidationStatus,
  W_ as VerificationStatus,
  L_ as WebNotificationState,
  Q_ as WebSubscriptionState,
  w_ as WebhookDeliveryAttemptState,
  T_ as WebhookEndpointStatus,
  I_ as WebhookEventDeliveryState,
  G_ as WebhookEventMode,
  D_ as WebhookEventType,
  Jy as attemptSync,
  dy as batchCreatePdPartnerEventMarkAllNotificationsRead,
  Iy as batchResendWebhookAttempt,
  Wr as benchmarkGetCapitalProductOwnership,
  Bc as countPdBusinessTableRows,
  wc as countPdCapitalProductApplicationRow,
  Hc as countPdCapitalProductOfferRow,
  Sc as countPdCapitalProductRow,
  __ as createAttemptToUnderwriteIntroductoryOffers,
  Rs as createBankAccountInfo,
  es as createCapitalProductIntents,
  ys as createCapitalProductSuperApplicationActions,
  is as createCapitalProductSuperApplications,
  gr as createCapitalProducts,
  jr as createOfferedCapitalProductFeeDiscount,
  it as createOrganization,
  rt as createPartnerApiKey,
  _y as createPdPartnerEventMarkNotificationRead,
  id as createPdWebhookEndpoint,
  Ts as createPlaidInvestigation,
  ji as createS3OfferCsv,
  Od as createS3PartnerDashboardTableCsv,
  o_ as deleteAuthManagementUseridSamlSamlconfigid,
  Te as deleteBeneficialOwnersId,
  gn as deleteBusinessOfficersId,
  A as deleteCashAdvancePausesP1,
  Fe as deleteMarketingIntegrationsVendor,
  si as deleteMerchantBankExternalAccountsId,
  eu as deleteMerchantBankRecipientsId,
  Bt as deletePartnerDeleteUsers,
  Wt as deletePartnerSyncWebhooksId,
  yd as deletePdWebhookEndpoint,
  Ot as deleteSlackChannelsId,
  Bi as getAccountingInfo,
  Yd as getAmazonFundingAccountLock,
  wi as getAnalyticsCubeToken,
  Zy as getAttemptSyncMutationOptions,
  e_ as getAuthManagementP1Saml,
  Ms as getBankAccountInfos,
  Ye as getBanks,
  py as getBatchCreatePdPartnerEventMarkAllNotificationsReadMutationOptions,
  Ty as getBatchResendWebhookAttemptMutationOptions,
  $r as getBenchmarkGetCapitalProductOwnershipQueryKey,
  zr as getBenchmarkGetCapitalProductOwnershipQueryOptions,
  Se as getBeneficialOwners,
  g as getBusinessAnnotations,
  d as getBusinessCores,
  js as getBusinessIdentities,
  dn as getBusinessOfficers,
  un as getBusinessTableRowsCard,
  sn as getBusinessTableRowsLoan,
  en as getBusinessTableRowsMca,
  Je as getBusinessTableRowsProductAgnostic,
  ro as getBusinessesDailySalesRecords,
  Ji as getBusinessesNotifications,
  Ba as getBusinessesProducts,
  Mr as getCapitalProductActivities,
  Ur as getCapitalProductApplicationSnapshots,
  Rr as getCapitalProductApplications,
  Jr as getCapitalProductIntents,
  Gr as getCapitalProductNetTopUpItemization,
  Or as getCapitalProductOfferCollection,
  yr as getCapitalProductProspectiveTermsOfService,
  Hr as getCapitalProductRepaymentSchedule,
  wr as getCapitalProductStateDisclosure,
  _r as getCapitalProducts,
  P_ as getCardAccessToken,
  m_ as getCardBalances,
  x_ as getCardLifecycleStates,
  E_ as getCardPendingTransactions,
  B_ as getCardTransactions,
  f_ as getCardholders,
  ka as getCards,
  K as getCashAdvanceChargeOffs,
  B as getCashAdvancePauses,
  vc as getCountPdBusinessTableRowsQueryKey,
  Cc as getCountPdBusinessTableRowsQueryOptions,
  Qc as getCountPdCapitalProductApplicationRowQueryKey,
  Lc as getCountPdCapitalProductApplicationRowQueryOptions,
  Vc as getCountPdCapitalProductOfferRowQueryKey,
  Yc as getCountPdCapitalProductOfferRowQueryOptions,
  Nc as getCountPdCapitalProductRowQueryKey,
  Dc as getCountPdCapitalProductRowQueryOptions,
  as as getCounteroffer,
  l_ as getCreateAttemptToUnderwriteIntroductoryOffersMutationOptions,
  Bs as getCreateBankAccountInfoMutationOptions,
  ns as getCreateCapitalProductIntentsMutationOptions,
  ds as getCreateCapitalProductSuperApplicationActionsMutationOptions,
  cs as getCreateCapitalProductSuperApplicationsMutationOptions,
  qr as getCreateCapitalProductsMutationOptions,
  Xr as getCreateOfferedCapitalProductFeeDiscountMutationOptions,
  ct as getCreateOrganizationMutationOptions,
  st as getCreatePartnerApiKeyMutationOptions,
  ly as getCreatePdPartnerEventMarkNotificationReadMutationOptions,
  cd as getCreatePdWebhookEndpointMutationOptions,
  ws as getCreatePlaidInvestigationMutationOptions,
  Xi as getCreateS3OfferCsvMutationOptions,
  Kd as getCreateS3PartnerDashboardTableCsvMutationOptions,
  Wn as getDataingestionS3DatasetIngestionConfigs,
  An as getDataingestionS3DatasetIntegrations,
  Cn as getDataingestionS3DatasetUploadValidationResults,
  Rn as getDataingestionS3DatasetUploads,
  In as getDataingestionS3OrgDatasetIntegrations,
  tr as getDataingestionStripeIngestions,
  hi as getDebtChecks,
  pi as getDebts,
  u_ as getDeleteAuthManagementUseridSamlSamlconfigidMutationOptions,
  we as getDeleteBeneficialOwnersIdMutationOptions,
  qn as getDeleteBusinessOfficersIdMutationOptions,
  S as getDeleteCashAdvancePausesP1MutationOptions,
  Re as getDeleteMarketingIntegrationsVendorMutationOptions,
  ai as getDeleteMerchantBankExternalAccountsIdMutationOptions,
  nu as getDeleteMerchantBankRecipientsIdMutationOptions,
  vt as getDeletePartnerDeleteUsersMutationOptions,
  $t as getDeletePartnerSyncWebhooksIdMutationOptions,
  dd as getDeletePdWebhookEndpointMutationOptions,
  Kt as getDeleteSlackChannelsIdMutationOptions,
  Aa as getDocumentUploadGroup,
  Ia as getDocumentUploadList,
  ki as getFinancialReviews,
  ps as getFlexLoanMinimumRepaymentSchedule,
  Es as getFundingAccounts,
  w as getFutureActivities,
  vi as getGetAccountingInfoQueryKey,
  Ci as getGetAccountingInfoQueryOptions,
  jd as getGetAmazonFundingAccountLockQueryKey,
  Xd as getGetAmazonFundingAccountLockQueryOptions,
  Qi as getGetAnalyticsCubeTokenQueryKey,
  Li as getGetAnalyticsCubeTokenQueryOptions,
  n_ as getGetAuthManagementP1SamlQueryKey,
  r_ as getGetAuthManagementP1SamlQueryOptions,
  xs as getGetBankAccountInfosQueryKey,
  Fs as getGetBankAccountInfosQueryOptions,
  je as getGetBanksQueryKey,
  Xe as getGetBanksQueryOptions,
  Ne as getGetBeneficialOwnersQueryKey,
  De as getGetBeneficialOwnersQueryOptions,
  q as getGetBusinessAnnotationsQueryKey,
  f as getGetBusinessAnnotationsQueryOptions,
  p as getGetBusinessCoresQueryKey,
  _ as getGetBusinessCoresQueryOptions,
  Xs as getGetBusinessIdentitiesQueryKey,
  Js as getGetBusinessIdentitiesQueryOptions,
  pn as getGetBusinessOfficersQueryKey,
  _n as getGetBusinessOfficersQueryOptions,
  cn as getGetBusinessTableRowsCardQueryKey,
  yn as getGetBusinessTableRowsCardQueryOptions,
  an as getGetBusinessTableRowsLoanQueryKey,
  on as getGetBusinessTableRowsLoanQueryOptions,
  nn as getGetBusinessTableRowsMcaQueryKey,
  rn as getGetBusinessTableRowsMcaQueryOptions,
  Ze as getGetBusinessTableRowsProductAgnosticQueryKey,
  tn as getGetBusinessTableRowsProductAgnosticQueryOptions,
  so as getGetBusinessesDailySalesRecordsQueryKey,
  ao as getGetBusinessesDailySalesRecordsQueryOptions,
  Zi as getGetBusinessesNotificationsQueryKey,
  tc as getGetBusinessesNotificationsQueryOptions,
  va as getGetBusinessesProductsQueryKey,
  Ca as getGetBusinessesProductsQueryOptions,
  xr as getGetCapitalProductActivitiesQueryKey,
  Fr as getGetCapitalProductActivitiesQueryOptions,
  Ar as getGetCapitalProductApplicationSnapshotsQueryKey,
  Sr as getGetCapitalProductApplicationSnapshotsQueryOptions,
  Br as getGetCapitalProductApplicationsQueryKey,
  vr as getGetCapitalProductApplicationsQueryOptions,
  Zr as getGetCapitalProductIntentsQueryKey,
  ts as getGetCapitalProductIntentsQueryOptions,
  Ir as getGetCapitalProductNetTopUpItemizationQueryKey,
  Tr as getGetCapitalProductNetTopUpItemizationQueryOptions,
  Kr as getGetCapitalProductOfferCollectionQueryKey,
  br as getGetCapitalProductOfferCollectionQueryOptions,
  dr as getGetCapitalProductProspectiveTermsOfServiceQueryKey,
  pr as getGetCapitalProductProspectiveTermsOfServiceQueryOptions,
  Vr as getGetCapitalProductRepaymentScheduleQueryKey,
  Yr as getGetCapitalProductRepaymentScheduleQueryOptions,
  Qr as getGetCapitalProductStateDisclosureQueryKey,
  Lr as getGetCapitalProductStateDisclosureQueryOptions,
  lr as getGetCapitalProductsQueryKey,
  mr as getGetCapitalProductsQueryOptions,
  k_ as getGetCardAccessTokenQueryKey,
  M_ as getGetCardAccessTokenQueryOptions,
  g_ as getGetCardBalancesQueryKey,
  q_ as getGetCardBalancesQueryOptions,
  F_ as getGetCardLifecycleStatesQueryKey,
  R_ as getGetCardLifecycleStatesQueryOptions,
  U_ as getGetCardPendingTransactionsQueryKey,
  A_ as getGetCardPendingTransactionsQueryOptions,
  v_ as getGetCardTransactionsQueryKey,
  C_ as getGetCardTransactionsQueryOptions,
  h_ as getGetCardholdersQueryKey,
  O_ as getGetCardholdersQueryOptions,
  Ma as getGetCardsQueryKey,
  xa as getGetCardsQueryOptions,
  b as getGetCashAdvanceChargeOffsQueryKey,
  P as getGetCashAdvanceChargeOffsQueryOptions,
  v as getGetCashAdvancePausesQueryKey,
  C as getGetCashAdvancePausesQueryOptions,
  os as getGetCounterofferQueryKey,
  us as getGetCounterofferQueryOptions,
  $n as getGetDataingestionS3DatasetIngestionConfigsQueryKey,
  zn as getGetDataingestionS3DatasetIngestionConfigsQueryOptions,
  Sn as getGetDataingestionS3DatasetIntegrationsQueryKey,
  Nn as getGetDataingestionS3DatasetIntegrationsQueryOptions,
  En as getGetDataingestionS3DatasetUploadValidationResultsQueryKey,
  Un as getGetDataingestionS3DatasetUploadValidationResultsQueryOptions,
  Bn as getGetDataingestionS3DatasetUploadsQueryKey,
  vn as getGetDataingestionS3DatasetUploadsQueryOptions,
  Tn as getGetDataingestionS3OrgDatasetIntegrationsQueryKey,
  wn as getGetDataingestionS3OrgDatasetIntegrationsQueryOptions,
  er as getGetDataingestionStripeIngestionsQueryKey,
  nr as getGetDataingestionStripeIngestionsQueryOptions,
  Oi as getGetDebtChecksQueryKey,
  Ki as getGetDebtChecksQueryOptions,
  _i as getGetDebtsQueryKey,
  li as getGetDebtsQueryOptions,
  Sa as getGetDocumentUploadGroupQueryKey,
  Na as getGetDocumentUploadGroupQueryOptions,
  Ta as getGetDocumentUploadListQueryKey,
  wa as getGetDocumentUploadListQueryOptions,
  Mi as getGetFinancialReviewsQueryKey,
  xi as getGetFinancialReviewsQueryOptions,
  _s as getGetFlexLoanMinimumRepaymentScheduleQueryKey,
  ls as getGetFlexLoanMinimumRepaymentScheduleQueryOptions,
  Us as getGetFundingAccountsQueryKey,
  As as getGetFundingAccountsQueryOptions,
  Q as getGetFutureActivitiesQueryKey,
  L as getGetFutureActivitiesQueryOptions,
  ee as getGetHackathonGetPilotQueryKey,
  ne as getGetHackathonGetPilotQueryOptions,
  d_ as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryKey,
  p_ as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryOptions,
  Xa as getGetLiensQueryKey,
  Ja as getGetLiensQueryOptions,
  ve as getGetMarketingGtmToolkitQueryKey,
  Ce as getGetMarketingGtmToolkitQueryOptions,
  Pe as getGetMarketingIntegrationsQueryKey,
  ke as getGetMarketingIntegrationsQueryOptions,
  Ue as getGetMarketingParagonTokenQueryKey,
  Ae as getGetMarketingParagonTokenQueryOptions,
  Po as getGetMerchantBankAccountsIdBalancesQueryKey,
  ko as getGetMerchantBankAccountsIdBalancesQueryOptions,
  Oo as getGetMerchantBankAccountsIdQueryKey,
  Ko as getGetMerchantBankAccountsIdQueryOptions,
  xo as getGetMerchantBankAccountsQueryKey,
  Fo as getGetMerchantBankAccountsQueryOptions,
  Td as getGetMerchantBankApplicationsIdQueryKey,
  wd as getGetMerchantBankApplicationsIdQueryOptions,
  Ld as getGetMerchantBankApplicationsQueryKey,
  Wd as getGetMerchantBankApplicationsQueryOptions,
  ku as getGetMerchantBankCardAccessTokenQueryKey,
  Mu as getGetMerchantBankCardAccessTokenQueryOptions,
  Bu as getGetMerchantBankCardCardholderAccessTokenQueryKey,
  vu as getGetMerchantBankCardCardholderAccessTokenQueryOptions,
  hu as getGetMerchantBankCardCardholdersQueryKey,
  Ou as getGetMerchantBankCardCardholdersQueryOptions,
  Nu as getGetMerchantBankCardDisputesQueryKey,
  Du as getGetMerchantBankCardDisputesQueryOptions,
  pu as getGetMerchantBankCardsQueryKey,
  _u as getGetMerchantBankCardsQueryOptions,
  Vu as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryKey,
  Yu as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryOptions,
  ni as getGetMerchantBankExternalAccountsIdQueryKey,
  ri as getGetMerchantBankExternalAccountsIdQueryOptions,
  $u as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryKey,
  zu as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryOptions,
  Xu as getGetMerchantBankExternalAccountsQueryKey,
  Ju as getGetMerchantBankExternalAccountsQueryOptions,
  $o as getGetMerchantBankInterestDetailsQueryKey,
  zo as getGetMerchantBankInterestDetailsQueryOptions,
  Tp as getGetMerchantBankOffersQueryKey,
  wp as getGetMerchantBankOffersQueryOptions,
  Zo as getGetMerchantBankRecipientsIdQueryKey,
  tu as getGetMerchantBankRecipientsIdQueryOptions,
  Vo as getGetMerchantBankRecipientsQueryKey,
  Yo as getGetMerchantBankRecipientsQueryOptions,
  Uo as getGetMerchantBankTransactionsIdQueryKey,
  Ao as getGetMerchantBankTransactionsIdQueryOptions,
  No as getGetMerchantBankTransactionsQueryKey,
  Do as getGetMerchantBankTransactionsQueryOptions,
  Io as getGetMerchantBankTransferFeesQueryKey,
  To as getGetMerchantBankTransferFeesQueryOptions,
  Qo as getGetMerchantBankTransferLimitsQueryKey,
  Lo as getGetMerchantBankTransferLimitsQueryOptions,
  Zd as getGetNaicsQueryKey,
  tp as getGetNaicsQueryOptions,
  Di as getGetOfficerSubsidiaryRecordsQueryKey,
  Gi as getGetOfficerSubsidiaryRecordsQueryOptions,
  ir as getGetOnboardingBusinessRequirementsQueryKey,
  cr as getGetOnboardingBusinessRequirementsQueryOptions,
  Vs as getGetOnboardingInfoNeededQueryKey,
  Ys as getGetOnboardingInfoNeededQueryOptions,
  $s as getGetOnboardingStatesQueryKey,
  zs as getGetOnboardingStatesQueryOptions,
  _t as getGetOrganizationQueryKey,
  lt as getGetOrganizationQueryOptions,
  $e as getGetOwnerIsBeneficialOwnerQueryKey,
  ze as getGetOwnerIsBeneficialOwnerQueryOptions,
  na as getGetOwnersQueryKey,
  ra as getGetOwnersQueryOptions,
  op as getGetPADAgreementQueryKey,
  up as getGetPADAgreementQueryOptions,
  yo as getGetParafinderBusinessTypesQueryKey,
  po as getGetParafinderBusinessTypesQueryOptions,
  qo as getGetParafinderCapitalInfoQueryKey,
  fo as getGetParafinderCapitalInfoQueryOptions,
  uo as getGetParafinderQueryKey,
  io as getGetParafinderQueryOptions,
  lo as getGetParafinderSummaryQueryKey,
  mo as getGetParafinderSummaryQueryOptions,
  et as getGetPartnerApiKeyQueryKey,
  nt as getGetPartnerApiKeyQueryOptions,
  St as getGetPartnerConfigsQueryKey,
  Nt as getGetPartnerConfigsQueryOptions,
  Et as getGetPartnerPublicConfigsQueryKey,
  Ut as getGetPartnerPublicConfigsQueryOptions,
  Tt as getGetPartnerSyncWebhooksQueryKey,
  wt as getGetPartnerSyncWebhooksQueryOptions,
  Pt as getGetPartnerUsersQueryKey,
  kt as getGetPartnerUsersQueryOptions,
  Sd as getGetPartnerWidgetConfigsQueryKey,
  Nd as getGetPartnerWidgetConfigsQueryOptions,
  F as getGetPartnersDoordashAdminUsersQueryKey,
  R as getGetPartnersDoordashAdminUsersQueryOptions,
  $ as getGetPartnersQueryKey,
  z as getGetPartnersQueryOptions,
  j as getGetPartnersUnderwritingQueryKey,
  X as getGetPartnersUnderwritingQueryOptions,
  ry as getGetPdOfferUrlQueryKey,
  sy as getGetPdOfferUrlQueryOptions,
  Py as getGetPdUtilityInfoQueryKey,
  ky as getGetPdUtilityInfoQueryOptions,
  Gs as getGetPlaidInvestigationsQueryKey,
  Is as getGetPlaidInvestigationsQueryOptions,
  I as getGetRepaymentPlanParametersQueryKey,
  T as getGetRepaymentPlanParametersQueryOptions,
  La as getGetRestrictionQueryKey,
  Wa as getGetRestrictionQueryOptions,
  Va as getGetRestrictionsDetailsQueryKey,
  Ya as getGetRestrictionsDetailsQueryOptions,
  fs as getGetRunOfacScreeningResultsQueryKey,
  hs as getGetRunOfacScreeningResultsQueryOptions,
  Pd as getGetRutterConnectionsQueryKey,
  kd as getGetRutterConnectionsQueryOptions,
  md as getGetS3PartnerDashboardTableCsvQueryKey,
  gd as getGetS3PartnerDashboardTableCsvQueryOptions,
  vd as getGetSardineSessionIdQueryKey,
  Cd as getGetSardineSessionIdQueryOptions,
  ft as getGetSlackChannelsQueryKey,
  ht as getGetSlackChannelsQueryOptions,
  fe as getGetStatementsV2QueryKey,
  he as getGetStatementsV2QueryOptions,
  sd as getGetSyncAndMaybeMarkFailedMutationOptions,
  ed as getGetSyncQueryKey,
  nd as getGetSyncQueryOptions,
  fp as getGetTemplateMockQueryKey,
  hp as getGetTemplateMockQueryOptions,
  kp as getGetTemplateSnapshotQueryKey,
  Mp as getGetTemplateSnapshotQueryOptions,
  Kp as getGetTemplateSnapshotsQueryKey,
  bp as getGetTemplateSnapshotsQueryOptions,
  Fp as getGetTemplateTypesQueryKey,
  Rp as getGetTemplateTypesQueryOptions,
  dp as getGetTemplatesQueryKey,
  pp as getGetTemplatesQueryOptions,
  Ps as getGetTermLoanProspectiveRepaymentsQueryKey,
  ks as getGetTermLoanProspectiveRepaymentsQueryOptions,
  te as getHackathonGetPilot,
  y_ as getLatestAttemptToUnderwriteIntroductoryOffers,
  ja as getLiens,
  dt as getLinkPartnerMutationOptions,
  $i as getListApiRequestsQueryKey,
  zi as getListApiRequestsQueryOptions,
  Pn as getListDatasetUploadsQueryKey,
  kn as getListDatasetUploadsQueryOptions,
  xn as getListManualDatasetUploadValidationResultsQueryKey,
  Fn as getListManualDatasetUploadValidationResultsQueryOptions,
  ot as getListOrganizationsQueryKey,
  ut as getListOrganizationsQueryOptions,
  oc as getListPdBusinessDetailsApplicationsQueryKey,
  uc as getListPdBusinessDetailsApplicationsQueryOptions,
  cc as getListPdBusinessDetailsBankInfoQueryKey,
  yc as getListPdBusinessDetailsBankInfoQueryOptions,
  pc as getListPdBusinessDetailsBusinessInfoQueryKey,
  _c as getListPdBusinessDetailsBusinessInfoQueryOptions,
  fc as getListPdBusinessDetailsFundedProductDetailsQueryKey,
  hc as getListPdBusinessDetailsFundedProductDetailsQueryOptions,
  Kc as getListPdBusinessDetailsFundedProductPaymentProgressQueryKey,
  bc as getListPdBusinessDetailsFundedProductPaymentProgressQueryOptions,
  mc as getListPdBusinessDetailsFundedProductsQueryKey,
  gc as getListPdBusinessDetailsFundedProductsQueryOptions,
  kc as getListPdBusinessDetailsOffersQueryKey,
  Mc as getListPdBusinessDetailsOffersQueryOptions,
  Fc as getListPdBusinessDetailsOwnerInfoQueryKey,
  Rc as getListPdBusinessDetailsOwnerInfoQueryOptions,
  Uc as getListPdBusinessTableRowsQueryKey,
  Ac as getListPdBusinessTableRowsQueryOptions,
  $c as getListPdCapitalProductApplicationRowsQueryKey,
  zc as getListPdCapitalProductApplicationRowsQueryOptions,
  Xc as getListPdCapitalProductOfferRowsQueryKey,
  Jc as getListPdCapitalProductOfferRowsQueryOptions,
  Ic as getListPdCapitalProductRowsQueryKey,
  Tc as getListPdCapitalProductRowsQueryOptions,
  $y as getListPdCrmDeliveryAttemptsQueryKey,
  zy as getListPdCrmDeliveryAttemptsQueryOptions,
  jy as getListPdCrmEventInfoQueryKey,
  Xy as getListPdCrmEventInfoQueryOptions,
  Qy as getListPdCrmEventsQueryKey,
  Ly as getListPdCrmEventsQueryOptions,
  Oy as getListPdCrmIntegrationsQueryKey,
  Ky as getListPdCrmIntegrationsQueryOptions,
  oy as getListPdPartnerEventListUserSubscriptionsQueryKey,
  uy as getListPdPartnerEventListUserSubscriptionsQueryOptions,
  cy as getListPdPartnerEventListWebNotificationsQueryKey,
  yy as getListPdPartnerEventListWebNotificationsQueryOptions,
  ty as getListPdSupportInfoQueryKey,
  ey as getListPdSupportInfoQueryOptions,
  od as getListPdWebhookEndpointsQueryKey,
  ud as getListPdWebhookEndpointsQueryOptions,
  Ey as getListPdWebhookLogsDeliveryAttemptsQueryKey,
  Uy as getListPdWebhookLogsDeliveryAttemptsQueryOptions,
  By as getListPdWebhookLogsEventInfoQueryKey,
  vy as getListPdWebhookLogsEventInfoQueryOptions,
  xy as getListPdWebhookLogsEventsQueryKey,
  Fy as getListPdWebhookLogsEventsQueryOptions,
  Vi as getListS3OfferCsvsQueryKey,
  Yi as getListS3OfferCsvsQueryOptions,
  fd as getListS3PartnerDashboardTableCsvsQueryKey,
  hd as getListS3PartnerDashboardTableCsvsQueryOptions,
  $p as getListSardineDetailsQueryKey,
  zp as getListSardineDetailsQueryOptions,
  Jn as getListVersionedSchemasQueryKey,
  Zn as getListVersionedSchemasQueryOptions,
  cp as getMarkPADAgreementSignedMutationOptions,
  Be as getMarketingGtmToolkit,
  be as getMarketingIntegrations,
  Ee as getMarketingParagonToken,
  Mo as getMerchantBankAccounts,
  ho as getMerchantBankAccountsId,
  bo as getMerchantBankAccountsIdBalances,
  Qd as getMerchantBankApplications,
  Id as getMerchantBankApplicationsId,
  Pu as getMerchantBankCardAccessToken,
  Ru as getMerchantBankCardCardholderAccessToken,
  fu as getMerchantBankCardCardholders,
  Su as getMerchantBankCardDisputes,
  du as getMerchantBankCards,
  ju as getMerchantBankExternalAccounts,
  ei as getMerchantBankExternalAccountsId,
  Hu as getMerchantBankExternalAccountsIdPlaidLinkToken,
  Wu as getMerchantBankExternalAccountsPlaidLinkToken,
  Wo as getMerchantBankInterestDetails,
  Ip as getMerchantBankOffers,
  Ho as getMerchantBankRecipients,
  Jo as getMerchantBankRecipientsId,
  So as getMerchantBankTransactions,
  Eo as getMerchantBankTransactionsId,
  Go as getMerchantBankTransferFees,
  wo as getMerchantBankTransferLimits,
  Sy as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryKey,
  Ny as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryOptions,
  Jd as getNaics,
  Ni as getOfficerSubsidiaryRecords,
  ur as getOnboardingBusinessRequirements,
  Hs as getOnboardingInfoNeeded,
  Ws as getOnboardingStates,
  pt as getOrganization,
  We as getOwnerIsBeneficialOwner,
  ea as getOwners,
  ap as getPADAgreement,
  oo as getParafinder,
  co as getParafinderBusinessTypes,
  go as getParafinderCapitalInfo,
  _o as getParafinderSummary,
  tt as getPartnerApiKey,
  At as getPartnerConfigs,
  Ct as getPartnerPublicConfigs,
  It as getPartnerSyncWebhooks,
  bt as getPartnerUsers,
  Ad as getPartnerWidgetConfigs,
  W as getPartners,
  x as getPartnersDoordashAdminUsers,
  Y as getPartnersUnderwriting,
  c_ as getPatchAuthManagementUseridSamlSamlconfigidMutationOptions,
  Le as getPatchBeneficialOwnersIdMutationOptions,
  ta as getPatchBusinessIdentitiesIdMutationOptions,
  hn as getPatchBusinessOfficersIdMutationOptions,
  ss as getPatchCapitalProductIntentsMutationOptions,
  hr as getPatchCapitalProductsMutationOptions,
  b_ as getPatchCardholdersCardholderidMutationOptions,
  D as getPatchCashAdvancePausesP1MutationOptions,
  Ks as getPatchClearWatchlistHitsIdMutationOptions,
  or as getPatchDataingestionStripeIngestionsMutationOptions,
  qa as getPatchFlexLoanAgeMutationOptions,
  Ka as getPatchFlexLoanFundMutationOptions,
  ha as getPatchFlexLoanOwnershipMutationOptions,
  to as getPatchLiensIdMutationOptions,
  qu as getPatchMerchantBankCardCardholdersIdMutationOptions,
  ui as getPatchMerchantBankExternalAccountsIdMutationOptions,
  di as getPatchMerchantBankExternalBankTransfersIdMutationOptions,
  Vp as getPatchMerchantBankMerchantConfigMutationOptions,
  yu as getPatchMerchantBankRecipientTransfersIdMutationOptions,
  su as getPatchMerchantBankRecipientsIdMutationOptions,
  Gp as getPatchMfaExtendSessionMutationOptions,
  Ve as getPatchOwnerIsBeneficialOwnerMutationOptions,
  aa as getPatchOwnersIdMutationOptions,
  Gt as getPatchPartnerConfigsIdMutationOptions,
  Ht as getPatchPartnerSyncWebhooksIdMutationOptions,
  Rt as getPatchPartnerUsersMutationOptions,
  Gd as getPatchPartnerWidgetConfigsMutationOptions,
  Z as getPatchPartnersIdMutationOptions,
  Ls as getPatchPersonaFallbackInquiryMutationOptions,
  gp as getPatchTemplatesMutationOptions,
  ny as getPdOfferUrl,
  by as getPdUtilityInfo,
  Ds as getPlaidInvestigations,
  Ui as getPostAccountingInfoMutationOptions,
  da as getPostAmazonRedirectUrlMutationOptions,
  Jp as getPostAuthLoginStartMutationOptions,
  jp as getPostAuthLoginValidateOtpMutationOptions,
  a_ as getPostAuthManagementUseridSamlMutationOptions,
  t_ as getPostAuthTokenExchangeMutationOptions,
  Yt as getPostAuthTokensMutationOptions,
  Ie as getPostBeneficialOwnersMutationOptions,
  O as getPostBusinessAnnotationsMutationOptions,
  Ua as getPostBusinessAuthLinkMutationOptions,
  m as getPostBusinessCoresMutationOptions,
  ua as getPostBusinessMutationOptions,
  mn as getPostBusinessOfficersMutationOptions,
  nc as getPostBusinessesNotificationsMutationOptions,
  Er as getPostCapitalProductApplicationMutationOptions,
  ma as getPostCapitalProductBusinessMutationOptions,
  Dr as getPostCapitalProductNetTopUpMutationOptions,
  kr as getPostCapitalProductOfferCollectionMutationOptions,
  Pi as getPostCardCloseAccountMutationOptions,
  Ra as getPostCardsMutationOptions,
  M as getPostCashAdvanceChargeOffsMutationOptions,
  U as getPostCashAdvancePausesMutationOptions,
  Kn as getPostDataingestionOneschemaGenerateJwtMutationOptions,
  Gn as getPostDataingestionS3DatasetIntegrationsMutationOptions,
  Ln as getPostDataingestionS3OrgDatasetIntegrationsMutationOptions,
  jn as getPostDataingestionS3RunDatasetIngestionMutationOptions,
  sr as getPostDataingestionStripeIngestionsMutationOptions,
  fi as getPostDebtsCheckCompleteMutationOptions,
  gi as getPostDebtsMutationOptions,
  Ga as getPostDocumentUploadGroupMutationOptions,
  Ri as getPostFinancialReviewsMutationOptions,
  Ap as getPostForgiveCapitalFeeMutationOptions,
  Ns as getPostFundingAccountsMutationOptions,
  oe as getPostHackathonAwaitParafinRepresentativeMutationOptions,
  Zt as getPostHackathonCreatePilotMutationOptions,
  se as getPostHackathonGenerateOffersMutationOptions,
  ye as getPostHackathonGmvMutationOptions,
  le as getPostHackathonMapOffersToEmailsMutationOptions,
  Xt as getPostHackathonPartnersMutationOptions,
  pe as getPostHackathonResetMutationOptions,
  ie as getPostHackathonSendOffersMutationOptions,
  ge as getPostHackathonUploadSalesCsvMutationOptions,
  Ep as getPostManualRepaymentMutationOptions,
  Rd as getPostMarkAccountingIntegrationSeenMutationOptions,
  xe as getPostMarketingIntegrationsMutationOptions,
  Ke as getPostMarketingReviewMutationOptions,
  Bo as getPostMerchantBankAccountsMutationOptions,
  zd as getPostMerchantBankApplicationsMutationOptions,
  ca as getPostMerchantBankBusinessMutationOptions,
  bu as getPostMerchantBankCardActivateMutationOptions,
  Fu as getPostMerchantBankCardApplePayMutationOptions,
  Lu as getPostMerchantBankCardDisputeChargebackCreditMutationOptions,
  wu as getPostMerchantBankCardDisputeStateTransitionMutationOptions,
  Iu as getPostMerchantBankCardDisputesMutationOptions,
  Au as getPostMerchantBankCardGooglePayMutationOptions,
  Eu as getPostMerchantBankCardStateTransitionMutationOptions,
  mu as getPostMerchantBankCardsMutationOptions,
  ti as getPostMerchantBankExternalAccountsMutationOptions,
  ci as getPostMerchantBankExternalBankTransfersMutationOptions,
  Co as getPostMerchantBankInnerTransfersMutationOptions,
  Lp as getPostMerchantBankOffersMutationOptions,
  iu as getPostMerchantBankRecipientTransfersMutationOptions,
  ou as getPostMerchantBankRecipientsIdPaymentRailsMutationOptions,
  Xo as getPostMerchantBankRecipientsMutationOptions,
  np as getPostMfaOtpSendPersonidPhonenumbertypeMutationOptions,
  sp as getPostMfaOtpVerifyPersonidPhonenumbertypeMutationOptions,
  Np as getPostMfaVerifyAccountDetailsMutationOptions,
  Ti as getPostOfficerSubsidiaryRecordsMutationOptions,
  no as getPostOptInMutationOptions,
  Lt as getPostPartnerSyncWebhooksMutationOptions,
  xt as getPostPartnerUsersMutationOptions,
  V as getPostPartnersMutationOptions,
  za as getPostRestrictionMutationOptions,
  xd as getPostRutterConnectionsMutationOptions,
  Ud as getPostSardineSessionIdMutationOptions,
  Pa as getPostSetupAmazonTopUpMutationOptions,
  gt as getPostSlackAuthorizeMutationOptions,
  _a as getPostSpdMutationOptions,
  Si as getPostTaxRecordsMutationOptions,
  vp as getPostTemplateTypesMutationOptions,
  lp as getPostTemplatesMutationOptions,
  sc as getPostTermsOfServiceMarkShownMutationOptions,
  gs as getPostTriggerRunCelticsChecklistMutationOptions,
  Vd as getPostWebhooksPlaidGeneralMutationOptions,
  Vn as getPutDataingestionS3DatasetIngestionConfigsMutationOptions,
  G as getRepaymentPlanParameters,
  Vy as getResendCrmAttemptMutationOptions,
  Gy as getResendWebhookAttemptMutationOptions,
  Qa as getRestriction,
  Ha as getRestrictionsDetails,
  qs as getRunOfacScreeningResults,
  bd as getRutterConnections,
  ld as getS3PartnerDashboardTableCsv,
  Bd as getSardineSessionId,
  qt as getSlackChannels,
  ZK as getStatement,
  qe as getStatementsV2,
  td as getSync,
  rd as getSyncAndMaybeMarkFailed,
  qp as getTemplateMock,
  Pp as getTemplateSnapshot,
  Op as getTemplateSnapshots,
  xp as getTemplateTypes,
  yp as getTemplates,
  bs as getTermLoanProspectiveRepayments,
  Cs as getUpdateBankAccountInfoMutationOptions,
  gy as getUpdatePdPartnerEventUpdateUserSubscriptionMutationOptions,
  fy as getUpdatePdPartnerEventUpdateUserSubscriptionsForEventTypeMutationOptions,
  _d as getUpdatePdWebhookEndpointMutationOptions,
  yt as linkPartner,
  Wi as listApiRequests,
  bn as listDatasetUploads,
  Mn as listManualDatasetUploadValidationResults,
  at as listOrganizations,
  ac as listPdBusinessDetailsApplications,
  ic as listPdBusinessDetailsBankInfo,
  dc as listPdBusinessDetailsBusinessInfo,
  qc as listPdBusinessDetailsFundedProductDetails,
  Oc as listPdBusinessDetailsFundedProductPaymentProgress,
  lc as listPdBusinessDetailsFundedProducts,
  Pc as listPdBusinessDetailsOffers,
  xc as listPdBusinessDetailsOwnerInfo,
  Ec as listPdBusinessTableRows,
  Wc as listPdCapitalProductApplicationRows,
  jc as listPdCapitalProductOfferRows,
  Gc as listPdCapitalProductRows,
  Wy as listPdCrmDeliveryAttempts,
  Yy as listPdCrmEventInfo,
  wy as listPdCrmEvents,
  hy as listPdCrmIntegrations,
  ay as listPdPartnerEventListUserSubscriptions,
  iy as listPdPartnerEventListWebNotifications,
  Zc as listPdSupportInfo,
  ad as listPdWebhookEndpoints,
  Cy as listPdWebhookLogsDeliveryAttempts,
  Ry as listPdWebhookLogsEventInfo,
  My as listPdWebhookLogsEvents,
  Hi as listS3OfferCsvs,
  qd as listS3PartnerDashboardTableCsvs,
  Wp as listSardineDetails,
  Xn as listVersionedSchemas,
  ip as markPADAgreementSigned,
  Ay as multiWebhookPdWebhookLogsDeliveryAttempts,
  i_ as patchAuthManagementUseridSamlSamlconfigid,
  Qe as patchBeneficialOwnersId,
  Zs as patchBusinessIdentitiesId,
  fn as patchBusinessOfficersId,
  rs as patchCapitalProductIntents,
  fr as patchCapitalProducts,
  K_ as patchCardholdersCardholderid,
  N as patchCashAdvancePausesP1,
  Os as patchClearWatchlistHitsId,
  ar as patchDataingestionStripeIngestions,
  ga as patchFlexLoanAge,
  Oa as patchFlexLoanFund,
  fa as patchFlexLoanOwnership,
  Za as patchLiensId,
  gu as patchMerchantBankCardCardholdersId,
  oi as patchMerchantBankExternalAccountsId,
  yi as patchMerchantBankExternalBankTransfersId,
  Hp as patchMerchantBankMerchantConfig,
  cu as patchMerchantBankRecipientTransfersId,
  ru as patchMerchantBankRecipientsId,
  Dp as patchMfaExtendSession,
  He as patchOwnerIsBeneficialOwner,
  sa as patchOwnersId,
  Dt as patchPartnerConfigsId,
  zt as patchPartnerSyncWebhooksId,
  Ft as patchPartnerUsers,
  Dd as patchPartnerWidgetConfigs,
  J as patchPartnersId,
  Qs as patchPersonaFallbackInquiry,
  mp as patchTemplates,
  Ei as postAccountingInfo,
  ya as postAmazonRedirectUrl,
  Xp as postAuthLoginStart,
  Yp as postAuthLoginValidateOtp,
  s_ as postAuthManagementUseridSaml,
  Zp as postAuthTokenExchange,
  Vt as postAuthTokens,
  Ge as postBeneficialOwners,
  oa as postBusiness,
  h as postBusinessAnnotations,
  Ea as postBusinessAuthLink,
  l as postBusinessCores,
  ln as postBusinessOfficers,
  ec as postBusinessesNotifications,
  Cr as postCapitalProductApplication,
  la as postCapitalProductBusiness,
  Nr as postCapitalProductNetTopUp,
  Pr as postCapitalProductOfferCollection,
  bi as postCardCloseAccount,
  Fa as postCards,
  k as postCashAdvanceChargeOffs,
  E as postCashAdvancePauses,
  On as postDataingestionOneschemaGenerateJwt,
  Dn as postDataingestionS3DatasetIntegrations,
  Qn as postDataingestionS3OrgDatasetIntegrations,
  Yn as postDataingestionS3RunDatasetIngestion,
  rr as postDataingestionStripeIngestions,
  mi as postDebts,
  qi as postDebtsCheckComplete,
  Da as postDocumentUploadGroup,
  Fi as postFinancialReviews,
  Up as postForgiveCapitalFee,
  Ss as postFundingAccounts,
  ae as postHackathonAwaitParafinRepresentative,
  Jt as postHackathonCreatePilot,
  re as postHackathonGenerateOffers,
  ce as postHackathonGmv,
  _e as postHackathonMapOffersToEmails,
  jt as postHackathonPartners,
  de as postHackathonReset,
  ue as postHackathonSendOffers,
  me as postHackathonUploadSalesCsv,
  Cp as postManualRepayment,
  Fd as postMarkAccountingIntegrationSeen,
  Me as postMarketingIntegrations,
  Oe as postMarketingReview,
  Ro as postMerchantBankAccounts,
  $d as postMerchantBankApplications,
  ia as postMerchantBankBusiness,
  Ku as postMerchantBankCardActivate,
  xu as postMerchantBankCardApplePay,
  Qu as postMerchantBankCardDisputeChargebackCredit,
  Tu as postMerchantBankCardDisputeStateTransition,
  Gu as postMerchantBankCardDisputes,
  Uu as postMerchantBankCardGooglePay,
  Cu as postMerchantBankCardStateTransition,
  lu as postMerchantBankCards,
  Zu as postMerchantBankExternalAccounts,
  ii as postMerchantBankExternalBankTransfers,
  vo as postMerchantBankInnerTransfers,
  Qp as postMerchantBankOffers,
  uu as postMerchantBankRecipientTransfers,
  jo as postMerchantBankRecipients,
  au as postMerchantBankRecipientsIdPaymentRails,
  ep as postMfaOtpSendPersonidPhonenumbertype,
  rp as postMfaOtpVerifyPersonidPhonenumbertype,
  Sp as postMfaVerifyAccountDetails,
  Ii as postOfficerSubsidiaryRecords,
  eo as postOptIn,
  Qt as postPartnerSyncWebhooks,
  Mt as postPartnerUsers,
  H as postPartners,
  $a as postRestriction,
  Md as postRutterConnections,
  Ed as postSardineSessionId,
  ba as postSetupAmazonTopUp,
  mt as postSlackAuthorize,
  pa as postSpd,
  Ai as postTaxRecords,
  Bp as postTemplateTypes,
  _p as postTemplates,
  rc as postTermsOfServiceMarkShown,
  ms as postTriggerRunCelticsChecklist,
  Hd as postWebhooksPlaidGeneral,
  bb as publicApiPaths,
  Hn as putDataingestionS3DatasetIngestionConfigs,
  Hy as resendCrmAttempt,
  Dy as resendWebhookAttempt,
  vs as updateBankAccountInfo,
  my as updatePdPartnerEventUpdateUserSubscription,
  qy as updatePdPartnerEventUpdateUserSubscriptionsForEventType,
  pd as updatePdWebhookEndpoint,
  _b as useAmazonRPCPreLogin,
  qb as useAttemptAmazonBankVerification,
  HO as useAttemptSync,
  CO as useBatchCreatePdPartnerEventMarkAllNotificationsRead,
  QO as useBatchResendWebhookAttempt,
  rf as useBenchmarkGetCapitalProductOwnership,
  hO as useCountPdBusinessTableRows,
  PO as useCountPdCapitalProductApplicationRow,
  MO as useCountPdCapitalProductOfferRow,
  KO as useCountPdCapitalProductRow,
  WK as useCreateAttemptToUnderwriteIntroductoryOffers,
  hf as useCreateBankAccountInfo,
  ab as useCreateBeneficialOwner,
  uf as useCreateCapitalProductIntents,
  pf as useCreateCapitalProductSuperApplicationActions,
  df as useCreateCapitalProductSuperApplications,
  zq as useCreateCapitalProducts,
  af as useCreateOfferedCapitalProductFeeDiscount,
  Cg as useCreateOrganization,
  Bg as useCreatePartnerApiKey,
  EO as useCreatePdPartnerEventMarkNotificationRead,
  XO as useCreatePdWebhookEndpoint,
  kf as useCreatePlaidInvestigation,
  fb as useCreatePlaidLinkage,
  uO as useCreateS3OfferCsv,
  nK as useCreateS3PartnerDashboardTableCsv,
  pb as useCreateUnderwritingAttempt,
  yb as useCreateUnverifiedAmazonFundingAccount,
  cb as useCreateUnverifiedFundingAccount,
  gb as useCreateUnverifiedFundingAccountFromPlaidLinkage,
  hb as useCreateZendeskTicket,
  wK as useDeleteAuthManagementUseridSamlSamlconfigid,
  ub as useDeleteBeneficialOwner,
  lq as useDeleteBeneficialOwnersId,
  Mq as useDeleteBusinessOfficersId,
  Og as useDeleteCashAdvancePausesP1,
  cq as useDeleteMarketingIntegrationsVendor,
  Qh as useDeleteMerchantBankExternalAccountsId,
  fh as useDeleteMerchantBankRecipientsId,
  Tg as useDeletePartnerDeleteUsers,
  zg as useDeletePartnerSyncWebhooksId,
  JO as useDeletePdWebhookEndpoint,
  Ng as useDeleteSlackChannelsId,
  mb as useDisputeTransaction,
  Zh as useGetAccountingInfo,
  lK as useGetAmazonFundingAccountLock,
  sO as useGetAnalyticsCubeToken,
  IK as useGetAuthManagementP1Saml,
  ff as useGetBankAccountInfos,
  fq as useGetBanks,
  pq as useGetBeneficialOwners,
  _g as useGetBusinessAnnotations,
  dg as useGetBusinessCores,
  Rf as useGetBusinessIdentities,
  Pq as useGetBusinessOfficers,
  bq as useGetBusinessTableRowsCard,
  Kq as useGetBusinessTableRowsLoan,
  Oq as useGetBusinessTableRowsMca,
  hq as useGetBusinessTableRowsProductAgnostic,
  th as useGetBusinessesDailySalesRecords,
  iO as useGetBusinessesNotifications,
  Lf as useGetBusinessesProducts,
  jq as useGetCapitalProductActivities,
  Zq as useGetCapitalProductApplicationSnapshots,
  Xq as useGetCapitalProductApplications,
  of as useGetCapitalProductIntents,
  ef as useGetCapitalProductNetTopUpItemization,
  Vq as useGetCapitalProductOfferCollection,
  Wq as useGetCapitalProductProspectiveTermsOfService,
  sf as useGetCapitalProductRepaymentSchedule,
  nf as useGetCapitalProductStateDisclosure,
  $q as useGetCapitalProducts,
  VK as useGetCardAccessToken,
  $K as useGetCardBalances,
  YK as useGetCardLifecycleStates,
  XK as useGetCardPendingTransactions,
  tb as useGetCardRepayments,
  jK as useGetCardTransactions,
  zK as useGetCardholders,
  wf as useGetCards,
  mg as useGetCashAdvanceChargeOffs,
  fg as useGetCashAdvancePauses,
  yf as useGetCounteroffer,
  Nq as useGetDataingestionS3DatasetIngestionConfigs,
  Eq as useGetDataingestionS3DatasetIntegrations,
  Cq as useGetDataingestionS3DatasetUploadValidationResults,
  vq as useGetDataingestionS3DatasetUploads,
  Aq as useGetDataingestionS3OrgDatasetIntegrations,
  Tq as useGetDataingestionStripeIngestions,
  Yh as useGetDebtChecks,
  zh as useGetDebts,
  $f as useGetDocumentUploadGroup,
  Hf as useGetDocumentUploadList,
  Xh as useGetFinancialReviews,
  _f as useGetFlexLoanMinimumRepaymentSchedule,
  Kf as useGetFundingAccounts,
  Pg as useGetFutureActivities,
  Xg as useGetHackathonGetPilot,
  nb as useGetIsWhitelistedForEarlyManualPayment,
  LK as useGetLatestAttemptToUnderwriteIntroductoryOffers,
  Xf as useGetLiens,
  yq as useGetMarketingGtmToolkit,
  uq as useGetMarketingIntegrations,
  dq as useGetMarketingParagonToken,
  uh as useGetMerchantBankAccounts,
  ah as useGetMerchantBankAccountsId,
  oh as useGetMerchantBankAccountsIdBalances,
  dK as useGetMerchantBankApplications,
  yK as useGetMerchantBankApplicationsId,
  Rh as useGetMerchantBankCardAccessToken,
  vh as useGetMerchantBankCardCardholderAccessToken,
  xh as useGetMerchantBankCardCardholders,
  Uh as useGetMerchantBankCardDisputes,
  Ph as useGetMerchantBankCards,
  Ih as useGetMerchantBankExternalAccounts,
  wh as useGetMerchantBankExternalAccountsId,
  Gh as useGetMerchantBankExternalAccountsIdPlaidLinkToken,
  Dh as useGetMerchantBankExternalAccountsPlaidLinkToken,
  lh as useGetMerchantBankInterestDetails,
  EK as useGetMerchantBankOffers,
  mh as useGetMerchantBankRecipients,
  qh as useGetMerchantBankRecipientsId,
  dh as useGetMerchantBankTransactions,
  yh as useGetMerchantBankTransactionsId,
  ph as useGetMerchantBankTransferFees,
  _h as useGetMerchantBankTransferLimits,
  mK as useGetNaics,
  nO as useGetOfficerSubsidiaryRecords,
  Lq as useGetOnboardingBusinessRequirements,
  Ff as useGetOnboardingInfoNeeded,
  xf as useGetOnboardingStates,
  sb as useGetOptIns,
  rb as useGetOptOutEligibility,
  Ug as useGetOrganization,
  gq as useGetOwnerIsBeneficialOwner,
  vf as useGetOwners,
  fK as useGetPADAgreement,
  eh as useGetParafinder,
  nh as useGetParafinderBusinessTypes,
  sh as useGetParafinderCapitalInfo,
  rh as useGetParafinderSummary,
  Rg as useGetPartnerApiKey,
  Qg as useGetPartnerConfigs,
  wg as useGetPartnerPublicConfigs,
  Wg as useGetPartnerSyncWebhooks,
  Dg as useGetPartnerUsers,
  iK as useGetPartnerWidgetConfigs,
  kg as useGetPartners,
  qg as useGetPartnersDoordashAdminUsers,
  xg as useGetPartnersUnderwriting,
  RO as useGetPdOfferUrl,
  NO as useGetPdUtilityInfo,
  Pf as useGetPlaidInvestigations,
  JK as useGetPlaidLinkToken,
  bg as useGetRepaymentPlanParameters,
  Vf as useGetRestriction,
  jf as useGetRestrictionsDetails,
  mf as useGetRunOfacScreeningResults,
  rK as useGetRutterConnections,
  tK as useGetS3PartnerDashboardTableCsv,
  oK as useGetSardineSessionId,
  eb as useGetShowTopUpOffer,
  Sg as useGetSlackChannels,
  aq as useGetStatementsV2,
  VO as useGetSync,
  YO as useGetSyncAndMaybeMarkFailed,
  PK as useGetTemplateMock,
  MK as useGetTemplateSnapshot,
  kK as useGetTemplateSnapshots,
  xK as useGetTemplateTypes,
  OK as useGetTemplates,
  qf as useGetTermLoanProspectiveRepayments,
  Eg as useLinkPartner,
  aO as useListApiRequests,
  Rq as useListDatasetUploads,
  Bq as useListManualDatasetUploadValidationResults,
  vg as useListOrganizations,
  dO as useListPdBusinessDetailsApplications,
  pO as useListPdBusinessDetailsBankInfo,
  _O as useListPdBusinessDetailsBusinessInfo,
  mO as useListPdBusinessDetailsFundedProductDetails,
  gO as useListPdBusinessDetailsFundedProductPaymentProgress,
  lO as useListPdBusinessDetailsFundedProducts,
  qO as useListPdBusinessDetailsOffers,
  fO as useListPdBusinessDetailsOwnerInfo,
  OO as useListPdBusinessTableRows,
  kO as useListPdCapitalProductApplicationRows,
  xO as useListPdCapitalProductOfferRows,
  bO as useListPdCapitalProductRows,
  WO as useListPdCrmDeliveryAttempts,
  zO as useListPdCrmEventInfo,
  LO as useListPdCrmEvents,
  SO as useListPdCrmIntegrations,
  BO as useListPdPartnerEventListUserSubscriptions,
  vO as useListPdPartnerEventListWebNotifications,
  FO as useListPdSupportInfo,
  jO as useListPdWebhookEndpoints,
  IO as useListPdWebhookLogsDeliveryAttempts,
  GO as useListPdWebhookLogsEventInfo,
  DO as useListPdWebhookLogsEvents,
  oO as useListS3OfferCsvs,
  eK as useListS3PartnerDashboardTableCsvs,
  AK as useListSardineDetails,
  Iq as useListVersionedSchemas,
  hK as useMarkPADAgreementSigned,
  TO as useMultiWebhookPdWebhookLogsDeliveryAttempts,
  Kb as useOptIn,
  Ob as useOptOut,
  QK as usePatchAuthManagementUseridSamlSamlconfigid,
  ob as usePatchBeneficialOwner,
  mq as usePatchBeneficialOwnersId,
  Bf as usePatchBusinessIdentitiesId,
  xq as usePatchBusinessOfficersId,
  cf as usePatchCapitalProductIntents,
  Hq as usePatchCapitalProducts,
  HK as usePatchCardholdersCardholderid,
  Kg as usePatchCashAdvancePausesP1,
  gf as usePatchClearWatchlistHitsId,
  Qq as usePatchDataingestionStripeIngestions,
  Df as usePatchFlexLoanAge,
  If as usePatchFlexLoanFund,
  Gf as usePatchFlexLoanOwnership,
  Jf as usePatchLiensId,
  Mh as usePatchMerchantBankCardCardholdersId,
  Lh as usePatchMerchantBankExternalAccountsId,
  $h as usePatchMerchantBankExternalBankTransfersId,
  SK as usePatchMerchantBankMerchantConfig,
  bh as usePatchMerchantBankRecipientTransfersId,
  hh as usePatchMerchantBankRecipientsId,
  CK as usePatchMfaExtendSession,
  qq as usePatchOwnerIsBeneficialOwner,
  Cf as usePatchOwnersId,
  Lg as usePatchPartnerConfigsId,
  Hg as usePatchPartnerSyncWebhooksId,
  Ig as usePatchPartnerUsers,
  cK as usePatchPartnerWidgetConfigs,
  Fg as usePatchPartnersId,
  Mf as usePatchPersonaFallbackInquiry,
  bK as usePatchTemplates,
  tO as usePostAccountingInfo,
  Af as usePostAmazonRedirectUrl,
  DK as usePostAuthLoginStart,
  NK as usePostAuthLoginValidateOtp,
  TK as usePostAuthManagementUseridSaml,
  GK as usePostAuthTokenExchange,
  Vg as usePostAuthTokens,
  _q as usePostBeneficialOwners,
  Ef as usePostBusiness,
  lg as usePostBusinessAnnotations,
  Wf as usePostBusinessAuthLink,
  pg as usePostBusinessCores,
  kq as usePostBusinessOfficers,
  cO as usePostBusinessesNotifications,
  Jq as usePostCapitalProductApplication,
  Nf as usePostCapitalProductBusiness,
  tf as usePostCapitalProductNetTopUp,
  Yq as usePostCapitalProductOfferCollection,
  jh as usePostCardCloseAccount,
  Qf as usePostCards,
  gg as usePostCashAdvanceChargeOffs,
  hg as usePostCashAdvancePauses,
  Fq as usePostDataingestionOneschemaGenerateJwt,
  Uq as usePostDataingestionS3DatasetIntegrations,
  Sq as usePostDataingestionS3OrgDatasetIntegrations,
  Gq as usePostDataingestionS3RunDatasetIngestion,
  wq as usePostDataingestionStripeIngestions,
  Hh as usePostDebts,
  Vh as usePostDebtsCheckComplete,
  zf as usePostDocumentUploadGroup,
  Jh as usePostFinancialReviews,
  BK as usePostForgiveCapitalFee,
  bf as usePostFundingAccounts,
  Zg as usePostHackathonAwaitParafinRepresentative,
  jg as usePostHackathonCreatePilot,
  Jg as usePostHackathonGenerateOffers,
  eq as usePostHackathonGmv,
  rq as usePostHackathonMapOffersToEmails,
  Yg as usePostHackathonPartners,
  nq as usePostHackathonReset,
  tq as usePostHackathonSendOffers,
  sq as usePostHackathonUploadSalesCsv,
  RK as usePostManualRepayment,
  aK as usePostMarkAccountingIntegrationSeen,
  iq as usePostMarketingIntegrations,
  oq as usePostMarketingReview,
  ih as usePostMerchantBankAccounts,
  pK as usePostMerchantBankApplications,
  Uf as usePostMerchantBankBusiness,
  Fh as usePostMerchantBankCardActivate,
  Bh as usePostMerchantBankCardApplePay,
  Nh as usePostMerchantBankCardDisputeChargebackCredit,
  Sh as usePostMerchantBankCardDisputeStateTransition,
  Ah as usePostMerchantBankCardDisputes,
  Eh as usePostMerchantBankCardGooglePay,
  Ch as usePostMerchantBankCardStateTransition,
  kh as usePostMerchantBankCards,
  Th as usePostMerchantBankExternalAccounts,
  Wh as usePostMerchantBankExternalBankTransfers,
  ch as usePostMerchantBankInnerTransfers,
  UK as usePostMerchantBankOffers,
  Kh as usePostMerchantBankRecipientTransfers,
  gh as usePostMerchantBankRecipients,
  Oh as usePostMerchantBankRecipientsIdPaymentRails,
  gK as usePostMfaOtpSendPersonidPhonenumbertype,
  qK as usePostMfaOtpVerifyPersonidPhonenumbertype,
  vK as usePostMfaVerifyAccountDetails,
  rO as usePostOfficerSubsidiaryRecords,
  Zf as usePostOptIn,
  $g as usePostPartnerSyncWebhooks,
  Gg as usePostPartnerUsers,
  Mg as usePostPartners,
  Yf as usePostRestriction,
  sK as usePostRutterConnections,
  uK as usePostSardineSessionId,
  Tf as usePostSetupAmazonTopUp,
  Ag as usePostSlackAuthorize,
  Sf as usePostSpd,
  eO as usePostTaxRecords,
  FK as usePostTemplateTypes,
  KK as usePostTemplates,
  yO as usePostTermsOfServiceMarkShown,
  lf as usePostTriggerRunCelticsChecklist,
  _K as usePostWebhooksPlaidGeneral,
  Dq as usePutDataingestionS3DatasetIngestionConfigs,
  $O as useResendCrmAttempt,
  wO as useResendWebhookAttempt,
  ib as useSubmitKYC,
  Of as useUpdateBankAccountInfo,
  lb as useUpdateBoostCompletion,
  UO as useUpdatePdPartnerEventUpdateUserSubscription,
  AO as useUpdatePdPartnerEventUpdateUserSubscriptionsForEventType,
  ZO as useUpdatePdWebhookEndpoint,
  db as useUploadBankStatements
};
