import { ReactNode } from 'react'
import styled from 'styled-components'
import useTheme from '@/src/ui/theme'
import { Typography } from '../../ui/components/Typography'

type BadgeProps = {
  variant: 'green' | 'yellow' | 'red' | 'gray'
  children: ReactNode
}

export const Badge = ({ variant, children }: BadgeProps) => {
  const { theme } = useTheme()

  const getBackgroundColor = () => {
    if (variant === 'green') return theme.colors.accent200
    if (variant === 'yellow') return theme.colors.accent700
    if (variant === 'red') return theme.colors.negative100
    return theme.colors.mono200
  }

  const getTextColor = () => {
    if (variant === 'green') return theme.colors.accent100
    if (variant === 'yellow') return theme.colors.accent600
    if (variant === 'red') return theme.colors.accent500
    return theme.colors.black
  }

  return (
    <BadgeWrapper
      backgroundColor={getBackgroundColor()}
      textColor={getTextColor()}
    >
      {children}
    </BadgeWrapper>
  )
}

type BadgeWrapperProps = {
  backgroundColor: string
  textColor: string
}

const BadgeWrapper = styled(Typography)<BadgeWrapperProps>`
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 4px 12px;
`
