import {
  ListUserSubscriptionsResponse,
  Organization,
  Partner,
} from '@parafin/medici-api'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
