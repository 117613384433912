import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import styled from 'styled-components'
import { Typography } from '../components/Typography'

export const ToggleRoot = forwardRef<
  ElementRef<typeof ToggleGroup.Root>,
  ComponentPropsWithoutRef<typeof ToggleGroup.Root>
>(({ children, ...props }, ref) => {
  return (
    <StyledToggleRoot ref={ref} {...props}>
      {children}
    </StyledToggleRoot>
  )
})

type ToggleItemProps = ComponentPropsWithoutRef<typeof ToggleGroup.Item> & {
  label?: string
  currentValue: string
}

export const ToggleItem = forwardRef<
  ElementRef<typeof ToggleGroup.Item>,
  ToggleItemProps
>(({ children, label, currentValue, ...props }, ref) => {
  return (
    <StyledToggleItem ref={ref} {...props}>
      <Typography
        color={currentValue === props.value ? 'basil100' : 'black70'}
        weight="medium"
      >
        {label ?? props.value}
      </Typography>
    </StyledToggleItem>
  )
})

export const StyledToggleItem = styled(ToggleGroup.Item)`
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  background-color: ${({ theme }) => theme.colors.black20};

  &[data-state='on'] {
    border: 1px solid ${({ theme }) => theme.colors.black30};
    background-color: ${({ theme }) => theme.colors.black0};
  }
`

const StyledToggleRoot = styled(ToggleGroup.Root)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.black20};
`
