import { ReactNode } from 'react'
import { useSelect } from 'downshift'
import { Typography } from '@/src/ui/components/Typography'
import { Icon } from './Icon'
import styled, { css } from 'styled-components'
import { useSegment } from '@parafin/logging'

export type Item = {
  key: any
  content: any
  isLabel?: boolean
  isDisabled?: boolean
  isPlaceholder?: boolean
}

type DropdownVariants = 'normal' | 'small' | 'flat'

type DropdownProps = {
  loggingPrefix?: string
  items: Item[]
  defaultItem?: Item
  onChange: (input: Item) => void
  width?: string
  maxWidth?: string
  selectedItem?: Item
  variant?: DropdownVariants
  icon?: ReactNode
  hideChevron?: Boolean
  name?: string
  disabled?: boolean
}

export const Dropdown = (props: DropdownProps) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: props.items,
    defaultSelectedItem: props.defaultItem,
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) props.onChange(changes.selectedItem)
    },
    ...(props.selectedItem ? { selectedItem: props.selectedItem } : {}),
  })

  return (
    <Shell>
      <Button
        {...(!props.disabled ? getToggleButtonProps() : {})}
        width={props.width || 'auto'}
        maxWidth={props.maxWidth}
        variant={props.variant || 'normal'}
        data-testid={props.name ? `dropdown-${props.name}` : undefined}
        disabled={props.disabled}
      >
        <Label {...getLabelProps()} variant={props.variant || 'normal'}>
          {props.icon && props.icon}
          <Typography
            color={selectedItem?.isPlaceholder ? 'black80' : 'black100'}
          >
            {selectedItem?.content}
          </Typography>
        </Label>
        {!props.hideChevron && (
          <ChevronWrapper open={isOpen}>
            <Icon type="downChevron" size="12px" padding="2px" />
          </ChevronWrapper>
        )}
      </Button>
      <Options open={isOpen} {...getMenuProps()}>
        {props.items.map((item, index) => (
          <Item
            data-testid={`dropdown-item-${item.key}`}
            highlighted={highlightedIndex === index}
            key={`${item}${index}`}
            {...getItemProps({
              item,
              index,
              disabled: item.isLabel || item.isDisabled,
            })}
            hoverable={!(item.isLabel || item.isDisabled)}
          >
            <Typography weight={item.isLabel ? 'bold' : 'regular'}>
              {item.content}
            </Typography>
            {selectedItem?.key === item.key && (
              <Icon type="checkmark" size="12px" padding="2px" />
            )}
          </Item>
        ))}
      </Options>
    </Shell>
  )
}

const Shell = styled.div`
  display: inline-block;
  position: relative;
`

const Label = styled.label<{ variant: DropdownVariants }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ variant }) =>
    variant === 'small' || variant === 'flat' ? '4px' : '8px'};
  width: 100%;
`

const Button = styled.div<{
  width: string
  maxWidth?: string
  variant: DropdownVariants
  disabled?: boolean
}>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  min-width: ${({ width }) => width};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ variant }) => variant === 'normal' && NormalButton};
  ${({ variant }) => variant === 'flat' && FlatButton};
  ${({ variant }) => variant === 'small' && SmallButton};
  ${({ disabled }) => disabled && 'pointer-events: none;'}
  ${({ disabled }) => disabled && 'opacity: 50%;'}

  cursor: pointer;
  * {
    cursor: pointer;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.black20};
  }
  background-color: ${({ theme }) => theme.colors.white};
`

const FlatButton = css`
  padding: 10px 12px 10px 10px;
  border-radius: 8px;
`

const NormalButton = css`
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  border-radius: 8px;
`

const SmallButton = css`
  padding: 5px 8px;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  border-radius: 8px;
`

const Options = styled.ul<{ open: boolean }>`
  position: absolute;
  top: calc(100% - 13px);
  flex-direction: column;
  max-height: 450px;
  overflow: scroll;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0px 4px 12px 0px #00000029;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.white};
  transition: opacity 0.2s, transform 0.2s;
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  transform: ${({ open }) => (open ? 'translateY(16px)' : 'none')};
`

const Item = styled.li<{ highlighted: boolean; hoverable?: boolean }>`
  min-width: 152px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  gap: 16px;
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.colors.mono200 : theme.colors.white};
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    ${({ hoverable }) => hoverable && `cursor: pointer;`}
  }
`

const ChevronWrapper = styled.div<{ open: boolean }>`
  ${({ open }) => open && 'transform: rotate(180deg);'}
  transition: 0.3s;
`
