import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { Icon } from '@/src/ui/components/Icon'
import { cn } from '@/src/helpers/utils'
import useTheme from '@/src/ui/theme'
import { Typography } from '@/src/ui/components/Typography'
import styled from 'styled-components'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 8, ...props }, ref) => (
  <StyledTooltipContent
    ref={ref}
    sideOffset={sideOffset}
    collisionPadding={12}
    className={cn(
      'z-50 overflow-hidden bg-popover text-sm text-popover-foreground animate-in fade-in-0 shadow zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  >
    <StyledContent variant="caption" color="black0">
      {props.children}
    </StyledContent>
  </StyledTooltipContent>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

const InfoTooltip = ({
  children,
  color,
}: {
  children: React.ReactNode
  color?: string
}) => {
  const { theme } = useTheme()

  return (
    <Tooltip>
      <TooltipTrigger>
        <Icon type="info" color={color || theme.colors.accent100} />
      </TooltipTrigger>
      <TooltipContent className="text-center">{children}</TooltipContent>
    </Tooltip>
  )
}

const ContentTooltip = ({
  children,
  content,
}: {
  children: React.ReactNode
  content?: React.ReactNode
}) => (
  <Typography weight="medium">
    <Tooltip>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent>{content}</TooltipContent>
    </Tooltip>
  </Typography>
)

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  InfoTooltip,
  ContentTooltip,
}

const StyledTooltipContent = styled(TooltipPrimitive.Content)`
  padding: 12px;
  border-radius: 12px;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.kale70};
`

const StyledContent = styled(Typography)`
  max-width: 250px;
  width: fit-content;
  white-space: normal;
`
