type Props = {
  color?: string
}

export const Truck = ({ color = '#808284' }: Props) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M103.429 63.8129L82.3749 62.0766L83.75 45.3987"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M28.0633 97.1615C22.4221 96.6963 18.226 91.7458 18.6912 86.1039C19.1564 80.462 24.1066 76.2658 29.7478 76.731C35.389 77.1962 39.5851 82.1467 39.1199 87.7886C38.6547 93.4305 33.7045 97.6267 28.0633 97.1615Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M80.8185 79.9424C75.1772 79.4772 70.2271 83.6735 69.7619 89.3154C69.4947 92.5559 70.7653 95.5683 72.9642 97.6338L86.9879 97.6338C88.7766 95.9569 89.9732 93.6363 90.1906 91C90.6558 85.3581 86.4597 80.4076 80.8185 79.9424Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.473 24.9553C11.5338 24.2168 12.1818 23.6676 12.9203 23.7284L67.4175 28.2222C68.156 28.283 68.7052 28.931 68.6444 29.6695L63.7896 88.5459L42.1525 86.7618C42.1579 87.1824 42.1433 87.6071 42.108 88.0349C42.1018 88.1106 42.0949 88.1862 42.0874 88.2615L64.4138 90.1025L65.1612 90.1641L65.2229 89.4166L70.1393 29.7928C70.2683 28.2287 69.1049 26.8562 67.5408 26.7272L13.0436 22.2335C11.4795 22.1046 10.107 23.2679 9.97803 24.832L5.23348 82.3713C5.10451 83.9354 6.2679 85.3079 7.83198 85.4369L15.6828 86.0842C15.6878 86.0086 15.6934 85.9329 15.6996 85.8571C15.7349 85.4294 15.7901 85.0081 15.8643 84.5941L7.95524 83.9419C7.21679 83.8811 6.66752 83.2331 6.72841 82.4946L11.473 24.9553ZM39.097 88.0149C39.1049 87.9396 39.1119 87.8641 39.1182 87.7883C39.1508 87.3928 39.1605 87.0008 39.1484 86.6134L39.0333 88.0097L39.097 88.0149Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.5347 43.3086L68.2821 43.3702L95.0687 45.5792L95.4979 45.6146L95.6837 46.0031L103.937 63.2634C103.937 63.2643 103.938 63.2651 103.939 63.2658L112.053 70.7024C112.396 71.0173 112.575 71.4734 112.537 71.9379L110.957 91.0899C110.829 92.6539 109.456 93.8173 107.892 93.6883L93.4502 92.4974L92.7028 92.4357L92.826 90.9408L93.5735 91.0025L108.015 92.1934C108.754 92.2543 109.402 91.705 109.463 90.9666L111.042 71.8146C111.042 71.8122 111.041 71.8099 111.039 71.8082L102.925 64.3716C102.783 64.2411 102.667 64.0846 102.584 63.9104L94.5162 47.0387L68.9063 44.9268L65.2997 88.6709L66.1069 88.7374L66.8544 88.7991L66.7311 90.294L65.9837 90.2324L64.4289 90.1041L63.6815 90.0425L63.7431 89.295L67.473 44.0561L67.5347 43.3086Z"
      fill={color}
    />
    <path
      d="M111.499 75.3931L109.206 75.204C107.94 75.0996 106.828 76.0415 106.724 77.3079V77.3079C106.62 78.5743 107.562 79.6856 108.828 79.79L111.121 79.9791"
      stroke={color}
      strokeWidth="1.5"
    />
    <path d="M81.91 67.7056L86.7045 68.1009" stroke={color} strokeWidth="1.5" />
  </svg>
)
