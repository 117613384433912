import styled from 'styled-components'
import { Typography } from '@/src/ui/components/Typography'
import { Space } from '@/src/components/ui/Space'
import { Truck } from '@/src/ui/icons/Truck'
import { Center, FlexCenter } from '@/src/ui/components/Flex'

export const ErrorDisplay = () => (
  <ErrorBackground>
    <ErrorCard>
      <Truck color="#A1A2A4" />
      <Space amount="32px" />
      <Typography variant="title" rawColor="#A1A2A4">
        Something went wrong on our end. We are looking into it!
      </Typography>
    </ErrorCard>
  </ErrorBackground>
)

const ErrorBackground = styled(Center)`
  height: 70vh;
  width: 100%;
`

const ErrorCard = styled(FlexCenter)`
  flex-direction: column;
`
