import { ReactNode } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { IconContext } from 'react-icons'
import useTheme from '@/src/ui/theme'
import { TooltipProvider } from '@/src/ui/base/tooltip'

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { theme } = useTheme()

  return (
    <IconContext.Provider
      value={{
        size: '16px',
        color: theme.colors.black,
        style: { display: 'block' },
      }}
    >
      <StyledThemeProvider theme={theme}>
        <TooltipProvider delayDuration={100}>{children}</TooltipProvider>
      </StyledThemeProvider>
    </IconContext.Provider>
  )
}
