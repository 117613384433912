import { Organization } from '@parafin/medici-api'
import { Typography, TypographyWeight } from '../Typography'
import { Icon } from '../Icon'
import styled from 'styled-components'
import { FlexCenter } from '../Flex'

type OrganizationLogoProps = {
  organization: Organization
  inline?: boolean
  withOrgTag?: boolean
  orgEnabled: boolean
  weight?: TypographyWeight
}

export const OrganizationLogoWithName = ({
  organization,
  inline = true,
  withOrgTag = true,
  orgEnabled = false,
  weight,
}: OrganizationLogoProps) => (
  <IconAlignment>
    {inline ? (
      <>
        <Icon inline type="organization" />
        <Typography
          weight={weight}
          inline
          color={orgEnabled ? 'black100' : 'black70'}
        >
          {organization.name}
        </Typography>
        {withOrgTag && (
          <Typography inline color="black70">
            organization
          </Typography>
        )}
      </>
    ) : (
      <FlexCenter gap="8px">
        <Icon type="organization" />
        <NameWrap wrap="wrap">
          <Typography color={'black100'}>{organization.name}</Typography>
          {withOrgTag && <Typography color="black70">organization</Typography>}
        </NameWrap>
      </FlexCenter>
    )}
  </IconAlignment>
)

const NameWrap = styled(FlexCenter)`
  column-gap: 4px;
`

const IconAlignment = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2px 8px;
`
