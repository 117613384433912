import styled, { keyframes } from 'styled-components'
import { ButtonColor, ButtonVariant } from '@/src/ui/components/Button'
import useTheme from '@/src/ui/theme'

const spinKeyFrames = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

type SpinnerColorProps = {
  backgroundColor: string
  color: string
}

// This reuses button variants so that button and spinners are styled together
// Default button has green accents so does the spinner
const useColorsForVariant = (
  variant: ButtonVariant,
  color: ButtonColor
): SpinnerColorProps => {
  const { theme } = useTheme()

  switch (variant) {
    case 'primary':
      switch (color) {
        case 'green':
          return {
            color: theme.colors.mint100,
            backgroundColor: theme.colors.kale100,
          }
        case 'gray':
          return {
            color: theme.colors.black80,
            backgroundColor: theme.colors.black40,
          }
        case 'red':
          return {
            color: theme.colors.reed100,
            backgroundColor: theme.colors.reed140,
          }
      }
    case 'secondary':
    case 'tertiary':
      switch (color) {
        case 'green':
          return {
            color: theme.colors.mint100,
            backgroundColor: theme.colors.basil100,
          }
        case 'gray':
          return {
            color: theme.colors.black60,
            backgroundColor: theme.colors.black20,
          }
        case 'red':
          return {
            color: theme.colors.reed100,
            backgroundColor: theme.colors.reed10,
          }
      }
  }
}

type SpinnerSizeProps = {
  size?: string
  border?: number
}

const StyledSpinner = styled.div<SpinnerSizeProps & SpinnerColorProps>`
  border-radius: 50%;
  animation: ${spinKeyFrames} 1s linear infinite;
  ${({ size }) => `
    width: ${size ?? '32px'};
    height: ${size ?? '32px'};
  `}
  ${({ border, color, backgroundColor }) => `
    border: ${border ?? 4}px solid ${backgroundColor};
    border-top-color: ${color};
  `};
`

type SpinnerProps = SpinnerSizeProps & {
  variant?: ButtonVariant
  color?: ButtonColor
}

const Spinner = ({
  size,
  border,
  variant = 'primary',
  color = 'green',
}: SpinnerProps) => {
  const variantColors = useColorsForVariant(variant, color)
  return <StyledSpinner {...{ size, border, ...variantColors }} />
}

export default Spinner
