export enum RouteName {
  HOME = '/',
  ANALYTICS = '/analytics',
  BUSINESSES = '/businesses',
  BUSINESSES_LIST = '/businesses/list',
  CAPITAL_BUSINESSES = '/businesses/capital',
  LOAN_BUSINESSES = '/loan/businesses',
  DATA_SHARE = '/data-share',
  DEVELOPER = '/developer',
  SETTINGS = '/settings',
  USERS = '/settings/users',
  WIDGET = '/settings/widget',
  BRANDING = '/settings/branding',
  SUPPORT_INFO = '/settings/support-info',
  SLACK = '/settings/slack',
  NOTIFICATION_PREFERENCES = '/settings/notification-preferences',
  MARKETING = '/marketing',
  REPORTING = '/reporting',
  PLAYGROUND = '/playground',
  LOGIN = '/login',
}
