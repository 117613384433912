import { BASE_URL } from '@/parafin.config'
import {
  SANDBOX_HEADER,
  PARTNER_ID_HEADER,
  useCore,
} from '@/src/providers/core'
import { useAuth } from '@/src/providers/auth'

export const getQueryOptions = (
  authHeader: string | undefined,
  sandbox: boolean,
  queryKey?: any[],
  partnerId?: string
) => ({
  axios: {
    baseURL: BASE_URL,
    headers: {
      ...(authHeader ? { authorization: authHeader } : {}),
      ...(sandbox ? { [SANDBOX_HEADER]: '1' } : {}),
      ...(partnerId ? { [PARTNER_ID_HEADER]: `partner_${partnerId}` } : {}),
    },
    paramsSerializer: {
      indexes: null,
    },
  },
  ...(queryKey
    ? {
        query: {
          queryKey: [
            ...queryKey,
            ...(partnerId ? [partnerId] : []),
            ...(sandbox ? ['sandbox'] : []),
          ],
        },
      }
    : {}),
})

export const useQueryOptions = (queryKey?: any[]) => {
  const { authHeader } = useAuth()
  const { sandbox, partner } = useCore()
  return getQueryOptions(authHeader, sandbox, queryKey, partner?.id)
}

export const useUnauthenticatedQueryOptions = (queryKey?: any[]) => {
  return getQueryOptions(undefined, false, queryKey, undefined)
}

export const useHeaders = () => {
  const { authHeader } = useAuth()
  const { sandbox, partner } = useCore()
  return {
    authorization: authHeader,
    ...(sandbox ? { [SANDBOX_HEADER]: '1' } : {}),
    ...(partner ? { [PARTNER_ID_HEADER]: `partner_${partner.id}` } : {}),
  }
}
