import styled from 'styled-components'

type SpaceProps = {
  amount: string
}

export const Space = styled.div<SpaceProps>`
  height: ${({ amount }) => amount};
`

export const HSpace = styled.div<SpaceProps>`
  display: inline-block;
  width: ${({ amount }) => amount};
`

export const FullWidthSpace = styled(Space)`
  width: 100%;
`
