import {
  useEffect,
  useContext,
  createContext,
  useState,
  ReactNode,
} from 'react'
import { ExperimentProvider as BaseExperimentProvider } from '@parafin/experimentation'
import { ENVIRONMENT, STATSIG_KEY } from '@/parafin.config'
import { useAuth } from './auth'
import { useCore } from './core'
import { FullScreenLoading } from '../ui/components/Loading'

export const STATSIG_GATES = {
  showWebhookEndpoints: 'pd-new-webhook-endpoints',
  showNotification: 'partner-event-emails',
  enableMediciLogin: 'pd-enable-medici-login',
  showPlayground: 'pd-sandbox-playground',
}

type ExperimentConfig = {
  userId?: string
  partnerSlug?: string
  organizationSlugs: string[]
}

type ExperimentContextT = {
  config: ExperimentConfig
  setConfig: (data: ExperimentConfig) => void
}

const ExperimentContext = createContext<ExperimentContextT | undefined>(
  undefined
)

export const ExperimentProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState<ExperimentConfig>({
    organizationSlugs: [],
  })

  return (
    <ExperimentContext.Provider
      value={{
        config,
        setConfig,
      }}
    >
      <BaseExperimentProvider
        statsigKey={STATSIG_KEY}
        environment={ENVIRONMENT}
        userID={config.userId}
        partnerSlug={config.partnerSlug}
        organizationSlugs={config.organizationSlugs}
        loadingComponent={<FullScreenLoading />}
      >
        {children}
      </BaseExperimentProvider>
    </ExperimentContext.Provider>
  )
}

export const useExperimentContext = () => {
  const experimentContext = useContext(ExperimentContext)
  if (!experimentContext) {
    throw new Error('ExperimentContext is not available')
  }
  return experimentContext
}
