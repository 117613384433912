import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '@/src/providers/auth'
import { useCore } from '@/src/providers/core'
import { Breadcrumbs, Breadcrumb } from '@/src/components/frame/Breadcrumbs'
import { Icon } from '../../ui/components/Icon'
import { PartnerLogo } from '@/src/ui/components/PartnerLogo'
import { FlexSpaced, Center, FlexCenter, Flex } from '@/src/ui/components/Flex'
import { Typography } from '@/src/ui/components/Typography'
import Menu, { Option } from '@/src/ui/components/Menu'
import { Badge } from '@/src/components/ui/Badge'
import { Notifications } from './Notifications'
import { useGate } from '@parafin/experimentation'
import { STATSIG_GATES } from '@/src/providers/experiment'

type HeaderProps = {
  heading: string | Breadcrumb[]
  children?: ReactNode
}

export const Header = ({ heading, children }: HeaderProps) => {
  const { partner, sandbox, setSandbox } = useCore()
  const { logout, claims } = useAuth()
  const [opened, setOpened] = useState(false)
  const showNotification = useGate(STATSIG_GATES.showNotification)

  const toggleSandboxUi = () => {
    const isSwitchingToSandbox = !sandbox

    setSandbox(isSwitchingToSandbox)
    setOpened(false)
  }

  return (
    <HeaderContainer>
      <FlexCenter gap="16px">
        {children}
        {typeof heading === 'string' ? (
          <Typography variant="heading" weight="medium">
            {heading}
          </Typography>
        ) : (
          <Breadcrumbs crumbs={heading} />
        )}
      </FlexCenter>
      <Flex gap="6px">
        {showNotification && <Notifications />}
        <Menu
          key={sandbox ? 'sandbox' : 'production'}
          width="226px"
          opened={opened}
          setOpened={setOpened}
          menuPosition="right"
          button={
            <ProfileButton data-testid="user-menu-button">
              <PartnerLogo partner={partner} />
              <Typography>{claims.name}</Typography>
              <Center>
                <Icon type="moreVertical" />
              </Center>
            </ProfileButton>
          }
        >
          <Option
            onClick={() => toggleSandboxUi()}
            data-testid="option-sandbox"
          >
            Switch to{' '}
            <Mode>
              <Badge variant={sandbox ? 'green' : 'yellow'}>
                {sandbox ? 'Production' : 'Sandbox'}
              </Badge>
            </Mode>
          </Option>
          <Option onClick={logout} data-testid="option-logout">
            Log out
          </Option>
        </Menu>
      </Flex>
    </HeaderContainer>
  )
}

const Mode = styled.span`
  display: inline-block;
  margin-left: 2px;
  text-transform: capitalize;
`

const HeaderContainer = styled(FlexSpaced)`
  height: 80px;
  padding: 0 32px;
  max-width: 1280px;
  margin: 0 auto;
`

const ProfileButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  padding: 8px;
  user-select: none;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.mono200};
  }
`
