import styled, { css, keyframes } from 'styled-components'
import { ReactNode, useEffect, useRef, useCallback } from 'react'
import { Typography } from './Typography'

type MenuStyle = {
  visible?: boolean
  width?: string
  wrapperWidth?: string
  menuPosition?: 'center' | 'left' | 'right'
}

type MenuProps = {
  children: ReactNode
  button: ReactNode
  opened: boolean
  setOpened: (value: boolean) => void
  onMenuClose?: () => void
  onMenuOpen?: () => void
} & Omit<MenuStyle, 'visible'>

type WrapperProps = {
  width?: string
}

const Menu = ({
  button,
  children,
  width,
  wrapperWidth,
  opened,
  onMenuOpen,
  onMenuClose,
  setOpened,
  menuPosition,
}: MenuProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const hide = useCallback(
    (e: Event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node) &&
        opened
      ) {
        setOpened(false)
        onMenuClose?.()
      }
    },
    [containerRef, opened]
  )
  useEffect(() => {
    window.addEventListener('click', hide)
    return () => {
      window.removeEventListener('click', hide)
    }
  }, [hide])
  return (
    <Wrapper ref={containerRef} tabIndex={0} width={wrapperWidth}>
      <div
        onClick={() => {
          setOpened(!opened)
          onMenuOpen?.()
        }}
      >
        {button}
      </div>
      <Options width={width} visible={opened} menuPosition={menuPosition}>
        {children}
      </Options>
    </Wrapper>
  )
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: inline-block;
  ${({ width }) => width && `width: ${width}`};
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Options = styled.div<MenuStyle>`
  position: absolute;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  overflow: hidden;
  animation: ${fadeIn} 0.1s ease-out;
  z-index: 10;
  top: 100%;
  ${({ menuPosition }) =>
    menuPosition === 'left'
      ? 'left: 0;'
      : menuPosition === 'right'
      ? 'right: 0;'
      : `
      left: 50%;
      transform: translateX(-50%);
    `}
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
  ${({ width }) => width && `width: ${width}`};
`

export const optionCss = css`
  cursor: pointer;
  padding: 8px 8px 8px 16px;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.4, 1);

  &:hover {
    background: ${({ theme }) => theme.colors.mono200};
  }
`

export const Option = styled(Typography)`
  ${optionCss}
`

export default Menu
