import Spinner from '@/src/ui/components/Spinner'
import styled from 'styled-components'

export const FullScreenLoading = () => (
  <Wrapper>
    <Spinner variant="tertiary" color="gray" />
  </Wrapper>
)

type LoadingProps = {
  height?: string
}

export const Loading = ({ height }: LoadingProps) => (
  <Wrapper container height={height}>
    <Spinner variant="tertiary" color="gray" />
  </Wrapper>
)

type WrapperProps = {
  container?: boolean
}

const Wrapper = styled.div<WrapperProps & LoadingProps>`
  height: ${({ container, height }) => (height ? height : '100vh')};
  width: ${({ container }) => (container ? '100%' : '100vw')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
`
