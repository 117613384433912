import { ReactNode } from 'react'
import styled from 'styled-components'
import { PartnerLogo } from '../PartnerLogo'
import { Typography, TypographyWeight } from '../Typography'
import { PartnerInfo } from '@/src/providers/core'
import { FlexCenter } from '../Flex'

type PartnerLogoWithNameProps = {
  partner?: PartnerInfo
  icon?: ReactNode
  weight?: TypographyWeight
}

export const PartnerLogoWithName = ({
  partner,
  icon,
  weight,
}: PartnerLogoWithNameProps) => {
  return (
    <IconAlignment>
      {partner === undefined ? (
        <Typography inline color="black70">
          (Not available)
        </Typography>
      ) : (
        <FlexCenter gap="8px">
          <PartnerLogo partner={partner} icon={icon} />
          <NameWrap wrap="wrap">
            <Typography inline weight={weight}>
              {partner.name}
            </Typography>{' '}
            {partner.config && partner.config.country_code === 'CA' && (
              <Typography inline color="black70">
                (Canada)
              </Typography>
            )}
          </NameWrap>
        </FlexCenter>
      )}
    </IconAlignment>
  )
}

const NameWrap = styled(FlexCenter)`
  column-gap: 4px;
`

const IconAlignment = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2px 8px;
`
