import { useLocation } from 'react-router-dom'
import { Dropdown } from '@/src/ui/components/DropdownV2'
import { pageIsOrgEnabled, PartnerInfo } from '@/src/providers/core'
import { Organization } from '@parafin/medici-api'
import useTheme, { ThemeContextType } from '@/src/ui/theme'
import { PartnerLogoWithName } from '@/src/ui/components/PartnerLogoWithName'
import { Icon } from '@/src/ui/components/Icon'
import { OrganizationLogoWithName } from '@/src/ui/components/OrganizationLogoWithName/OrganizationLogoWithName'
import { RouteName } from '@/src/routes/RouteName'

const getDropdownItems = (
  partners: PartnerInfo[],
  organization: Organization,
  orgEnabled: boolean,
  theme: ThemeContextType
) => {
  return [
    {
      key: 'organization',
      content: (
        <OrganizationLogoWithName
          organization={organization}
          orgEnabled={orgEnabled}
          inline={false}
        />
      ),
      isDisabled: !orgEnabled,
    },
    ...partners.map((partner) => {
      return {
        key: partner.id,
        content: (
          <PartnerLogoWithName
            partner={partner}
            icon={<Icon type="briefcase" color="black80" />}
          />
        ),
      }
    }),
  ]
}

type OrganizationDropdownProps = {
  partners: PartnerInfo[]
  organization: Organization
  partner?: PartnerInfo
  setPartnerId: (partnerId: string | null) => void
  enabled?: boolean
}

export const OrganizationDropdown = ({
  partners,
  partner,
  organization,
  setPartnerId,
  enabled = true,
}: OrganizationDropdownProps) => {
  const { pathname } = useLocation()
  const { theme } = useTheme()
  const orgEnabled = pageIsOrgEnabled(pathname)
  const items = getDropdownItems(partners, organization, orgEnabled, theme)

  return (
    <Dropdown
      width="200px"
      maxWidth="200px"
      items={items}
      selectedItem={
        partner ? items.find((item) => item.key === partner.id) : items[0]
      }
      onChange={(item) => {
        if (item.key === 'organization') {
          setPartnerId(null)
        }
        const newPartner = partners.find((partner) => partner.id === item.key)
        if (newPartner) {
          setPartnerId(newPartner.id)
        }
      }}
      name="partnerSwitcher"
      disabled={!enabled}
    />
  )
}

export const pageIsOrgSwitcherEnabled = (pathname: string) => {
  const SwitcherDisablePages = [RouteName.NOTIFICATION_PREFERENCES]

  for (const route of SwitcherDisablePages) {
    if (pathname.includes(route)) {
      return false
    }
  }

  return true
}
